import React from "react";
import Card from "./components/Card";
import { toAbsoluteUrl } from "../../_helpers";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import NotAuthorized from "../widgets/NotAuthorized";

export function Setting() {
  const intl = useIntl();

  const { permission, role } = useSelector(
    (state) => ({
      permission: state.auth.permission,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  // const { role } = useSelector(
  //   (state) => ({
  //     role: state.auth.user.roles,
  //   }),
  //   shallowEqual
  // );

  return (
    <>
      {permission?.setting?.view ? (
        <div className="row setting-sec">
          <>
            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "PRODUCTS" })}
                url={"/settings/products"}
                description={intl.formatMessage({
                  id: "SETTING.PRODUCTS.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
              />
            </div>
            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "USERS" })}
                url={"/settings/users"}
                description={intl.formatMessage({
                  id: "SETTING.USERS.CARD.DES",
                })}
                svgPath={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group.svg"
                )}
              />
            </div>
            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "FRANCHISES" })}
                url={"/settings/franchises"}
                description={intl.formatMessage({
                  id: "SETTING.FRANCHISES.CARD.DES",
                })}
                svgPath={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Address-card.svg"
                )}
              />
            </div>

            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "Timeline" })}
                url={"/settings/timeline"}
                description={intl.formatMessage({
                  id: "SETTING.FEEDS.CARD.DES",
                })}
                svgPath={toAbsoluteUrl(
                  "/media/svg/icons/General/Notifications1.svg"
                )}
              />
            </div>

            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "PROMOTIONS" })}
                url={"/settings/promotions"}
                description={intl.formatMessage({
                  id: "SETTING.PROMOTIONS.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Sale2.svg")}
              />
            </div>
            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "DEPOTS" })}
                url={"/settings/depots"}
                description={intl.formatMessage({
                  id: "SETTING.DEPOTS.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
              />
            </div>
            {/* <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "REPORTING" })}
                url={"/settings/reporting"}
                description={intl.formatMessage({
                  id: "SETTING.REPORTING.CARD.DES",
                })}
                svgPath={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Chart-pie.svg"
                )}
              />
            </div> */}

            <div className="col-md-4">
              <Card
                title={intl.formatMessage({ id: "COUNTRY" })}
                url={"/settings/country"}
                description={intl.formatMessage({
                  id: "SETTING.COUNTRY.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")}
              />
            </div>
            <div className="col-md-4">
              <Card
                title={"Sales Agent Availability"}
                url={"/settings/sales-agent-availability"}
                description={intl.formatMessage({
                  id: "SETTING.Availability.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
              />
            </div>

            {(role && role?.length && role[0] === "super-admin") ||
            (role && role?.length && role[0]?.name === "franchise-owner") ? (
              <div className="col-md-4">
                <Card
                  title={"Reports"}
                  url={"/settings/reports"}
                  description={"Order Reports, Customer Reports etc."}
                  svgPath={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Chart-pie.svg"
                  )}
                />
              </div>
            ) : (
              ""
            )}
            {/* <div className="col-md-4">
              <Card
                title={"Container"}
                url={"/settings/container"}
                description={intl.formatMessage({
                  id: "SETTING.Availability.CARD.DES",
                })}
                svgPath={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
              />
            </div> */}
            {/* <div className="col-md-4">
              <Card
                title={"Customer"}
                url={"/settings/customers"}
                description={intl.formatMessage({
                  id: "SETTING.Availability.CARD.DES",
                })}
                svgPath={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Adress-book2.svg"
                )}
              />
            </div> */}
          </>
        </div>
      ) : (
        <NotAuthorized />
      )}
    </>
  );
}
