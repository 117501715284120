/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { updateDepot } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
const EditDepotModal = ({
  header,
  mainText,
  dialog,
  setDialog,
  handleAction,
  children,
  orderDetail,
}) => {
  const dispatch = useDispatch();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const initialValues = {
    order_id: orderDetail?.id || "",
    depot_id: "",
  };
  const { depotFraList } = useSelector(
    (state) => ({
      depotFraList: state.franchise.depot,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (orderDetail?.franchise_id && dialog?.check) {
      dispatch(fetchFranchisesDepot(orderDetail?.franchise_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.franchise_id, dialog?.check]);
  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });
    updateDepot(values)
      .then((res) => {
        // dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setDialog({ check: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: AssignContactSchema(intl, Yup),
    onSubmit: handleSubmit,
  });
  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      formik.resetForm();
      setDialog({ check: false });
    }
  };
  return (
    <Modal
      show={dialog.check}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={() => handleClose()}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Change Depot
        </Modal.Title>
        <span
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={() => handleClose()}
        >
          <i class="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <p className="marginBottom0">
            {orderDetail?.depot_details?.address_line_1 || ""}
          </p>

          <p className="marginBottom0">
            {orderDetail?.depot_details?.address_line_2 || ""}
          </p>

          <p className="marginBottom0">
            {orderDetail?.depot_details?.address_line_3 || ""}
          </p>

          <p className="marginBottom0">
            {orderDetail?.depot_details?.city || ""}
          </p>

          <p className="marginBottom0">
            {orderDetail?.depot_details?.postcode || ""}
          </p>
          <br />
          <span className="mt-3 ">New Depot</span>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              // onChange={(e) => {
              //   setFieldValue("search_view", e.target.value);
              //   handleSubmit();
              // }}
              name="depot_id"
              {...formik.getFieldProps("depot_id")}
            >
              <MenuItem value="">Choose</MenuItem>
              {depotFraList && depotFraList.length > 0
                ? depotFraList.map((item, index) => {
                    if (item?.status !== 1) return;
                    return (
                      <MenuItem value={item?.depot_id} key={index}>
                        {item?.name || ""}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setDialog}
      />
    </Modal>
  );
};

export default EditDepotModal;
