/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { months } from "../modules/Order/_mocks/dummyData";
import {
  addCards,
  addCharge,
  addPerson,
  expireCheck,
  findNewCustomerCard,
  getSmsPlaceOrder,
  paymentFaildError,
  sendQuoteCheckOut,
  smsAddCards,
  smsAddCharge,
  smsAddPod,
} from "../modules/Order/_redux/order/orderCrud";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useFormik } from "formik";
import { getPaymentIntent } from "../modules/Order/_redux/order/orderCrud";
import SnackBarTool from "../../_metronic/_partials/widgets/snack-bar/SnackBar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { LoadingCustome } from "../../_metronic/_partials/controls/LoadingCustome";
import { convertGBPtoPoundSymbol } from "../utils/commonFunction";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import valid from "card-validator";
import { CheckOutCardSchema } from "../modules/Order/order-form/validationSchema";
import CancelIcon from "@material-ui/icons/Cancel";
import creditCardType from "credit-card-type";
import CheckoutDialog from "../../_metronic/_partials/widgets/alert/CheckoutDialog";

const CheckoutPage = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const initialValues = {
    cardNumber: "",
    expires: "",
    expiresYear: "",
    cvv: "",
    nameCard: "",
  };
  // const [cardType, setCardType] = useState('');
  const [checkoutData, setCheckoutData] = useState({});
  const [payment, setPayment] = useState({});
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState(false);
  const [isStatus, setIsStatus] = useState({
    loading: false,
    success: false,
    expire: false,
    expireMessage: "",
  });
  const intl = useIntl();
  const fullYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + fullYear);
  const handlePaymentIntent = (value, stValue) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      first_name: value?.first_name || "",
      last_name: value?.last_name || "",
      email: value?.email || "",
      address: value?.address || "",
      city: value?.city || "",
      state: "",
      zip: value?.postcode || "",
      country: query?.currency ? query?.currency.substring(0, 2) : "",
      amount: query?.is_reservation == 2 && (query?.is_container == 1||(query?.is_container !=1 && value?.contact?.franchise_id == 6)) ? (+query?.amount* 0.1).toFixed(2) : query?.amount || "",
      currency_code: query?.currency || "",
      franchise_id: value?.contact?.franchise_id || value?.franchise_id || "",
    };
    formik.setFieldValue(
      "nameCard",
      value?.first_name + " " + value?.last_name
    );
    getPaymentIntent(data)
      .then((res) => {
        // setSnack({ call: true, type: "success", message: res.data.message });
        setPayment(res.data);
      })
      .catch((err) => {
        setDialog(true);
        setSnack({
          call: true,
          type: "error",
          message: err.response.data.message,
        });
      });
  };
  const handleCheck = () => {
    setIsStatus({ ...isStatus, loading: true });
    expireCheck(query?.token)
      .then((res) => {
        setIsStatus({ ...isStatus, loading: true });
      })
      .catch((error) => {
        setIsStatus({
          ...isStatus,
          expire: true,
          expireMessage: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    if (checkoutData?.customer) {
      setIsStatus({ ...isStatus, loading: true });
      handleCheck();
      expireCheck(query?.token)
        .then((res) => {
          setIsStatus({ ...isStatus, loading: false });
        })
        .catch((error) => {
          setIsStatus({
            ...isStatus,
            expire: true,
            expireMessage: error.response.data.message,
          });
        });
      try {
        window.Chargebee.init({
          site: checkoutData?.customer?.contact?.franchise?.chargebee_key || "",
          publishableKey:
            checkoutData?.customer?.contact?.franchise?.stripe_pk || "",
        });
        // setCbI({ cbInstance: window.Chargebee.getInstance() });
      } catch (error) {}

      handlePaymentIntent(checkoutData?.customer);
    }
  }, [checkoutData?.customer]);

  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });
    setIsStatus({ ...isStatus, loading: true });
    const date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (year == values.expiresYear) {
      if (month > values.expires) {
        setIsStatus({ ...isStatus, loading: false });
        setSnack({
          call: true,
          type: "error",
          message: "Your card has been expired!",
        });
        return;
      }
    }
    handleCheck();

    // return ;
    let cbInstance = window.Chargebee.getInstance();
    cbInstance.load3DSHandler().then((threeDSHandler) => {
      const intent = {
        id: payment?.data?.id || "",
        expires_at: Number(payment?.data?.expires_at) || "",
        amount: Number(payment?.data?.amount) || "",
        created_at: Number(payment?.data?.created_at) || "",
        currency_code: payment?.data?.currency_code || "",
        gateway: payment?.data?.gateway || "",
        gateway_account_id: payment?.data?.gateway_account_id || "",
        modified_at: Number(payment?.data?.modified_at) || "",
        object: payment?.data?.object || "",
        payment_method_type: payment?.data?.payment?.data_method_type || "",
        status: payment?.data?.status || "",
        customerId: payment?.data?.customer_id || "",
      };
      // Set the created paymentIntent
      threeDSHandler.setPaymentIntent(intent);
      threeDSHandler.handleCardPayment(
        {
          card: {
            firstName: values.nameCard,
            number: values.cardNumber.replace(/ /g, ""),
            cvv: values.cvv,
            expiryMonth: values.expires,
            expiryYear: values.expiresYear,
          },
          additionalData: {
            billingAddress: {
              zip: checkoutData?.customer?.postcode || "",
              firstName: checkoutData?.customer?.first_name || "",
              lastName: checkoutData?.customer?.last_name || "",

              phone: checkoutData?.customer?.phone || "",
              addressLine1: checkoutData?.customer?.address || "",
              city: checkoutData?.customer?.city || "",
            },
          },
        },
        {
          change: function(intent) {
            // Triggers on each step transition
          },
          success: function(intent) {
            setIsStatus({ ...isStatus, loading: true });
            const data = {
              link_pay: 1,
              // gw_token: intent?.active_payment_attempt?.id_at_gateway || "",
              gw_token: intent?.id || "",

              data: query?.data,
              customer_id: payment?.data?.customer_id || "",
              token: query?.token,
              payment_type: "card",
              is_reservation :query?.is_reservation 
            };
            getSmsPlaceOrder(data)
              .then((res) => {
                addPerson(data.email)
                // setIsStatus({ ...isStatus, loading: false });
                if(query?.is_reservation == 1   // ||query?.is_container != 1
                   ){

                  setIsStatus({ loading: false, success: true });
                  return;
                }
                
                const dataCard = {
                  customer_chargebee_id: res.data.data?.customer_chargebee_id,
                  franchise_id: res.data.data?.franchise_id,
                  // gw_token: intent?.active_payment_attempt?.id_at_gateway || "",
                  gw_token: intent?.id || "",

                  order_id: res.data.data?.id,
                };
                smsAddCards(dataCard , "sms")
                .then((resC) => {
                  const twentyPercentOfTotal =
                    Number(res?.data?.data?.amount.toFixed(2)) * 0.1;
                  const ePercentOfTotal = Number(res?.data?.data?.amount) * 0.9;
                  const dataH = {
                    order_id: dataCard?.order_id,
                    invoice_id: res.data.data?.invoice_id,
                    invoice_due_amount:
                    res?.data?.data?.amount == 0 ? 0 : +res?.data?.data?.amount.toFixed(2),
                    twenty_percent_amount: Number(twentyPercentOfTotal).toFixed(2),
                    eighty_percent_amount: Number(ePercentOfTotal).toFixed(2),
                    twenty_percent_status: 1,
                  };
                  if(query?.is_container != 1){
                    smsAddPod(dataH, "sms")
                    .then((resCh) => {
                      setSnack({
                        call: true,
                        type: "success",
                        message: resCh.data.message,
                      });
                      setIsStatus({ loading: false, success: true });
                      setTimeout(() => {}, 2000);
                    })
                    .catch((error) => {
                      setDialog(true);
                      setIsStatus({ ...isStatus, loading: false });
                    
                      setSnack({
                        call: true,
                        type: "error",
                        message:
                          "Order Placed successfully " +
                          error?.response?.data?.message,
                      });
                    });
                  }else{

                    smsAddCharge(dataH, "sms")
                      .then((resCh) => {
                        setSnack({
                          call: true,
                          type: "success",
                          message: resCh.data.message,
                        });
                        setIsStatus({ loading: false, success: true });
                        setTimeout(() => {}, 2000);
                      })
                      .catch((error) => {
                        setDialog(true);
                        setIsStatus({ ...isStatus, loading: false });
                      
                        setSnack({
                          call: true,
                          type: "error",
                          message:
                            "Order Placed successfully " +
                            error?.response?.data?.message,
                        });
                      });
                  }
                })
                .catch((err) => {
                  setDialog(true);
                  setIsStatus({ ...isStatus, loading: false });
                 
                  setSnack({
                    call: true,
                    type: "error",
                    message:
                      "Order Placed successfully " + err?.response?.data?.message,
                  });
                });
              })
              .catch((err) => {
                setDialog(true);
                setIsStatus({ ...isStatus, loading: false });
                setSnack({
                  call: true,
                  type: "error",
                  message: err.response.data.message,
                });
              });
          },
          error: function(intent, error) {
            // setLoading(false);
            setIsStatus({ ...isStatus, loading: false });
            
            const data = {
              data: query?.data,
              user_id: query?.user_id || "",
              amount: query?.amount,
              msg: error.message,
            };
            paymentFaildError(data);
            setDialog(true);
            setSnack({
              call: true,
              type: "error",
              message: error.message,
            });
            // Triggers when 3DS authorization fails
          },
        }
      );
      // The paymentIntent can be updated whenever the amount gets changed
      // threeDSHandler.updatePaymentIntent(paymentIntent);
    });
  };


  const formik = useFormik({
    initialValues,
    validationSchema: CheckOutCardSchema(valid),
    onSubmit: handleSubmit,
  });

  const handleGetCustomerData = () => {
    findNewCustomerCard(query?.user_id)
      .then((response) => {
        const { data } = response.data;
        setCheckoutData({ ...checkoutData, customer: data[0] });
      })
      .catch(() => {});
  };
  const handleCardNumber = (incomingValue) => {
    const rawText = [...incomingValue.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });

    formik.setFieldValue("cardNumber", creditCard.join(""));
  };
  useEffect(() => {
    handleGetCustomerData();
  }, []);

  const handleSetCvv = (e) => {
    const cardTypeInfo = creditCardType(formik.values.cardNumber);
    const cardType = cardTypeInfo[0]?.type || "";
    if (cardType == "american-express") {
      if (e.target.value?.length < 5) {
        formik.setFieldValue("cvv", e.target.value);
      }
    } else if (e.target.value?.length < 4) {
      formik.setFieldValue("cvv", e.target.value);
    }
  };
  const handleAction = (check) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    if (check === "quote" || check === "draft") {
      const data = {
        quote_by_link: 1,
        quote_type: check,
        data: query?.data,
        customer_id: payment?.data?.customer_id || "",
        token: query?.token,
      };
      sendQuoteCheckOut(data)
        .then((res) =>
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          })
        )
        .catch((error) =>
          setSnack({
            call: true,
            type: "error",
            message: error.message,
          })
        );
    } else {
      window.location.reload();
    }
  };
  return (
    <div class="Checkout " style={{ marginTop: "-70px" }}>
      <div>
        <img
          data-v-16d825fb=""
          src={toAbsoluteUrl("/media/logos/easystorage.png")}
          alt="logo"
        ></img>
      </div>
      {isStatus?.loading && <LoadingCustome />}

      <div class="Form mt-4  row">
        <div className="col-sm-12 font-size-lg mb-6">
          {!isStatus.success && !isStatus.expire && (
            <span>
              Dear{" "}
              <strong>
                {checkoutData?.customer?.first_name +
                  " " +
                  checkoutData?.customer?.last_name}
                ,{" "}
              </strong>
              <div>
                Please use this secure payment link to complete your booking.
              </div>
              {/* , kindly pay your order amount */}
            </span>
          )}
        </div>
        {/* {isStatus.expire ?} */}
        {!isStatus.success && !isStatus.expire && (
          <>
            <div className="col-md-6 col-sm-12">
              <span>
                Order Amount:{" "}
                {query?.currency
                  ? convertGBPtoPoundSymbol(query?.currency)
                  : ""}
                {(+query?.amount).toFixed(2)}
              </span>
              {/* <h2>
                {query?.currency
                  ? convertGBPtoPoundSymbol(query?.currency)
                  : ""}
                {query?.amount}
              </h2> */}
              <div className="mt-2 mb-4 ">
                <span>
                  {query.is_container == 1 ? "Container":
                  <>
                  {+query?.pod * 35} sq ft - {query?.pod || "-"} easyPods
                  </>
}
                </span>
              </div>
              {query.is_container == 1 ?"":
              <div>
                {query?.pod <= 5 ? (
                  <img
                    data-v-16d825fb=""
                    src={`https://bookstaging.easystorage.com/images/${query?.pod}.png`}
                    alt="pod"
                  ></img>
                ) : (
                  <img
                    data-v-16d825fb=""
                    src={`https://bookstaging.easystorage.com/images/6.png`}
                    alt="pod"
                  ></img>
                )}
              </div>
}
            </div>
            <div className="col-md-6 col-sm-12">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Card.Number",
                      })}
                      className={`form-control  `}
                      name="cardNumber"
                      maxLength={"19"}
                      value={formik?.values?.cardNumber}
                      onChange={(e) => handleCardNumber(e.target.value)}
                    />
                    {formik.touched.cardNumber && formik.errors.cardNumber ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.cardNumber}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-12 mb-5">
                        <div>
                          <Form.Label>
                            {intl.formatMessage({ id: "Expires" })}
                          </Form.Label>
                        </div>

                        <div className="row">
                          <div className="col-5">
                            <FormControl fullWidth>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                IconComponent={(props) => (
                                  <i {...props}>
                                    <KeyboardArrowDownIcon />
                                  </i>
                                )}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                name="expires"
                                className={`form-control w-100  `}
                                {...formik.getFieldProps("expires")}
                              >
                                {months.map((item, index) => (
                                  <MenuItem value={item.id}>
                                    {item.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formik.touched.expires && formik.errors.expires ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.expires}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-2 mt-2 text-center">/</div>
                          <div className="col-5">
                            <FormControl fullWidth>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                IconComponent={(props) => (
                                  <i {...props}>
                                    <KeyboardArrowDownIcon />
                                  </i>
                                )}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                name="expiresYear"
                                className={`form-control w-100  `}
                                {...formik.getFieldProps("expiresYear")}
                              >
                                {years.map((data, index) => (
                                  <MenuItem value={data}>{data}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {formik.touched.expiresYear &&
                            formik.errors.expiresYear ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.expiresYear}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>
                        {intl.formatMessage({ id: "CVV" })}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={intl.formatMessage({ id: "CVV" })}
                        className={`form-control  `}
                        name="cvv"
                        value={formik?.values?.cvv}
                        onChange={(e) => handleSetCvv(e)}
                        // {...formik.getFieldProps("cvv")}
                      />
                      {formik.touched.cvv && formik.errors.cvv ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.cvv}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>
                <div>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>
                      {intl.formatMessage({ id: "Name.On.Card" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"Name"}
                      className={`form-control  `}
                      name="nameCard"
                      {...formik.getFieldProps("nameCard")}
                    />
                    {formik.touched.nameCard && formik.errors.nameCard ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.nameCard}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  Pay{" "}
                  {query?.currency
                    ? convertGBPtoPoundSymbol(query?.currency)
                    : ""}
                    {query?.is_reservation == 2 ?(+query?.amount * 0.1).toFixed(2) : 
                    <>
                  {(+query?.amount).toFixed(2)}
                    </>
}
                </button>
              </form>
            </div>
          </>
        )}
        {isStatus?.success && !isStatus.expire && (
          <div className="col-md-12 col-sm-12 text-center">
            <CheckCircleIcon
              style={{ color: "green", height: "20%", width: "20%" }}
            />
            <h3>Payment successful</h3>
          </div>
        )}
        {isStatus.expire && (
          <div className="col-md-12 col-sm-12 text-center">
            <CancelIcon style={{ color: "red", height: "20%", width: "20%" }} />
            <h3>{isStatus?.expireMessage || "Token Expired"}</h3>
          </div>
        )}
      </div>
      <CheckoutDialog
        dialog={dialog}
        setDialog={setDialog}
        handleSend={handleAction}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};

export default CheckoutPage;
