import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { useProductsUIContext } from "../ProductsUIContext";
import { CustomeSearch } from "../../../../../../_metronic/_partials/controls/CustomeSearch";

const prepareFilter = (queryParams, values) => {
  const {
    searchText,
    search_by,
    search_value,
    search_status,
    search_type,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.search_by = search_by !== "" ? search_by : "";
  filter.search_value = search_value !== "" ? search_value : "";
  filter.search = searchText ? searchText : "";
  filter.search_type = search_type ? search_type : "";
  filter.search_status = search_status ? search_status : "";
  // Filter by condition
  // Filter by all fields
  // filter.model = searchText;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ProductsFilter({ listLoading }) {
  const intl = useIntl();
  // Products UI Context
  const productsUIContext = useProductsUIContext();

  const productsUIProps = useMemo(() => {
    return {
      setQueryParams: productsUIContext.setQueryParams,
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      productsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          search_by: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_value: "",
          search_type: "",
          search_status: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-md-8 col-lg-10 mb-5">
                <div className="row">
                  <div className="col-lg-2">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_type", e.target.value);
                          handleSubmit();
                        }}
                        // value={""}
                        onBlur={handleBlur}
                        value={values.search_type}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All.Products" })}
                        </MenuItem>
                        <MenuItem value="plan">
                          {intl.formatMessage({ id: "Plans" })}
                        </MenuItem>
                        <MenuItem value="addon">
                          {intl.formatMessage({ id: "Addons" })}
                        </MenuItem>
                        <MenuItem value="charge">
                          {intl.formatMessage({ id: "Charges" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-2">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_status", e.target.value);
                          handleSubmit();
                        }}
                        // value={""}
                        onBlur={handleBlur}
                        value={values.search_status}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Status" })}
                        </MenuItem>
                        <MenuItem value="active">
                          {intl.formatMessage({ id: "ACTIVE" })}
                        </MenuItem>
                        <MenuItem value="archived">
                          {intl.formatMessage({ id: "Archive" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-2">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_by", e.target.value);
                          setFieldValue("search_value", "");
                          handleSubmit();
                        }}
                        // value={""}
                        onBlur={handleBlur}
                        value={
                          values.search_by === "id" ||
                          values.search_by === "name"
                            ? values.search_by
                            : ""
                        }
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "Search.Filter" })}
                        </MenuItem>
                        <MenuItem value="id">
                          {" "}
                          {intl.formatMessage({ id: "Product.ID" })}
                        </MenuItem>
                        <MenuItem value="name">
                          {" "}
                          {intl.formatMessage({ id: "Product.Name" })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      setTimeout(() => {
                        handleSubmit();
                      }, 1000);
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
