/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import {
  fetchBillingCardDetail,
  fetchMyOrderTimeline,
  // fetchOrderDropDown,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";

import { Form } from "react-bootstrap";
import { PartSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { paymentPlan } from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function PartPayment({ orderDetail, billingCard }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const initialValues = {
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    invoice_id: billingCard?.old_unpaid_invoice?.invoice?.id || "",
    amount: "",
    comment: "",
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    paymentPlan(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        formik.resetForm();
        canvaCancel("kt_PartPayment_panel_close");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: PartSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleDiscard = () => {
    canvaCancel("kt_PartPayment_panel_close");
    formik.resetForm();
  };

  return (
    <div
      id="kt_PartPayment_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleDiscard()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_PartPayment_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 date-picker">
          <div>
            <h5>
              {intl.formatMessage({ id: "Part.Payment" })}&nbsp;(#
              {billingCard?.old_unpaid_invoice?.invoice?.id || "-"})
            </h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_PartPayment_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {/* For invoice [{canva?.data?.booking_reference || ""}] */}
                </Form.Label>
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>{intl.formatMessage({ id: "Amount" })}</Form.Label>
                <Form.Control
                  type="text"
                  // placeholder={canva?.data?.currency || ""}
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>{intl.formatMessage({ id: "Comment" })}</Form.Label>
                <Form.Control
                  type="textarea"
                  row="3"
                  as="textarea"
                  // placeholder={canva?.data?.currency || ""}
                  className={`form-control  ${getInputClasses("comment")}`}
                  name="comment"
                  {...formik.getFieldProps("comment")}
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.comment}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {/* <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {intl.formatMessage({ id: "Payment.method" })}
                </Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="payment_method"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    className={`form-control w-100 ${getInputClasses(
                      "payment_method"
                    )}`}
                    {...formik.getFieldProps("payment_method")}
                  >
                    <MenuItem value="">
                      {intl.formatMessage({ id: "Payment.method" })}
                    </MenuItem>
                    {orderDropDown &&
                    orderDropDown?.paymentMethod &&
                    orderDropDown.paymentMethod.length &&
                    orderDropDown.paymentMethod.length > 0 ? (
                      orderDropDown.paymentMethod.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="no"></MenuItem>
                    )}
                  </Select>
                </FormControl>
                {formik.touched.payment_method &&
                formik.errors.payment_method ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.payment_method}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div> */}
            {/* <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {intl.formatMessage({ id: "Payment.Date" })}
                </Form.Label>
                <InputGroup className="calendr">
                  <DatePicker
                    fullWidth
                    fixedHeight
                    className="form-control w-100"
                    wrapperClassName="datepicker"
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    selected={Date.parse(formik.values?.payment_date)}
                    onChange={(date) =>
                      formik.setFieldValue(
                        "payment_date",
                        moment(date).format("DD-MM-yyyy")
                      )
                    }
                    // popperClassName="date-picker-reports"
                    placeholderText="DD-MM-YYYY"
                  />
                  <CalendarTodayIcon />
                </InputGroup>
                {formik.touched.payment_date && formik.errors.payment_date ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.payment_date}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div> */}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "Payment" })}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
