/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import ActiveDialogCharge from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogCharge";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { useIntl } from "react-intl";

export function NewAddonsCanvas({ formik, detailData, type }) {
  const intl = useIntl();

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    if (formik?.values?.name && type !== "edit") {
      let data = formik?.values?.name.replace(/ /g, "-");
      formik.setFieldValue("id", data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <label>{intl.formatMessage({ id: "External.Name" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="external_name"
              placeholder={intl.formatMessage({ id: "External.Name" })}
              label="External Name"
              className={`form-control  ${getInputClasses(
                formik,
                "external_name"
              )}`}
              {...formik.getFieldProps("external_name")}
            />
            {formik.touched.external_name && formik.errors.external_name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.external_name}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Internal.Name" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: "Internal.Name" })}
              label="Internal Name"
              className={`form-control  ${getInputClasses(formik, "name")}`}
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-12">
          <label>{intl.formatMessage({ id: "Description" })}</label>
          <div className="form-groupB">
            <textarea
              type="textarea"
              name="description"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Description" })}
              label="Description"
              rows="4"
              {...formik.getFieldProps("description")}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Addon.Id" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="id"
              className={`form-control  ${getInputClasses(formik, "id")}`}
              {...formik.getFieldProps("id")}
              placeholder={intl.formatMessage({ id: "Addon.Id" })}
              label="Addon Id"
              disabled={type === "edit"}
            />
            {formik.touched.id && formik.errors.id ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.id}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Unit.Measure" })}</label>
          <div className="form-groupB">
            <textarea
              type="textarea"
              name="unit"
              className="form-control"
              {...formik.getFieldProps("unit")}
              rows="4"
            />
            {formik.touched.unit && formik.errors.unit ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.unit}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="enabled_in_portal"
              checked={
                formik.values.enabled_in_portal === "true" ||
                formik.values.enabled_in_portal === true
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "enabled_in_portal",
                  String(e.target.checked)
                )
              }
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Display.Portal" })}
          </label>
        </div>
        {type === "edit" ? (
          ""
        ) : (
          <div className="col-6 col-sm-6">
            <div className="form-groupB">
              <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses(
                    formik,
                    "country"
                  )}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="country"
                  {...formik.getFieldProps("country")}
                  disabled={formik?.values?.profile_id === 1 ? true : false}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "COUNTRY" })}
                  </MenuItem>
                  {detailData && detailData?.length ? (
                    detailData.map((data, index) => (
                      <MenuItem value={data.id} key={index}>
                        {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span style={{ color: "lightgrey" }}>
                          {data.timezone}
                        </span>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="q"></MenuItem>
                  )}
                </Select>
              </FormControl>
              {formik.touched.country && formik.errors.country ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.country}</div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {type === "edit" && (
          <div className="col-6">
            <div className="form-groupB row">
              <label className="col-5 col-form-label ">
                {intl.formatMessage({ id: "ACTIVE" })}
              </label>
              <div className="col-7 text-right mt-1">
                <span className="switch switch-outline switch-icon switch-primary">
                  <label>
                    <input
                      type="checkbox"
                      checked={formik.values.status === "active" ? true : false}
                      onChange={() => setDialog(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <ActiveDialogCharge
        setDialog={setDialog}
        dialog={dialog}
        formik={formik}
      />
    </>
  );
}
