import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CollectionEdit } from "../order-canva/CollectionEdit";
import SVG from "react-inlinesvg";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { ContainerCollectionModal } from "../NewOrder/modal/ContainerCollectionModal";
import { Button } from "react-bootstrap";

export function NewOrderCollection({
  franchise,
  dispatchReducer,
  state,
  customerAddress,
  postcode,
  city,
  setKeyDis,
  paramData,
  setDialogType,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [keys, setKeys] = useState("collection");
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [collectionDate, setCollectionDate] = useState(new Date());

  const { depotFraList } = useSelector(
    (state) => ({
      depotFraList: state.franchise.depot,
    }),
    shallowEqual
  );
  const init = ["kt_collectionedit_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  // useEffect(() => {
  //   if (franchise) {
  //     dispatch(fetchFranchisesDepot(franchise));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [franchise]);
  const initialValues = {
    collection_date: "",
    return_date: "",
    return_start_time_slot: "",
    return_end_time_slot: "",
    start_time_slot: "",
    end_time_slot: "",
    depot_id:
      (
        depotFraList &&
        depotFraList.length > 0 &&
        depotFraList.find((e) => e.is_default && e.status)
      )?.depot_id || "",
    collection_van_id: "",
    return_van_id: "",
    is_collection_address: "0",
    is_return_address: "0",
    postcode: "",
    is_manual: "0",
    address: "",
    city: "",
    is_student: "",
    collection_postcode: postcode || "",
    return_postcode: postcode || "",
    is_address_manual: "0",
    is_return_address_manual: "0",
    collection_address_line: customerAddress || "",
    collection_address_city: city || "",
    return_address_line: customerAddress || "",
    return_address_city: city || "",
    date_type: "",
    site_class_id: "",
  };
  const initialValuesC = {
    collection_date: "",
    return_date: "",
    return_start_time_slot: "",
    return_end_time_slot: "",
    start_time_slot: "",
    end_time_slot: "",
    depot_id:
      (
        depotFraList &&
        depotFraList.length > 0 &&
        depotFraList.find((e) => e.is_default && e.status)
      )?.depot_id || "",
    collection_van_id: "",
    return_van_id: "",
    is_collection_address: "0",
    is_return_address: "0",
    postcode: "",
    is_manual: "0",
    address: "",
    city: "",
    is_student: "",
    collection_postcode: postcode || "",
    return_postcode: postcode || "",
    is_address_manual: "0",
    is_return_address_manual: "0",
    collection_address_line: customerAddress || "",
    collection_address_city: city || "",
    return_address_line: customerAddress || "",
    return_address_city: city || "",
    date_type: "2",
    site_class_id: "",
    container_return_date: "",
    container_collection_date: "",
  };
  const handleSetKeys = (value) => {
    setKeys(value);
    //distance Keys
    setKeyDis(value);
  };
  return (
    <>
      <div
        className="card card-custom gutter-b card-head  "
        style={{ height: "180px" }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div className="mr-3 mb-5">
                      <h4 className="card-title align-items-start flex-column mb-0">
                        <span className="font-weight-bolder text-dark">
                          Calendar{" "}
                          {state.containerTypeSelect !== "" &&
                            state.containerTypeSelect !== "container" && (
                              <Button
                                variant="primary"
                                onClick={() => setDialogType({ open: true })}

                              >
                                Edit Type
                              </Button>
                            )}

                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-icon">

              {state?.collectionRetrun?.collection_date && state?.containerTypeSelect === "container" && (
                <button
                  className="btn btn-primary font-weight-bolder font-size-sm "
                  onClick={() =>
                    dispatchReducer({
                      type: "collectionRetrunEmpty",
                      value: {},
                    })
                  }
                >
                  Reset
                </button>
              )}
              <span className="symbol-label ml-2">
                {state?.productArray?.length > 0 && (
                  <span
                    className="svg-icon svg-icon-sm svg-icon-primary "
                    role="button"
                    onClick={() => {
                      if (state?.containerTypeSelect === "container") {
                        setShow(true);
                        return;
                      }
                      if (franchise) {
                        dispatch(fetchFranchisesDepot({ franchise_id: franchise, postcode: postcode }));
                      }
                      dispatchReducer({ type: "isQuote", value: true });
                      setDialog(true);
                    }}
                  >
                    <SVG
                      title=" "
                      className="h-25 align-self-end"
                      src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    ></SVG>
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="neworderdtl-tab collection-area">
            {state?.containerTypeSelect !== "container" && (
              <>
                <button
                  className={`btn ${keys === "collection" ? "btn-primaryCus" : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                  onClick={() => handleSetKeys("collection")}
                >
                  {intl.formatMessage({ id: "Collection" })}
                </button>

                <button
                  className={`btn ${keys === "return" ? "btn-primaryCus" : "btn-dark"
                    } font-weight-bolder font-size-sm mr-3 `}
                  onClick={() => handleSetKeys("return")}
                  disabled={
                    !state?.collectionRetrun?.start_time_slot
                    // || state.productArray.some((e)=>e.id === "Howden")
                  }
                >
                  {intl.formatMessage({ id: "Return" })}
                </button>
                {state?.collectionRetrun?.collection_date && (
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm "
                    onClick={() =>
                      dispatchReducer({
                        type: "collectionRetrunEmpty",
                        value: {},
                      })
                    }
                  >
                    Reset
                  </button>
                )}
              </>
            )}
            {state?.collectionRetrun?.collection_date && (
              <>
                {state?.containerTypeSelect === "container" ? "" : <br />}
                {keys === "collection" ? (
                  <div
                    className={
                      state?.containerTypeSelect === "container" ? "" : `mt-6`
                    }
                  >
                    <strong>
                      {state?.containerTypeSelect === "container" &&
                        state.collectionRetrun?.container_collection_date
                        ? "Collection Date"
                        : intl.formatMessage({ id: "Collection.Date" })}
                      :{" "}
                    </strong>
                    {(state?.containerTypeSelect === "container" && state?.collectionRetrun?.container_collection_date ?
                      moment(
                        state?.collectionRetrun?.container_collection_date
                      ).format("DD-MM-YYYY") : state?.containerTypeSelect !== "container" ? state?.collectionRetrun?.collection_date : "-") ||
                      ""}
                    {state?.containerTypeSelect === "container" &&
                      state?.collectionRetrun?.collection_date && (
                        <div>
                          <strong>MoveIn Date: </strong>
                          {(state?.collectionRetrun?.collection_date &&
                            moment(
                              state?.collectionRetrun?.collection_date
                            ).format("DD-MM-YYYY")) ||
                            ""}
                        </div>
                      )}
                    {state?.containerTypeSelect === "container" &&
                      state?.collectionRetrun?.container_return_date && (
                        <div>
                          <strong>Return Date: </strong>
                          {(state?.collectionRetrun?.container_return_date &&
                            moment(
                              state?.collectionRetrun?.container_return_date
                            ).format("DD-MM-YYYY")) ||
                            ""}
                        </div>
                      )}
                    {state?.containerTypeSelect === "container" &&
                      state?.collectionRetrun?.return_date && (
                        <div>
                          <strong>MoveOut Date: </strong>
                          {(state?.collectionRetrun?.return_date &&
                            moment(state?.collectionRetrun?.return_date).format(
                              "DD-MM-YYYY"
                            )) ||
                            ""}
                        </div>
                      )}
                    {state?.containerTypeSelect === "container" ? "" : "-"}
                    {state?.collectionRetrun?.start_time_slot || ""}
                    {state?.collectionRetrun?.end_time_slot &&
                      state?.collectionRetrun?.start_time_slot !==
                      moment(
                        state?.collectionRetrun?.collection_date +
                        "T" +
                        state?.collectionRetrun?.end_time_slot
                      )
                        .subtract(1, "hours")
                        .format("HH:mm") &&
                      " - " +
                      moment(
                        state?.collectionRetrun?.collection_date +
                        "T" +
                        state?.collectionRetrun?.end_time_slot
                      )
                        .subtract(1, "hours")
                        .format("HH:mm")}
                  </div>
                ) : (
                  <div className="mt-6">
                    <strong>
                      {intl.formatMessage({ id: "Return.Date" })}:{" "}
                    </strong>{" "}
                    {(state?.collectionRetrun?.return_date &&
                      state?.collectionRetrun?.return_start_time_slot &&
                      moment(state?.collectionRetrun?.return_date).format(
                        "DD-MM-YYYY"
                      )) ||
                      ""}{" "}
                    - {state?.collectionRetrun?.return_start_time_slot || ""}
                    {state?.collectionRetrun?.return_end_time_slot &&
                      state?.collectionRetrun?.return_start_time_slot !==
                      moment(
                        state?.collectionRetrun?.return_date +
                        "T" +
                        state?.collectionRetrun?.return_end_time_slot
                      )
                        .subtract(1, "hours")
                        .format("HH:mm") &&
                      "- " +
                      moment(
                        state?.collectionRetrun?.return_date +
                        "T" +
                        state?.collectionRetrun?.return_end_time_slot
                      )
                        .subtract(1, "hours")
                        .format("HH:mm")}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* {show && */}
      <ContainerCollectionModal
        dispatchReducer={dispatchReducer}
        state={state}
        show={show}
        setShow={setShow}
        initialValues={initialValuesC}
        franchise={franchise}
      />
      {/* } */}
      <CollectionEdit
        title={"Job Date"}
        type="new_order"
        keys={keys}
        setKeys={setKeys}
        setDialog={setDialog}
        dialog={dialog}
        collectionDate={collectionDate}
        setCollectionDate={setCollectionDate}
        depotList={depotFraList}
        franchise={franchise}
        dispatchReducer={dispatchReducer}
        state={state}
        initialValues={initialValues}
        paramData={paramData}
      />
    </>
  );
}
