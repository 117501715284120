/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import {
  fetchBillingCardDetail,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";

import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import { PaymentPlanSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { addCharge, addPodCharge, paymentPlan } from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";

export function Charge20({ orderDetail, billingCard }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);


  const initialValues = {
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    invoice_id: billingCard?.old_unpaid_invoice?.invoice?.id || "",
    invoice_due_amount:
      billingCard?.in_arrears == 0 ? 0 : +billingCard?.in_arrears?.toFixed(2),
    twenty_percent_amount:
      (+billingCard?.in_arrears?.toFixed(2) * (0.1))?.toFixed(2),
    eighty_percent_amount:
      (+billingCard?.in_arrears?.toFixed(2) * (0.9))?.toFixed(2),
    twenty_percent_status: 1,
  };

  const handleSubmit = (values, actions) => {
    // actions.resetForm({ values: "" });
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    addPodCharge(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        setLoading(false);
        actions.resetForm({ values: "" });
        canvaCancel("kt_Charge20_panel_close");
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: PaymentPlanSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleDiscard = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_Charge20_panel_close");
      formik.resetForm();
    }
  };

  return (
    <div
      id="kt_Charge20_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_Charge20_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>
               Collect 10%&nbsp;(#
              {billingCard?.old_unpaid_invoice?.invoice?.id || "-"})
            </h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_Charge20_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>10 % charge</Form.Label>
                <Form.Control
                  type="text"
                  
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  value={(+billingCard?.in_arrears?.toFixed(2) * (0.1))?.toFixed(2) || 0}
                  disabled
                  onChange={(e) => {
                    if (Number(e.target.value) > 0)
                      formik.setFieldValue("amount", e.target.value);
                  }}
                  // {...formik.getFieldProps("amount")}
                />
                {/* {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null} */}
              </Form.Group>
            </div>
            {/* <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {intl.formatMessage({ id: "Due.Arrears" })}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    billingCard?.currency_code
                      ? convertGBPtoPoundSymbol(billingCard?.currency_code)
                      : "-"
                  }
                  className={`form-control  ${getInputClasses("due_amount")}`}
                  name="due_amount"
                  value={billingCard?.in_arrears || 0}
                  disabled
                />
                {formik.touched.due_amount && formik.errors.due_amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.due_amount}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div> */}
            {/* <div className="mb-3">
              <strong>
                {intl.formatMessage({ id: "Missing.Billing.Periods" })}
              </strong>{" "}
              <br></br>
              {billingCard?.missing_billing_period || "-"}
            </div> */}
            {/* <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {intl.formatMessage({ id: "Description" })}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={`form-control  ${getInputClasses("comment")}`}
                  name="comment"
                  {...formik.getFieldProps("comment")}
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.comment}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div> */}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "Payment" })}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>

      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_Charge20_panel_close"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
