// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../TasksUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getHandlerTableChange,
  getSelectRow,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
// import { ChangeTasksCanva } from "../tasks-canvas/ChangeTasksCanva";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { fetchQTasks } from "../_redux/QTasksAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useQTasksUIContext } from "../TasksUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";

const QuotesTable = ({ show, setShow, permission }) => {
  const intl = useIntl();
  const TasksUIContext = useQTasksUIContext();
  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  const TasksUIProps = useMemo(() => {
    return {
      ids: TasksUIContext?.ids,
      setIds: TasksUIContext?.setIds,
      queryParams: TasksUIContext?.queryParams,
      setQueryParams: TasksUIContext?.setQueryParams,
      openEditContactPage: TasksUIContext?.openEditContactPage,
      openDeleteContactDialog: TasksUIContext?.openDeleteContactDialog,
    };
  }, [TasksUIContext]);

  const history = useHistory();

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.quotes.list }),
    shallowEqual
  );

  const { totalCount, entities, loading } = currentState;
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    TasksUIProps.setIds([]);

    dispatch(fetchQTasks(TasksUIProps.queryParams));
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);

  useEffect(() => {
    TasksUIProps.setQueryParams(uiHelpers.initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const columns = [
    {
      dataField: "",
      text: "Name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.deal_data?.contact?.user?.first_name}{" "}
            {data?.deal_data?.contact?.user?.last_name}
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "franchise_name",
      text: "Franchise",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "",
      text: "Source",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        if (data?.source === "Online") {
          return "Online";
        } else if (data?.source === "Draft") {
          return "V2";
        } else if (data?.source === "Quote") {
          return "V2";
        } else if (data?.source === "PostHog") {
          return "PostHog";
        }
      },
    },
    {
      dataField: "",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        if (data?.source === "Online") {
          return "Quote";
        } else if (data?.source === "Draft") {
          return "Draft";
        } else if (data?.source === "Quote") {
          return "Quote";
        } else if (data?.source === "PostHog") {
          return "Quote";
        }
        // if (data?.deal_data?.deal_status === "Close-Won") {
        //   return "Closed";
        // } else if (data?.deal_data?.deal_status === "Close-Lost") {
        //   return "Lost";
        // } else if (
        //   data?.deal_data?.deal_status === "Transit" ||
        //   data?.deal_data?.deal_status === "Pipeline"
        // ) {
        //   return "Open";
        // } else if (data?.deal_data?.deal_status === "Open") {
        //   return "Open";
        // }
      },
    },
    {
      dataField: "",
      text: "Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        if (data?.deal_data?.deal_status === "Close-Won") {
          return "Closed";
        } else if (data?.deal_data?.deal_status === "Close-Lost") {
          return "Lost";
        } else if (
          data?.deal_data?.deal_status === "Transit" ||
          data?.deal_data?.deal_status === "Pipeline"
        ) {
          return "Open";
        } else if (data?.deal_data?.deal_status === "Open") {
          return "Open";
        }
      },
    },
    {
      dataField: "",
      text: "Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span> £{data?.amount}</span>;
      },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "Create.Date" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{moment(e).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/new-order?quote`, {
        franchise_id: row.franchise_id,
        quote: row.quote_data?.id,
      });

      // history.push(`/deal-view/${row.deal_id}`);
    },
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
            >
              {show && (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  hover
                  keyField="id"
                  defaultSorted={uiHelpers.defaultSorted}
                  data={
                    !permission?.task?.view || entities === null ? [] : entities
                  }
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                <NotAuthorized />
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
      {/* <ChangeTasksCanva setShow={setShow} /> */}
      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default QuotesTable;
