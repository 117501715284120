import * as requestFromServer from "./countryCrud";
import { countrySlice, callTypes } from "./countrySlice";

const { actions } = countrySlice;
//This Function is for fetching active country
export const fetchActiveCountry = (type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findActiveCountry()
    .then((response) => {
      const { data, data_two } = response.data;
      if (type?.type === "data_two") {
        dispatch(actions.activeCountryFetched({ data: data_two }));
      } else {
        dispatch(actions.activeCountryFetched({ data }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//This function is for fetching all country list
export const fetchCountryList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCountryList(queryParams)
    .then((response) => {
      const { data, meta } = response.data;

      dispatch(
        actions.countryFetchedList({ totalCount: meta.total, entities: data })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//This function is for detail of country data
export const fetchCountryDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCountryDetail(queryParams)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.countryDetail({ data }));
    })
    .catch((error) => {
      if (error?.response?.status === 404 || error?.response?.status === 400) {
        // Redirect to the error page
        window.location.href = "/error/error-v1"; // Replace '/error' with the actual route of your error page
      }
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//This function clears the redux state of country field
export const countryDetailEmpty = () => (dispatch) => {
  dispatch(actions.countryDetail({ data: {} }));
};
