export const dealsUpdateApiCall = ({
  dealsUpdate,
  contactDetail,
  setSnack,
  values,
  setLoading,
  dispatch,
  fetchDealDetail,
  setOpenCanva,
}) => {
  dealsUpdate(contactDetail && contactDetail.id, values)
    .then((res) => {
      setSnack({
        call: true,
        type: "success",
        message: res.data.message,
      });
      setLoading(false);
      dispatch(fetchDealDetail(contactDetail.id));
      setOpenCanva(false);
    })
    .catch((error) => {
      setSnack({
        call: true,
        type: "error",
        message: error?.response?.data?.message,
      });
      setLoading(false);
    });
};

export const updateCustomerApiCall = ({
  fetchMyOrderDetailCard,
  orderId,
  setOpenCanva,
  updateContact,
  editCustomerId,
  values,
  setSnack,
  setLoading,
  fetchNewCustomerCard,
  dispatch,
  check,
  pageCheck,
  formik,
  setBillingCall,
  isPopSelected,
  handleContactUpdate
}) => {
  updateContact(editCustomerId, values)
    .then((res) => {
      setSnack({
        call: true,
        type: "success",
        message: "Customer details updated succesfully ",
      });

      setLoading(false);
      dispatch(fetchNewCustomerCard(values.user_id));

      dispatch(fetchMyOrderDetailCard(orderId));
      setTimeout(() => {
        setOpenCanva(false);

      }, 1000);

      if (
        check === "customer" &&
        pageCheck === "new_order" &&
        formik.initialValues?.franchise_id !== values?.franchise_id
      ) {
        setBillingCall(true);
        // window.location.reload();
      }
      if (res?.data?.data?.plan_type?.length > 0) {

        handleContactUpdate(res?.data?.data)
      }
    })
    .catch((error) => {
      setSnack({
        call: true,
        type: "error",
        message: error?.response?.data?.message,
        // error: error?.response?.data?.errors,
      });
      setLoading(false);
    });
};

export const updateContactApiCall = ({
  setOpenCanva,
  updateContact,
  contactDetail,
  values,
  setSnack,
  setLoading,
  dispatch,
  fetchContactDetail,
}) => {
  updateContact(contactDetail && contactDetail.id, values)
    .then((res) => {
      setSnack({
        call: true,
        type: "success",
        message: res.data.message,
      });
      setLoading(false);
      dispatch(fetchContactDetail(contactDetail.id));
      setOpenCanva(false);
    })
    .catch((error) => {
      setSnack({
        call: true,
        type: "error",
        // message: error?.response?.data?.message,
        error: error?.response?.data?.errors,
      });
      setLoading(false);
    });
};

export const createContactApiCall = ({
  createContact,
  values,
  setSnack,
  dispatch,
  fetchContact,
  initialFilter,
  setOpenCanva,
  check,
  history,
  setLoading,
}) => {
  createContact(values)
    .then((res) => {
      setSnack({
        call: true,
        type: "success",
        message: res.data.message,
      });
      dispatch(fetchContact(initialFilter));
      setTimeout(() => {
        setOpenCanva(false);
      }, 2000);
      if (check === "customer") {
        history.push("/new-order?customer", {
          id: res?.data?.data?.user_id,
          type: "deal",
          dealId: res?.data?.data?.id,
        });
      }
      setLoading(false);
    })
    .catch((error) => {
      setSnack({
        call: true,
        type: "error",
        // message: error.response.data.message,
        error: error?.response?.data?.errors,
      });
      setLoading(false);
    });
};

export const editContactValue = (contactDetail) => {
  let cus_address =
    contactDetail && contactDetail.user_data && contactDetail.user_data.address
      ? contactDetail.user_data.address
      : "";
  if (cus_address !== "") {
    cus_address = cus_address?.replaceAll(", ", ",");
    cus_address = cus_address?.replaceAll(",, ", ",");
    cus_address = cus_address?.replaceAll(",,,", ",");
  }
  return {
    first_name:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.first_name
        ? contactDetail.user_data.first_name
        : "",
    last_name:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.last_name
        ? contactDetail.user_data.last_name
        : "",
    email: contactDetail && contactDetail.email ? contactDetail.email : [],
    phone: contactDetail && contactDetail.phone ? contactDetail.phone : [],
    postcode:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.postcode
        ? contactDetail.user_data.postcode
        : "",
    city:
      contactDetail && contactDetail.user_data && contactDetail.user_data.city
        ? contactDetail.user_data.city
        : "",
    address: cus_address,
    user_id:
      contactDetail && contactDetail.user_id ? contactDetail.user_id : "",
    is_address_manual:
      contactDetail && contactDetail.is_address_manual
        ? contactDetail.is_address_manual
        : "0",
    franchise_id:
      contactDetail && contactDetail.franchise_id
        ? contactDetail.franchise_id
        : "",
  };
};

export const editDeal = (contactDetail) => {
  return {
    contact_id:
      contactDetail && contactDetail.contact_id ? contactDetail.contact_id : "",
    first_name:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.first_name
        ? contactDetail.user_data.first_name
        : "",
    last_name:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.last_name
        ? contactDetail.user_data.last_name
        : "",
    email:
      contactDetail &&
        contactDetail.contact_data &&
        contactDetail.contact_data.email
        ? contactDetail.contact_data.email
        : [""],
    phone:
      contactDetail &&
        contactDetail.contact_data &&
        contactDetail.contact_data.phone
        ? contactDetail.contact_data.phone
        : [""],
    postcode:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.postcode
        ? contactDetail.user_data.postcode
        : "",
    city:
      contactDetail && contactDetail.user_data && contactDetail.user_data.city
        ? contactDetail.user_data.city
        : "",
    address:
      contactDetail &&
        contactDetail.user_data &&
        contactDetail.user_data.address
        ? contactDetail.user_data.address
        : "",
    user_id:
      contactDetail &&
        contactDetail.contact_data &&
        contactDetail.contact_data.user_id
        ? contactDetail.contact_data.user_id
        : "",
    is_address_manual:
      contactDetail &&
        contactDetail.contact_data &&
        contactDetail.contact_data.is_address_manual
        ? contactDetail.contact_data.is_address_manual
        : "0",
    franchise_id:
      contactDetail && contactDetail.franchise_id
        ? contactDetail.franchise_id
        : "",
  };
};

export const initialValuesSms = (detailData, ID) => {
  return {
    contact_id: ID ? ID : "",
    email_to:
      detailData && detailData.user_data && detailData.user_data.email
        ? detailData.user_data.email
        : "",

    subject: "",
    description: "",
  };
};

export const initialValuesTasks = (canva) => {
  return {
    contact_id:
      canva && canva.data && canva.data.contact_id ? canva.data.contact_id : "",
    task_type:
      canva && canva.data && canva.data.task_type ? canva.data.task_type : "",
    task_name:
      canva && canva.data && canva.data.task_name ? canva.data.task_name : "",
    due_date:
      canva && canva.data && canva.data.due_date ? canva.data.due_date : "",
    task_owner_id:
      canva &&
        canva.data &&
        canva.data.is_unassigned &&
        canva.data.is_unassigned === 1
        ? "is_unassigned"
        : canva && canva.data && canva.data.task_owner_id
          ? canva.data.task_owner_id
          : "",
    task_description:
      canva && canva.data && canva.data.task_description
        ? canva.data.task_description
        : "",
    priority:
      canva && canva.data && canva.data.priority ? canva.data.priority : "",
    time: canva && canva.data && canva.data.time ? canva.data.time : "",
  };
};

export const initialValuesDealTasks = (canva) => {
  return {
    deal_id:
      canva && canva.data && canva.data.deal_id ? canva.data.deal_id : "",
    contact_id:
      canva && canva.data && canva.data.contact_id ? canva.data.contact_id : "",
    task_type:
      canva && canva.data && canva.data.task_type ? canva.data.task_type : "",
    task_name:
      canva && canva.data && canva.data.task_name ? canva.data.task_name : "",
    due_date:
      canva && canva.data && canva.data.due_date ? canva.data.due_date : "",
    task_owner_id:
      canva && canva.data && canva.data.task_owner_id
        ? canva.data.task_owner_id
        : "",
    task_description:
      canva && canva.data && canva.data.task_description
        ? canva.data.task_description
        : "",
    priority:
      canva && canva.data && canva.data.priority ? canva.data.priority : "",
    time: canva && canva.data && canva.data.time ? canva.data.time : "",
  };
};
