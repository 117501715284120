import React, { useEffect, useState } from "react";
import AutoCollectModal from "../../order-modal/AutoCollect";
import RequestPaymentModal from "../../order-modal/RequestPayment";
import ExemptDialog from "../EditOrderComponents/Modal/ExemptModal";
import { showCharge20 } from "../../_redux/order/orderCrud";
import SendBoxLabelModal from "../EditOrderComponents/Modal/SendBoxLabelModal";
import EmailCustomerModal from "../../order-modal/EmailCustomer";
import ReactivateSubModal from "../../order-modal/ReactivateSub";

const BillingActionDropdown = ({
  intl,
  billingCard,
  setDialog,
  orderDetail,
  setDialogRecur,
  dialogRecur,
  setEightCharge,
  cardDetailArray
}) => {
  // eslint-disable-next-line no-unused-vars
  const [showHisInvoice, setHisInvoice] = useState(false);
  const [dialogAuto, setDialogAuto] = useState({ check: false });
  const [dialogs, setDialogs] = useState({ check: false });
  const [dialogEmail, setDialogEmail] = useState({ check: false });

  const [excempt, setExcempt] = useState(false);
  const [sendLabel, setSendLabel] = useState(false);
  const [charge, setCharge] = useState(true);
  const [reactivateModal, setReactivateModal] = useState(false);


  useEffect(() => {
    if (orderDetail?.created_at) {
      let date = new Date("2023-05-18T09:15:16.000000Z").getTime();
      let orderDate = new Date(orderDetail?.created_at).getTime();
      if (orderDate <= date) {
        setHisInvoice(true);
      } else {
        setHisInvoice(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.created_at]);

  useEffect(() => {
    if (orderDetail?.id) {
      showCharge20(orderDetail?.id).then((res) => {
        if (
          res?.data?.data?.twenty_percent_status !== 1 &&
          res?.data?.data?.eighty_percent_status !== 1
        ) {
          setCharge(true);
        } else {
          setCharge(false);
        }
        if (res?.data?.data?.eighty_percent_status) {
          setEightCharge(true);
        }
      });
    }
  }, [orderDetail?.id]);
  return (
    <>
      <ul className="navi navi-hover">
        <li className="navi-item pointer">
          <span className="navi-link" id="kt_OrderInvoice_panel_toggle">
            <span className="navi-text">
              {intl.formatMessage({ id: "Invoices" })}
            </span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span className="navi-link" id="kt_EditNextBilling_panel_toggle">
            <span className="navi-text">
              {intl.formatMessage({ id: "Edit.Next.Billing.Date" })}
            </span>
          </span>
        </li>

        <li className="navi-item pointer">
          <span className="navi-link" id="kt_EditAdhocCharge_panel_toggle">
            <span className="navi-text">
              {intl.formatMessage({ id: "Create.Hoc.Charge" })}
            </span>
          </span>
        </li>
        {billingCard?.old_unpaid_invoice !== "" && (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_PaymentPlan_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Payment.Plan" })}
              </span>
            </span>
          </li>
        )}
        {(orderDetail?.payment_method === "bac" ||
          orderDetail?.payment_method === "cash") && orderDetail?.order_details?.pod_plan_type != "Economy" && orderDetail?.franchise_id == 6 && (
            <li className="navi-item pointer">
              <span className="navi-link" id="kt_Charge20_panel_toggle">
                <span className="navi-text"> Collect 10%</span>
              </span>
            </li>
          )}
        {billingCard?.old_unpaid_invoice !== "" && (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_PartPayment_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Part.Payment" })}
              </span>
            </span>
          </li>
        )}
        <span
          style={{
            display: `${orderDetail?.subscription_data?.status !== "paused"
              ? "block"
              : "none"
              }`,
          }}
        >
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_PutOnHold_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Put.Hold" })}
              </span>
            </span>
          </li>
        </span>
        {billingCard?.late_fee_status === 0 ||
          billingCard?.late_fee_status === 1 ? (
          <span>
            <li className="navi-item pointer">
              <span
                className="navi-link"
                onClick={() =>
                  setDialog({
                    type: true,
                    check:
                      billingCard?.late_fee_status === 1 ? "stop" : "activate",
                  })
                }
              >
                <span className="navi-text">
                  {billingCard?.late_fee_status === 1
                    ? "Disable Late fee"
                    : "Enable Late fee"}
                </span>
              </span>
            </li>
          </span>
        ) : (
          ""
        )}
        {orderDetail?.subscription_data?.status === "paused" ? (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setDialog({ type: true, check: "resume" })}
            >
              <span className="navi-text">
                {intl.formatMessage({ id: "Resume.Subscription" })}
              </span>
            </span>
          </li>
        ) : (
          ""
        )}
        {orderDetail?.is_collection_completed !== 1 ||
          orderDetail?.subscription_data?.status !== "cancelled" ? (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_CancelSubscription_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Cancel.Subscription" })}
              </span>
            </span>
          </li>
        ) : (
          ""
        )}
        {showHisInvoice ? (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() =>
                setDialogRecur({
                  dialog: true,
                  orderId: orderDetail?.id,
                  bookRef: orderDetail?.booking_reference,
                  ordCreatedAt: orderDetail?.created_at,
                })
              }
            >
              <span className="navi-text">Historical Invoices</span>
            </span>
          </li>
        ) : (
          ""
        )}
        {(orderDetail?.payment_method === "card" || cardDetailArray?.length > 0) ?
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setDialogAuto({ check: true })}
            >
              <span className="navi-text">Auto Collection</span>
            </span>
          </li>
          : ""}
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setDialogs({ check: true })}
          >
            <span className="navi-text">Email for payment</span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setDialogEmail({ check: true })}
          >
            <span className="navi-text">Resend Order Confirmation Email</span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span className="navi-link" onClick={() => setExcempt(true)}>
            <span className="navi-text">EXEMPT Automated Price Inc</span>
          </span>
        </li>
        {orderDetail?.is_container == 3 &&
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setSendLabel(true)}
            >
              <span className="navi-text">Email for Send Label</span>
            </span>
          </li>
        }
        {orderDetail?.subscription_data?.status == "cancelled" &&
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setReactivateModal({ check: true })}
            >
              <span className="navi-text">Reactivate Subscription</span>
            </span>
          </li>
        }
      </ul>
      <AutoCollectModal
        orderDetail={orderDetail}
        dialog={dialogAuto}
        setDialog={setDialogAuto}
        billingCard={billingCard}
      />
      {dialogs.check && (
        <RequestPaymentModal
          dialog={dialogs}
          setDialog={setDialogs}
          orderDetail={orderDetail}
        />
      )}
      {dialogEmail.check && (
        <EmailCustomerModal
          dialog={dialogEmail}
          setDialog={setDialogEmail}
          orderDetail={orderDetail}
        />
      )}
      {excempt && (
        <ExemptDialog
          dialog={excempt}
          setDialog={setExcempt}
          orderId={orderDetail?.id}
        />
      )}
      {sendLabel && (
        <SendBoxLabelModal
          dialog={sendLabel}
          setDialog={setSendLabel}
          orderId={orderDetail?.id}
        />
      )}
      {reactivateModal.check && <ReactivateSubModal
        dialog={reactivateModal}
        setDialog={setReactivateModal}
        orderId={orderDetail?.id}
        orderDetail={orderDetail} />}
    </>
  );
};

export default BillingActionDropdown;
