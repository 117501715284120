import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { OrderContainerCollectionModal } from "../order-modal/OrderContainerCollectionModal";
import moment from "moment";
import {
  collectionComplete,
  confirmContainer,
  moveInComplete,
} from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import CustomeOrderSaveDialog from "../../../../_metronic/_partials/widgets/alert/CustomeOrderSaveDialog";
import DynamicDialog from "../../../../_metronic/_partials/widgets/alert/DynamicDialog";

export const OrderContainerCollection = ({ orderDetail }) => {
  const [key, setKey] = useState("moveIn");
  const [show, setShow] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialog, setDialog] = useState({ modal: false });

  const dispatch = useDispatch();

  const initialValueMI = {
    date_type: "1",
    update_type: "move_in",
    collection_date: orderDetail?.collection_date || "",
    is_return: "0",
    is_student: "0",
    mail_to_customer: "",
    max_pod: 3,
    order_id: orderDetail?.id || "",
    container_size: orderDetail?.container_size || "",
    site_id:
      (orderDetail?.order_container_details &&
        orderDetail?.order_container_details.length > 0 &&
        orderDetail?.order_container_details[0]?.site_id) ||
      "",
  };
  const initialValueCO = {
    date_type: "1",
    update_type: "collection",
    container_collection_date: orderDetail?.container_collection_date || "",
    is_return: "0",
    is_student: "0",
    mail_to_customer: "",
    max_pod: 3,
    order_id: orderDetail?.id || "",
    collection_address_city: orderDetail?.collection_address_city || "",
    collection_address_line: orderDetail?.collection_address_line || "",
    collection_postcode: orderDetail?.collection_postcode || "",
    is_manual: orderDetail?.is_manual || "",
    is_collection_address: orderDetail?.is_collection_address || "",
    container_size: orderDetail?.container_size || "",
    site_id:
      (orderDetail?.order_container_details &&
        orderDetail?.order_container_details.length > 0 &&
        orderDetail?.order_container_details[0]?.site_id) ||
      "",
  };
  const initialValueRe = {
    update_type: "move_out",
    order_id: orderDetail?.id || "",
    is_student_return: "0",
    return_date: orderDetail?.return_date || "",
    is_return: 1,
    mail_to_customer: "",
    is_return_cost: 0,
    price_id: orderDetail?.subscription_data?.subscription_items?.find(
      (e) => e.type === "plan"
    )?.item_price_id,
    container_size: orderDetail?.container_size || "",
    site_id:
      (orderDetail?.order_container_details &&
        orderDetail?.order_container_details.length > 0 &&
        orderDetail?.order_container_details[0]?.site_id) ||
      "",
    is_penalty_charge: 0,
    penalty_charge: 0,
  };
  const initialValueMO = {
    update_type: "return",
    order_id: orderDetail?.id || "",
    is_student_return: "0",
    container_return_date: orderDetail?.container_return_date || "",
    is_return: 1,
    mail_to_customer: "",
    is_return_cost: 0,
    price_id: orderDetail?.subscription_data?.subscription_items?.find(
      (e) => e.type === "plan"
    )?.item_price_id,
    is_return_address: 0,
    return_address_city: orderDetail?.collection_address_city,
    return_postcode: orderDetail?.collection_postcode || "",
    return_address_line: orderDetail?.collection_address_line || "",
    is_manual: "",
    container_size: orderDetail?.container_size || "",

    site_id:
      (orderDetail?.order_container_details &&
        orderDetail?.order_container_details.length > 0 &&
        orderDetail?.order_container_details[0]?.site_id) ||
      "",
  };
  const initialValue = {
    date_type: "1",
    collection_date: "",
    is_return: "0",
    is_student: "0",
    mail_to_customer: "",
    max_pod: 3,
    order_id: orderDetail?.id || "",
  };

  const handleConfirm = () => {
    setSnack({ call: false, type: "", message: "" });
    const data = {
      order_id: orderDetail?.id || "",
    };
    confirmContainer(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleComplete = () => {
    const data = {
      order_id: orderDetail?.id || "",
      type: "collection",
    };
    const dataMo = {
      order_id: orderDetail?.id || "",
      container_fulfillment_stage: 3,
      status: "in storage",
    };
    if (dialog?.type !== "moveIn") {
      collectionComplete(data)
        .then((res) => {
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          setTimeout(() => {
            setDialog({ modal: false });
          }, 2000);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message || "",
          });
        });
    } else {
      moveInComplete(dataMo)
        .then((res) => {
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          setTimeout(() => {
            setDialog({ modal: false });
          }, 2000);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message || "",
          });
        });
    }
  };

  return (
    <div className="card card-custom gutter-b customer-duration ">
      <div className="card-body">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="moveIn" title="MoveIn">
            <div class="neworderdtl-tab">
              <div class="edit-icon"></div>
              <form>
                <div class="row mt-4 align-items-center order-collection ">
                  <div class="w-100 justify-content-between d-flex">
                    <h5 class="card-title align-items-start flex-column mb-3 ml-4">
                      <span class="font-weight-bolder text-dark">
                        {/* <sup class="top-4">
                            <span class="badge badge-pill badge-danger">
                              Not completed
                            </span>
                          </sup> */}
                      </span>
                    </h5>
                    <div class="dropdown-inline mr-4 dropdown">
                      {orderDetail?.container_status != "3" &&orderDetail?.container_status != 5 && (
                        <button
                          onClick={() =>
                            setDialog({ modal: true, type: "moveIn" })
                          }
                          type="button"
                          class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                        >
                          Complete
                        </button>
                      )}
                      &nbsp;
                      <button
                        onClick={() => setShow(true)}
                        type="button"
                        class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  {/* <div class="col-xl-12 mb-2">
                      <strong>MoveIn Address : </strong>39a Brushfield
                      Street, London, , E1 6AA
                    </div> */}
                  <div class="col-xl-6 mb-2">
                    <strong>Date</strong>:{" "}
                    {moment(orderDetail?.collection_date).format("DD-MM-yyyy")}
                  </div>
                  <div class="col-xl-12 mb-2">
                    <strong>Site</strong>:{" "}
                    {orderDetail?.container_site || "-"}
                  </div>
                </div>
              </form>
            </div>
          </Tab>
          <Tab eventKey="collection" title="Collection">
            <div class="neworderdtl-tab">
              <div class="edit-icon"></div>
              <form>
                <div class="row mt-4 align-items-center order-collection ">
                  <div class="w-100 justify-content-between d-flex">
                    <h5 class="card-title align-items-start flex-column mb-3 ml-4">
                      {orderDetail?.container_collection_date && (
                        <span class="font-weight-bolder text-dark">
                          <sup class="top-4">
                            <span
                              class={`badge badge-pill ${
                                orderDetail?.is_collection_completed === 1
                                  ? "badge-success"
                                  : "badge-danger"
                              }`}
                            >
                              {orderDetail?.is_collection_completed === 1
                                ? "Completed"
                                : "Not completed"}
                            </span>
                          </sup>
                        </span>
                      )}
                    </h5>
                    <div class="dropdown-inline mr-4 dropdown">
                      {orderDetail?.is_collection_completed !== 1 &&
                        orderDetail?.container_status != 5 && orderDetail?.container_collection_date && (
                          <button
                            onClick={() => setDialog({ modal: true })}
                            type="button"
                            class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                          >
                            Complete
                          </button>
                        )}
                      &nbsp;
                      <button
                        onClick={() => setShow(true)}
                        type="button"
                        class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  {orderDetail?.container_collection_date && (
                    <div class="col-xl-12 mb-2">
                      <strong>Collection Address : </strong>
                      {orderDetail?.collection_address_line}
                    </div>
                  )}
                  <div class="col-xl-6 mb-2">
                    <strong>Date</strong>:{" "}
                    {(orderDetail?.container_collection_date &&
                      moment(orderDetail?.container_collection_date).format(
                        "DD-MM-yyyy"
                      )) ||
                      "-"}
                  </div>
                </div>
              </form>
            </div>
          </Tab>
          <Tab eventKey="moveOut" title="MoveOut">
            <div class="neworderdtl-tab">
              <div class="edit-icon"></div>
              <form>
                <div class="row mt-4 align-items-center order-collection ">
                  <div class="w-100 justify-content-between d-flex">
                    <h5 class="card-title align-items-start flex-column mb-3 ml-4">
                      <span class="font-weight-bolder text-dark">
                        {/* <sup class="top-4">
                          <span class="badge badge-pill badge-danger">
                            Not completed
                          </span>
                        </sup> */}
                      </span>
                    </h5>
                    <div class="dropdown-inline mr-4 dropdown">
                      <button
                        onClick={() => setShow(true)}
                        type="button"
                        class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                      >
                        Edit
                      </button>
                      &nbsp;
                      {orderDetail?.return_date && (
                        <button
                          onClick={handleConfirm}
                          type="button"
                          class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                        >
                          Confirmed
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div class="col-xl-12 mb-2">
                    <strong>MoveOut Address : </strong>39a Brushfield Street,
                    London, , E1 6AA
                  </div> */}
                  <div class="col-xl-6 mb-2">
                    <strong>Date</strong>:{" "}
                    {(orderDetail?.return_date &&
                      moment(orderDetail?.return_date).format("DD-MM-yyyy")) ||
                      "-"}
                  </div>
                </div>
              </form>
            </div>
          </Tab>
          <Tab eventKey="return" title="Return">
            <div class="neworderdtl-tab">
              <div class="edit-icon"></div>
              <form>
                <div class="row mt-4 align-items-center order-collection ">
                  <div class="w-100 justify-content-between d-flex">
                    <h5 class="card-title align-items-start flex-column mb-3 ml-4">
                      {/* <span class="font-weight-bolder text-dark">
                        <sup class="top-4">
                          <span class="badge badge-pill badge-danger">
                            Not completed
                          </span>
                        </sup>
                      </span> */}
                    </h5>
                    <div class="dropdown-inline mr-4 dropdown">
                      <button
                        onClick={() => setShow(true)}
                        type="button"
                        class="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75 dropdown-toggle btn btn-transparent"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-12 mb-2">
                    <strong>Return Address : </strong>{" "}
                    {orderDetail?.return_address_line}
                  </div>
                  <div class="col-xl-6 mb-2">
                    <strong>Date</strong>:{" "}
                    {orderDetail?.container_return_date &&
                      moment(orderDetail?.container_return_date).format(
                        "DD-MM-yyyy"
                      )}
                  </div>
                </div>
              </form>
            </div>
          </Tab>
        </Tabs>
      </div>
      <OrderContainerCollectionModal
        // dispatchReducer={dispatchReducer}
        // state={state}
        orderDetail={orderDetail}
        check={"order"}
        keyC={key}
        setKeyC={setKey}
        show={show}
        setShow={setShow}
        initialValues={
          key === "moveIn"
            ? initialValueMI
            : key === "collection"
            ? initialValueCO
            : key === "moveOut"
            ? initialValueRe
            : key === "return"
            ? initialValueMO
            : initialValue
        }
        // franchise={franchise}
      />

      <DynamicDialog
        dialog={dialog}
        setDialog={setDialog}
        header={
          dialog?.type === "moveIn" ? "Complete MoveIn " : "Complete Collection"
        }
        mainText={
          dialog?.type === "moveIn"
            ? "Are you sure you want to complete this MoveIn"
            : "Are you sure you want to complete this Collection?"
        }
        handleChange={handleComplete}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
