export const promotions = [
  { id: "name", title: "Offer Code" },

  { id: "discountType", title: "Discount Type" },

  { id: "createdAt", title: "Valid From" },

  { id: "status", title: "Status" },
];

export const country = [{ id: 1, title: "India" }];
export const countryShort = [{ id: 1, title: "Ind" }];
export const language = [{ id: 1, title: "Hindi" }];
export const currency = [{ id: 1, title: "Rupee" }];
export const timeZone = [{ id: 1, title: "India" }];
