/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import {
  fetchInvoiceList,
  fetchMyOrderTimeline,
  fetchOrderDropDown,
  fetchOrderInvoice,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CreditNote } from "../order-form/validationSchema";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { creditNotes } from "../_redux/order/orderCrud";
import { useFormik } from "formik";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";

export function CreditNotes({ setCanva, canva, orderDetailData }) {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    franchise_id: canva?.data?.franchise_id || "",
    invoice_id: canva?.data?.invoice_id || "",
    order_id: canva?.data?.order_id || "",
    currency_code: canva?.data?.currency || "",
    amount: "",
    type: "",
    reason_code: "",
    notes: "",
  };

  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDropDown,
    }),
    shallowEqual
  );

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    creditNotes(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setCanva({ type: false });
        }, 2000);
        setLoading(false);
        dispatch(
          fetchPaymentInvoice(
            canva?.data?.order_id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(fetchOrderInvoice(canva?.data?.order_id));
        dispatch(
          fetchInvoiceList(
            canva?.data?.franchise_id,
            canva?.data?.order_id,
            orderDetailData?.subscription_id
          )
        );
        dispatch(fetchMyOrderTimeline(canva?.data?.order_id));
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreditNote,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    dispatch(fetchOrderDropDown());
  }, [dispatch]);

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched).length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: "" });
      // canvaCancel("kt_plan_panel_close");
      formik.resetForm();
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>Credit Note</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_CreditNotes_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>Create Credit Note to</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="type"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    className={`form-control w-100 ${getInputClasses("type")}`}
                    {...formik.getFieldProps("type")}
                  >
                    <MenuItem value="">Create Credit Note to</MenuItem>
                    {orderDetail &&
                    orderDetail?.creditNoteTo &&
                    orderDetail.creditNoteTo.length &&
                    orderDetail.creditNoteTo.length > 0 ? (
                      orderDetail.creditNoteTo.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="no"></MenuItem>
                    )}
                  </Select>
                </FormControl>
                {formik.touched.type && formik.errors.type ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.type}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={
                    canva?.data?.currency
                      ? convertGBPtoPoundSymbol(canva?.data?.currency)
                      : ""
                  }
                  className={`form-control  ${getInputClasses("amount")}`}
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-5">
                <Form.Label>Reason Code</Form.Label>
                <FormControl fullWidth>
                  <Select
                    name="reason_code"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    IconComponent={(props) => (
                      <i {...props}>
                        <KeyboardArrowDownIcon />
                      </i>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                    displayEmpty
                    className={`form-control w-100 ${getInputClasses(
                      "reason_code"
                    )}`}
                    {...formik.getFieldProps("reason_code")}
                  >
                    <MenuItem value="">Reason Code</MenuItem>
                    {orderDetail &&
                    orderDetail?.reasonCodeList &&
                    orderDetail.reasonCodeList.length &&
                    orderDetail.reasonCodeList.length > 0 ? (
                      orderDetail.reasonCodeList.map((item, index) => (
                        <MenuItem value={item?.id} key={index}>
                          {item?.title}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="no"></MenuItem>
                    )}
                  </Select>
                </FormControl>
                {formik.touched.reason_code && formik.errors.reason_code ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.reason_code}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>

            <div>
              <Form.Group className="mb-5">
                {/* <Form.Label>Reason code</Form.Label> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Add a note (optional)"
                  className={`form-control  ${getInputClasses("notes")}`}
                  name="notes"
                  {...formik.getFieldProps("notes")}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.notes}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right mr-4">
            <button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              Create
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
