import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { shallowEqual, useSelector } from "react-redux";
// import { OrderSummaryDetailTable } from "../Table/OrderSummaryDetailTable";
import CalcumateModal from "../NewOrder/modal/CalcumateModal";
import { EditOrderDetailSummary } from "../order-canva/EditOrderDetailSummary";
import { useIntl } from "react-intl";
import { OrderSubscriptionTable } from "../Table/OrderSubscriptionTable";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import ChangePlanModal from "./EditOrderComponents/Modal/ChangePlanModal";
// import { useHistory } from "react-router-dom";

export function CustomerOrderSummary({
  orderDetail,
  editOrderDetail,
  orderInvoice,
  orderInvoiceLoading,
  scheduleData,
  setScheduleData
}) {
  const intl = useIntl();
  // const history = useHistory();
  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const [dialog, setDialog] = useState(false);
  const [planChangeDialog, setPlanChangeDialog]= useState({open:false})

  useEffect(() => {
    return () => {};
  }, [user]);
  // const value =
  //   (orderInvoice?.first_invoice &&
  //     orderInvoice?.first_invoice.length &&
  //     orderInvoice?.first_invoice[0]?.invoice) ||
  //   "";

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const nextDate = useMemo(() => {
    if (orderDetail && orderDetail.subscription_data) {
      // Create a new Date object with the next billing timestamp
      let date = new Date(orderDetail.subscription_data.next_billing_at * 1000); // Convert timestamp to milliseconds

      switch (orderDetail.subscription_data.billing_period_unit) {
        case 'month':
          date.setMonth(date.getMonth() + orderDetail.subscription_data.billing_period);
          break;
        case 'week':
          date.setDate(date.getDate() + (orderDetail.subscription_data.billing_period * 7));
          break;
        case 'year':
          date.setFullYear(date.getFullYear() + orderDetail.subscription_data.billing_period);
          break;
        default:
          break;
      }
      
      return moment(date).format("DD/MM/YYYY");
    }
    return '';
  }, [orderDetail]);
  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Order.summary" })}
                &nbsp;<span style={{fontSize:"14px"}}>({orderDetail?.order_details?.pod_plan_type || ""})</span>
             
               
              </span>
            </h3>
            <div className="d-flex align-items-center">
              {orderDetail?.is_container !== 1 &&
            <button
              type="button"
              className="btn btn-primary font-weight-bolder font-size-sm "
              onClick={()=>setPlanChangeDialog({open:true})}
            >
              Change Plan
            </button>}&nbsp;
              <span
                className="symbol-label ml-2 pointer"
                // id="kt_EditOrderDetailSummary_panel_toggle"
                onClick={() =>
                  window.open(`/edit-order/${orderDetail?.id}`, "_blank")
                }
              >
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary"
                  // style={{
                  //   display:
                  //     orderDetail?.is_collection_completed === 1 ? "none" : "",
                  // }}
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="card-body pt-0">
          <div className="py-4">
            <OrderSubscriptionTable orderDetail={orderDetail} />
            {/* <OrderSummaryDetailTable
              type={"first_invoice"}
              orderDetail={orderDetail}
              value={value}
              orderInvoice={orderInvoice}
              orderInvoiceLoading={orderInvoiceLoading}
            /> */}
            {scheduleData?.subscription_items &&
              scheduleData?.subscription_items.length > 0 && (
                <div class="mb-2">
                  <span className="font-weight-bolder text-dark">
                    Scheduled Changes on next renewal (
                    {moment(scheduleData?.next_billing_at * 1000).format("DD-MM-YYYY")}
                    )
                  </span>
                </div>
              )}
            <div
            // style={{
            //   backgroundColor: "#e9fffe",
            //   border: "1px solid #c6e7e5",
            // }}
            >
              {scheduleData?.subscription_items &&
              scheduleData?.subscription_items.length > 0
                ? scheduleData?.subscription_items.map((item, index) => {
                    if (
                      orderDetail?.subscription_data?.subscription_items[index]
                        ?.item_type === "plan"
                    ) {
                      return (
                        <div>
                          <span class="ml-2">
                            {scheduleData?.billing_period +
                              " " +
                              scheduleData?.billing_period_unit}
                            {scheduleData?.billing_period > 1 ? "s" : ""}{" "}
                            {
                              orderDetail?.subscription_data
                                ?.subscription_items[index]?.product_name
                            }{" "}
                            from{" "}
                            {
                              orderDetail?.subscription_data
                                ?.subscription_items[index]?.quantity
                            }{" "}
                            - {item?.quantity}
                            {/* {capitalizeFirstLetter(item?.item_type) +
                              " " +
                              item?.item_price_id +
                              " quantity " +
                              item?.quantity}{" "} */}
                          </span>
                        </div>
                      );
                    }
                    return (
                      <div>
                        <span class="ml-2">
                          {capitalizeFirstLetter(item?.item_type) +
                            " " +
                            item?.item_price_id +
                            " quantity " +
                            item?.quantity}{" "}
                        </span>
                      </div>
                    );
                  })
                : ""}
              {/* {scheduleData?.subscription_items &&
              scheduleData?.subscription_items.length > 0 ? (
                <span class="ml-2">
                  This subscription is scheduled for change on next renewal (
                  {moment(scheduleData?.next_billing_at * 1000)
                    .subtract(1, "days")
                    .format("DD-MM-YYYY")}
                  )
                </span>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="card-info-block payment-gateway-sec" style={{ backgroundColor: '#fff', padding: '0px 30px 0px 30px' }}>
      { scheduleData?.next_billing_at && orderDetail?.order_details.pod_plan_type !== orderDetail.downgrade_pod_plan_type && (
        <h5>
          <strong>
            Plan changed from {orderDetail?.order_details.pod_plan_type} to {orderDetail.downgrade_pod_plan_type}. This subscription is scheduled for change on next renewal ({orderDetail.is_plan_upgrade_scheduled === 1 && orderDetail.is_plan_downgrade_scheduled === 1 ? nextDate : moment(scheduleData?.next_billing_at * 1000).format("DD/MM/YYYY")}).
          </strong>
        </h5>
      )}
    </div>
      <CalcumateModal
        dialog={dialog}
        setDialog={setDialog}
        type={"order-detail"}
        orderDetail={orderDetail}
        keyItem={
          orderDetail?.storage_note
            ? String(Object.keys(orderDetail?.storage_note)[0])
            : ""
        }
      />
      <EditOrderDetailSummary
        orderInvoice={orderInvoice}
        orderDetail={orderDetail}
        editOrderDetail={editOrderDetail}
        setDialog={setDialog}
      />
      <ChangePlanModal dialog={planChangeDialog} setDialog={setPlanChangeDialog} orderDetail={orderDetail} setScheduleData={setScheduleData}/>
    </>
  );
}

