import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


export function ReturnReasonModal({show , setShow}) {

  const initialValues ={
    return_reason :"",
    return_reason_description:""
  }


  const handleClose = () => setShow(false);

  const handleSubmit=()=>{

  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reason for Return</Modal.Title>
        </Modal.Header>
        <Modal.Body><span className="mt-3 ">Reason for Return</span>
          <FormControl fullWidth>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              className="form-control"
              IconComponent={(props) => (
                <i {...props}>
                  <KeyboardArrowDownIcon />
                </i>
              )}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              // onChange={(e) => {
              //   setFieldValue("search_view", e.target.value);
              //   handleSubmit();
              // }}
              name="return_reason"
              {...formik.getFieldProps("return_reason")}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="Customer Requested">Customer Requested</MenuItem>
              <MenuItem value="Disposed">Disposed</MenuItem>
              <MenuItem value="Lost to competitor">Lost to competitor</MenuItem>
              <MenuItem value="Price sensitive">Price sensitive</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
              
            </Select>
          </FormControl>
          {formik.touched.return_reason && formik.errors.return_reason ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.return_reason}</div>
            </div>
          ) : null}
          <span className="mt-3 ">&nbsp;</span>
          <input
            type="text"
            name="return_reason_description"
            placeholder={"Quantity"}
            label="Desc"
            className={`form-control  `}
          
            {...formik.getFieldProps("return_reason_description")}
          />
          {formik.touched.return_reason_description && formik.errors.return_reason_description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.return_reason_description}</div>
            </div>
          ) : null}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
