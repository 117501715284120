import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ContactDetails } from "../../../../app/modules/ContactDeals/page/ContactDetails";
import { Deals } from "../../../../app/modules/ContactDeals/page/Deals";
import { fetchNewCustomerCard } from "../../../../app/modules/Order/_redux/order/orderAction";

const ContactDealModal = ({ dialog, cardDetail, setDialog, dispatch }) => {
  const [check, setCheck] = useState(false);
  const [dealData, setDealData] = useState({
    type: "deal",
    dealId: "",
    contactId: "",
  });

  useEffect(() => {
    if (dialog) {
      setTimeout(() => {
        setCheck(true);
      }, 100);
    }
  }, [dialog]);

  const handleClose = () => {
    dispatch(
      fetchNewCustomerCard(
        cardDetail && cardDetail.length && cardDetail[0].id
          ? cardDetail[0].id
          : ""
      )
    );
    setDialog(false);
    setCheck(false);
  };
  return (
    <Modal
      show={dialog}
      onHide={() => handleClose()}
      aria-labelledby="example-modal-sizes-title-xl"
      dialogClassName="my-modal"
      contentClassName="modal-height"
    >
      <div className="mt-2 mr-2" style={{ textAlign: "end" }}>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={() => handleClose()}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </div>

      {check ? (
        <Modal.Body>
          {(cardDetail &&
            cardDetail.length &&
            cardDetail[0].contact &&
            cardDetail[0].contact?.latest_deals) ||
          dealData.dealId !== "" ? (
            <Deals
              ids={
                cardDetail &&
                cardDetail.length &&
                cardDetail[0].contact &&
                cardDetail[0].contact?.id
                  ? cardDetail[0].contact?.latest_deals?.id || dealData.dealId
                  : ""
              }
              contactIds={
                cardDetail &&
                cardDetail.length &&
                cardDetail[0].contact &&
                cardDetail[0].contact?.id
                  ? cardDetail[0].contact?.id || dealData.contactId
                  : ""
              }
              isDeal={"modal"}
            />
          ) : (
            <ContactDetails
              ids={
                cardDetail &&
                cardDetail.length &&
                cardDetail[0].contact &&
                cardDetail[0].contact?.id
                  ? cardDetail[0].contact?.id
                  : ""
              }
              setDealData={setDealData}
              dealData={dealData}
            />
          )}
        </Modal.Body>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ContactDealModal;
