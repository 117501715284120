import React, { useEffect, useRef } from "react";

import { useIntl } from "react-intl";

const CalcumateModal = ({ dialog, setDialog, type, orderDetail, keyItem }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    // script.src =
    //   "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const intl = useIntl();
  const activeSlideRef = useRef(null);

  let modalTimer = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target.id === "exampleModalLive") {
        setDialog(false);
      }
    };

    if (dialog) document.addEventListener("click", checkIfClickedOutside, true);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  function updateBookId() {
    const book = activeSlideRef.current;
    if (
      book
        ?.querySelector("#calcumate-root")
        ?.querySelector('a[data-cy="order-button"]')
    ) {
      let orderButton = book
        .querySelector("#calcumate-root")
        .querySelector('a[data-cy="order-button"]');
      if (!orderButton?.href?.includes("&id")) {
        orderButton.href =
          orderButton.href.replace(
            "order-details?",
            `order-details/${orderDetail?.id}?`
          ) + "&id";
      }
    }
  }

  function updateBookIdChange(event) {
    if (modalTimer) clearTimeout(modalTimer);
    modalTimer = setTimeout(() => {
      updateBookId();
    }, 1000);
  }
  return (
    <>
      <div
        id="exampleModalLive"
        ref={activeSlideRef}
        className={`modal fade ${dialog ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLiveLabel"
        style={{
          display: dialog ? "block" : "none",
          paddingRight: "17px",
          overflowX: "scroll",
          overflowY: "scroll",
        }}
      >
        <div className="modal-dialog customModal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                {intl.formatMessage({ id: "Add/Edit.storage" })}
              </h5>
              <span
                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                onClick={() => setDialog(false)}
              >
                <i className="ki ki-close icon-sm text-muted"></i>
              </span>
            </div>
            <div className="modal-body" onMouseOver={updateBookIdChange}>
              <div
                id="calcumate-root"
                data-integration={
                  process.env.REACT_APP_CALCULATE_DATA_INTEGRATION
                }
                data-integration-2=""
                data-ref={process.env.REACT_APP_CALCULATE_REF}
                data-int={process.env.REACT_APP_CALCULATE_INT}
              ></div>

              {/* <div
                   id="calcumate-root"
                   data-integration="vaultstaging.easystorage.com"
                   data-integration-2=""
                   data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
                   data-int="fc40a500-d54e-47fa-a264-ccf58648d909"
                 ></div> */}
              {/* <div
                  id="calcumate-root"
                  data-integration="vault.easystorage.com"
                  data-integration-2=""
                  data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
                  data-int="6a4f5fe9-c197-4499-a166-8f60fb5cefaa"
                ></div> */}

              {/* <div
                 id="calcumate-root"
                 data-integration="vaultmigration.easystorage.com"
                 data-integration-2=""
                 data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
                 data-int="4ed1b849-5cbc-4776-968d-da28b8ced006"
               ></div>
               <div
                 id="calcumate-root"
                 data-integration="vaultstaging.easystorage.com"
                 data-integration-2=""
                 data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
                 data-int="f6a3a672-f4c3-4061-8566-e545d19e0bfc"
               ></div> */}
              {/* add edit on orderDetail */}
              {/* <div
                 id="calcumate-root"
                 data-integration="vault.easystorage.com"
                 data-integration-2=""
                 data-ref="AQICAHhReAOexSsfBeJ/FMNVdjLFzyMEhuepXqwRfRaqckbrnAESlsoBmb9EsncZ0DZ0abC4AAAAgzCBgAYJKoZIhvcNAQcGoHMwcQIBADBsBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDHSkv4dRtUzuPMaHegIBEIA/OBbz8iH6BRRHFQQ6g1u5XYOHwc2+rQAV8cMIrnx9+91Af4Cnyo9MCQOLVC616BZ/jrs09iJPWAZ86AX117fd"
                 data-int="6a4f5fe9-c197-4499-a166-8f60fb5cefaa"
               ></div> */}

              {type === "order-detail" && (
                <div>
                  <div className="storageEdit mt-2">
                    <div className="storageCardTitle">
                      <span className="font-weight-bolder text-dark">
                        {intl.formatMessage({ id: "Items" })}
                      </span>
                      <span className="font-weight-bolder text-dark">
                        {intl.formatMessage({ id: "Units" })}
                      </span>
                    </div>
                    {orderDetail && orderDetail?.storage_note
                      ? orderDetail?.storage_note[keyItem].map(
                          (item, index) => (
                            <div
                              className="card card-custom card-stretch m-2 mt-4"
                              key={index}
                            >
                              <div className="card-body storageCardBody">
                                <span className="font-weight-bolder text-dark">
                                  {item?.product || "-"}
                                </span>
                                <span className="font-weight-bolder text-dark">
                                  {item?.quantity || "-"}
                                </span>
                              </div>
                            </div>
                          )
                        )
                      : ""}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      {dialog && (
        <div
          className="offcanvas-overlay"
          onClick={() => setDialog(false)}
        ></div>
      )}
    </>
  );
};

export default CalcumateModal;
