/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";

export function SiteCard({ state ,setDialogType , dialogType , setDialogSite}) {
  return (
    <>
      <div className="card card-custom gutter-b newordnoteCon  ">
        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div className="mr-3 mb-5">
                      <h4 className="card-title align-items-start flex-column mb-0">
                        <span className="font-weight-bolder text-dark">
                          Site 
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-icon">
            <Button variant="primary" onClick={()=>setDialogType({ open:true})} disabled={state.containerTypeSelect == "container"}>Edit Type</Button>&nbsp;
            <Button variant="primary"   onClick={()=>setDialogSite({open:true})}>Edit Site</Button>
              {/* <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary "
                  role="button"
                  onClick={()=>setDialogSite({open:true})}
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  ></SVG>
                </span>
              </span> */}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center"></div>
          {state?.siteSelect?.name &&
          <div className="row">
                    <div className="col-4">
                      Name:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {state?.siteSelect?.name}
                      </span>
                    </div>
                    <div className="col-4">
                      Franchise:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {state?.siteSelect?.franchise?.name}
                      </span>
                    </div>
                    <div className="col-4">Distance:&nbsp;{state?.siteSelect?.distance} Km</div>
                    <div className="col-6">
                      Available&nbsp;From:&nbsp;{state?.siteSelect?.available_from}
                    </div>
                    <div className="col-12">Address:&nbsp;{state?.siteSelect?.address}</div>
                    {/* <div className="col-12"> <Button variant="secondary" onClick={() => handleRadioChange(e)}>{state?.siteSelect?.id === e.id?"Selected":"select"}Select</Button></div> */}
                  </div>
}
        </div>
      </div>
    </>
  );
}
