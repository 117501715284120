import { Card, CardBody } from "../../../_metronic/_partials/controls";
import React, { useEffect, useState } from "react";

import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { initIds } from "../../../_metronic/_partials/controls/RightPanel";
// import { useLocation } from "react-router-dom";
// import queryString from "query-string";
import { calendarTest } from "../../pages/redux/commonCrud";
import { CalendarDetail } from "./calendar-component/CalendarDetail";
import { TravelBlockingCanva } from "./calendar-canva/TravelBlockingCanva";
import moment from "moment";
import { containerCalendar, operationCalendar } from "./redux/calendarCrud";
import { useIntl } from "react-intl";
import { LoadingCustome } from "../../../_metronic/_partials/controls/LoadingCustome";
import { useSelector, shallowEqual } from "react-redux";
import { Tab, Tabs } from 'react-bootstrap';

const CalendarPage = (props) => {
  const intl = useIntl();
  const { user, admin } = useSelector((state) => ({ user: state.auth, admin: state.auth.user, }), shallowEqual);

  const init = ["kt_collection_calendar_panel"];
  const [canva, setCanva] = useState({ type: false, title: "" });
  const [calendarData, setCalendarData] = useState();
  const [franchiseList, setFranchiseList] = useState([]);
  const [searchBooking, setSearchBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [formData, setFormData] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
  });
  const [containerData, setContainerData] = useState(null)
  const [actionButton, setActionButton] = useState({
    type: "booking",
    check: "",
  });
  const [key, setKey] = useState('home');
  useEffect(() => {
    if (admin?.franchise_id) {
      setFormData({ ...formData, franchise: admin?.franchise_id });
      // setToggleCalendar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin?.franchise_id]);

  useEffect(() => {
    initIds(init);
  }, [init]);

  const handleApi = (test) => {
    console.log("containerData?.startOfWeek", containerData?.startOfWeek);
    setIsLoading(true);
    console.log("containerData?.startOfWeek-1");
    if (key === "profile") {
      console.log("containerData?.startOfWeek-2");
      const data = {
        id: formData?.franchise || "",
        start_date: containerData?.startOfWeek || "",
        end_date: containerData?.endOfWeek || ""

      }
      console.log("containerData?.startOfWeek-3");
      containerCalendar(data).then((res) => {
        setCalendarData(res.data);
        console.log("containerData?.startOfWeek-4");
        setIsLoading(false);
      }).catch((error) => {
        console.log("containerData?.startOfWeek-5");
        setIsLoading(false);
      });
      return
    }
    console.log("containerData?.startOfWeek-6");
    let query = {
      id: formData?.franchise,
      date: formData?.date || "",
      pod: 0,
      is_calendar: 1
    };
    console.log("containerData?.startOfWeek-7");
    if (actionButton?.type === "booking") {
      console.log("containerData?.startOfWeek-8", actionButton);
      calendarTest(query)
        .then((res) => {
          console.log("containerData?.startOfWeek-9", test, res);
          setCalendarData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      operationCalendar(query)
        .then((res) => {
          setCalendarData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      {isLoading && <LoadingCustome />}
      <NewBreadCrumbs title="Calendar" breadcrumb={true} />
      <Card>
        <CardBody>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            {((admin?.roles &&
              admin.roles?.length &&
              admin.roles?.length > 0 &&
              admin.roles[0] === "super-admin") || (admin?.roles &&
                admin.roles?.length &&
                admin.roles?.length > 0 &&
                admin.roles[0] === "super-agent") || (admin?.roles &&
                  admin.roles?.length &&
                  admin.roles?.length > 0 &&
                  admin.roles[0] !== "super-admin" && user?.user?.franchise_plan_types?.some((e) => e === "pod"))) &&
              <Tab eventKey="home" title="Pod">

                <div>
                  <div>
                    <h4 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder text-dark">
                        {intl.formatMessage({ id: "Franchise" })}:{" "}
                        {franchiseList.find((e) => e.id === formData?.franchise)
                          ?.name || "-"}
                      </span>
                    </h4>
                  </div>
                </div>
                <CalendarDetail
                  setCanva={setCanva}
                  setFormData={setFormData}
                  formData={formData}
                  setActionButton={setActionButton}
                  actionButton={actionButton}
                  setCalendarData={setCalendarData}
                  calendarData={calendarData}
                  handleApi={handleApi}
                  setFranchiseList={setFranchiseList}
                  franchiseList={franchiseList}
                  setSearchBooking={setSearchBooking}
                  searchBooking={searchBooking}
                  setToggleCalendar={setToggleCalendar}
                  toggleCalendar={toggleCalendar}
                  keyC={key}
                  containerData={containerData}
                />
                {canva.type && (
                  <>
                    <TravelBlockingCanva
                      setCanva={setCanva}
                      canva={canva}
                      formData={formData}
                      handleApi={handleApi}
                    />
                    <div
                      className="offcanvas-overlay"
                      onClick={() => setCanva({ type: false, title: "" })}
                    ></div>
                  </>
                )}
              </Tab>
            }
            {((admin?.roles &&
              admin.roles?.length &&
              admin.roles?.length > 0 &&
              admin.roles[0] === "super-admin") || (admin?.roles &&
                admin.roles?.length &&
                admin.roles?.length > 0 &&
                admin.roles[0] === "super-agent") || (admin?.roles &&
                  admin.roles?.length &&
                  admin.roles?.length > 0 &&
                  admin.roles[0] !== "super-admin" && user?.user?.franchise_plan_types?.some((e) => e === "container"))) &&
              <Tab eventKey="profile" title="Container" >
                <div>
                  <div>
                    <h4 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder text-dark">
                        {intl.formatMessage({ id: "Franchise" })}:{" "}
                        {franchiseList.find((e) => e.id === formData?.franchise)
                          ?.name || "-"}
                      </span>
                    </h4>
                  </div>
                </div>
                <CalendarDetail
                  setCanva={setCanva}
                  setFormData={setFormData}
                  formData={formData}
                  setActionButton={setActionButton}
                  actionButton={actionButton}
                  setCalendarData={setCalendarData}
                  calendarData={calendarData}
                  handleApi={handleApi}
                  setFranchiseList={setFranchiseList}
                  franchiseList={franchiseList}
                  setSearchBooking={setSearchBooking}
                  searchBooking={searchBooking}
                  setToggleCalendar={setToggleCalendar}
                  toggleCalendar={toggleCalendar}
                  keyCon={key === "profile" ? "container" : ""}
                  setContainerData={setContainerData}
                  keyC={key}
                  containerData={containerData}

                />
              </Tab>
            }
          </Tabs>
        </CardBody>
      </Card>
    </>
  );
};

export default CalendarPage;
