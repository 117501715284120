/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import ActiveDialogCharge from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogCharge";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  convertGBPtoPoundSymbol,
  getInputClasses,
} from "../../../../../utils/commonFunction";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchOrderDropDown } from "../../../../Order/_redux/order/orderAction";

export function NewChargesCanvas({ formik, detailData, type }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDropDown,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (formik?.values?.name && type !== "edit") {
      let data = formik?.values?.name.replace(/ /g, "-");
      formik.setFieldValue("id", data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name]);

  const handleSet = () => {
    if (formik.values.price === "") {
      formik.setFieldValue("price", 0);
    }
  };
  useEffect(() => {
    dispatch(fetchOrderDropDown());
  }, [dispatch]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <label>{intl.formatMessage({ id: "External.Name" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="external_name"
              placeholder={intl.formatMessage({ id: "External.Name" })}
              label="External Name"
              className={`form-control  ${getInputClasses("external_name")}`}
              {...formik.getFieldProps("external_name")}
            />
            {formik.touched.external_name && formik.errors.external_name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.external_name}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Internal.Name" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: "Internal.Name" })}
              label="Internal Name"
              className={`form-control  ${getInputClasses("name")}`}
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-12">
          <label>{intl.formatMessage({ id: "Description" })}</label>
          <div className="form-groupB">
            <textarea
              type="textarea"
              name="description"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Description" })}
              label="Description"
              rows="4"
              {...formik.getFieldProps("description")}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Charge.Id" })}</label>
          <div className="form-groupB">
            <input
              type="text"
              name="id"
              className={`form-control  ${getInputClasses("id")}`}
              {...formik.getFieldProps("id")}
              placeholder={intl.formatMessage({ id: "Charge.Id" })}
              label="Charge Id"
              disabled={type === "edit"}
            />
            {formik.touched.id && formik.errors.id ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.id}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="enabled_in_portal"
              checked={
                formik.values.enabled_in_portal === "true" ||
                formik.values.enabled_in_portal === true
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "enabled_in_portal",
                  String(e.target.checked)
                )
              }
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Display.Portal" })}
          </label>
        </div>
        {type === "edit" ? (
          ""
        ) : (
          <div className="col-12 col-sm-8">
            <div className="form-groupB">
              <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses("country")}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="country"
                  {...formik.getFieldProps("country")}
                  disabled={formik?.values?.profile_id === 1 ? true : false}
                >
                  <MenuItem value="">
                    {intl.formatMessage({ id: "COUNTRY" })}
                  </MenuItem>
                  {detailData && detailData?.length ? (
                    detailData.map((data, index) => (
                      <MenuItem value={data.id} key={index}>
                        {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span style={{ color: "lightgrey" }}>
                          {data.timezone}
                        </span>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="q"></MenuItem>
                  )}
                </Select>
              </FormControl>
              {formik.touched.country && formik.errors.country ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.country}</div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        <div className="col-4"></div>

        <div className="col-md-12 mt-2">
          <div>
            <h5>{intl.formatMessage({ id: "Pricing" })}</h5>
          </div>
        </div>

        <div className="col-12 col-sm-8">
          <div className="form-groupB">
            <label>{intl.formatMessage({ id: "Pricing.Modal" })}</label>
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="form-control"
                IconComponent={(props) => (
                  <i {...props}>
                    <KeyboardArrowDownIcon />
                  </i>
                )}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                name="pricing_model"
                {...formik.getFieldProps("pricing_model")}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "Pricing.Modal" })}
                </MenuItem>
                <MenuItem value="flat_fee">
                  {intl.formatMessage({ id: "Flat.Free" })}
                </MenuItem>
                <MenuItem value="per_unit">
                  {intl.formatMessage({ id: "Per.Unit" })}
                </MenuItem>
              </Select>
            </FormControl>
            {formik.touched.pricing_model && formik.errors.pricing_model ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.pricing_model}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-2">
          <label>£</label>
          <div className="form-groupB">
            <input
              type="text"
              name="currency_code"
              placeholder={"£"}
              className={`form-control  ${getInputClasses("currency_code")}`}
              value={
                formik?.values?.currency_code
                  ? convertGBPtoPoundSymbol(formik?.values?.currency_code)
                  : ""
              }
              onChange={(e) =>
                formik.setFieldValue("currency_code", e.target.value)
              }
              // {...formik.getFieldProps("currency_code")}
              disabled
            />
            {formik.touched.currency_code && formik.errors.currency_code ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.currency_code}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-8">
          <label>Price</label>
          <div className="form-groupB">
            <input
              type="text"
              name="price"
              className={`form-control  ${getInputClasses("price")}`}
              {...formik.getFieldProps("price")}
              placeholder="£ 00.00"
              label="Price"
              onBlur={handleSet}
            />
            {formik.touched.price && formik.errors.price ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.price}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Description" })}</label>
          <div className="form-groupB">
            <textarea
              type="textarea"
              name="price_description"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Description" })}
              label="Description"
              rows="4"
              {...formik.getFieldProps("price_description")}
            />
            {formik.touched.price_description &&
            formik.errors.price_description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.price_description}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="show_description_in_invoices"
              checked={
                formik.values.show_description_in_invoices === "true" ||
                formik.values.show_description_in_invoices === true
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "show_description_in_invoices",
                  String(e.target.checked)
                )
              }
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Show.Invoices" })}
          </label>
        </div>
        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="show_description_in_quotes"
              checked={
                formik.values.show_description_in_quotes === "true" ||
                formik.values.show_description_in_quotes === true
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "show_description_in_quotes",
                  String(e.target.checked)
                )
              }
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "Show.Quotes" })}
          </label>
        </div>
        <div className="col-12">
          <label>{intl.formatMessage({ id: "Invoice.Notes" })}</label>
          <div className="form-groupB">
            <textarea
              type="textarea"
              name="invoice_notes"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Description" })}
              label="Description"
              rows="4"
              {...formik.getFieldProps("invoice_notes")}
            />
            {formik.touched.invoice_notes && formik.errors.invoice_notes ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.invoice_notes}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="is_taxable"
              checked={
                formik.values.is_taxable === "true" ||
                formik.values.is_taxable === true
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue("is_taxable", String(e.target.checked))
              }
            />
            <span className="mr-2"></span>
            {intl.formatMessage({ id: "This.taxes" })}
          </label>
        </div>
        <div className="col-md-12 mb-5">
          <label className="checkbox">
            <input
              type="checkbox"
              name="is_packaging_material"
              checked={
                formik.values.is_packaging_material === "1" ||
                formik.values.is_packaging_material === 1
                  ? true
                  : false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  "is_packaging_material",
                  String(e.target.checked ? 1 : 0)
                )
              }
            />
            <span className="mr-2"></span>
            This is packaging material
          </label>
        </div>

        {(formik.values.is_packaging_material === "1" ||
          formik.values.is_packaging_material === 1) && (
          <div className="col-12 col-sm-8">
            <div className="form-groupB">
              <label>Category</label>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  className={`form-control  ${getInputClasses(
                    "category_type"
                  )}`}
                  IconComponent={(props) => (
                    <i {...props}>
                      <KeyboardArrowDownIcon />
                    </i>
                  )}
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty
                  name="category_type"
                  {...formik.getFieldProps("category_type")}
                  disabled={formik?.values?.profile_id === 1 ? true : false}
                >
                  <MenuItem value="">Category</MenuItem>
                  {orderDetail?.categoryList &&
                  orderDetail?.categoryList?.length ? (
                    orderDetail?.categoryList.map((data, index) => (
                      <MenuItem value={data.title} key={index}>
                        {data.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="q"></MenuItem>
                  )}
                </Select>
              </FormControl>
              {formik.touched.category_type && formik.errors.category_type ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.category_type}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
        {type === "edit" && (
          <div className="col-6">
            <div className="form-groupB row">
              <label className="col-5 col-form-label ">
                {intl.formatMessage({ id: "ACTIVE" })}
              </label>
              <div className="col-7 text-right mt-1">
                <span className="switch switch-outline switch-icon switch-primary">
                  <label>
                    <input
                      type="checkbox"
                      checked={formik.values.status === "active" ? true : false}
                      onChange={() => setDialog(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <ActiveDialogCharge
        setDialog={setDialog}
        dialog={dialog}
        formik={formik}
      />
    </>
  );
}
