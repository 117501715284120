/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getPagesCount } from "../../../_helpers";

export function CustomePaginationLinks({
  paginationProps,
  customePagination,
  loading,
}) {
  const { totalSize, sizePerPage, page } = paginationProps;
  const pagesCount = getPagesCount(totalSize, sizePerPage);
  // const pages = getPages(page, pagesCount, paginationSize);

  const handlePrevPage = ({ page, onPageChange, ids, setIds }) => {
    onPageChange(page - 1);
    if (customePagination) {
      const arr = [...ids];
      arr.splice(page - 1, 1);

      setIds(arr);
    }
  };

  const handleNextPage = ({ page, onPageChange, offset, setIds, ids }) => {
    // if (page < pagesCount) {
    onPageChange(page + 1);
    if (customePagination) {
      const arr = [...ids];
      arr.push(offset);
      setIds(arr);
    }
    // }
  };

  const disabledClass = pagesCount > 1 ? "" : "disabled";
  return (
    <>
      {pagesCount < 2 && <></>}

      <div className={`d-flex flex-wrap py-2 mr-3 ${disabledClass}`}>
        <button
          onClick={() => handlePrevPage(paginationProps)}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={(page === 1 && customePagination) || loading ? true : false}
        >
          <i className="ki ki-bold-arrow-back icon-xs" />
        </button>
        {customePagination ? (
          <button
            className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
            disabled
          >
            {page}
          </button>
        ) : (
          ""
        )}

        <button
          onClick={() => handleNextPage(paginationProps)}
          className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
          disabled={
            (paginationProps?.offset === null && customePagination) || loading
              ? true
              : false
          }
        >
          <i className="ki ki-bold-arrow-next icon-xs"></i>
        </button>
      </div>
    </>
  );
}
