/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
export function OrderNotes({
  state,
  dispatchReducer,
  cardDetail,
  title,
  mainText,
  type,
  location

}) {
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    notes: location?.search.includes("quote") ? mainText : state && state.driver_note ? state.driver_note : "",
  };
  const initialNotes = {
    notes: mainText ? mainText : "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    if (values.notes !== "") {
      dispatchReducer({ type: "driverNote", value: formik.values.notes });
    } else {
      setSnack({
        call: true,
        type: "error",
        message: "Driver Note is empty",
      });
    }
  };
  const handleReset = () => {};
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: type === "quote" ? initialNotes : initialValues,
    enableReinitialize: true,
    // validationSchema: NotesSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  return (
    <>
      <div className="card card-custom gutter-b newordnote  ">
        <div className="card-body ">
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div className="mr-3 mb-5">
                      <h4 className="card-title align-items-start flex-column mb-0">
                        <span className="font-weight-bolder text-dark">
                          {title || ""}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center"></div>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    as="textarea"
                    rows="5"
                    className={`form-control  ${getInputClasses("notes")}`}
                    name="notes"
                    {...formik.getFieldProps("notes")}
                    onBlur={handleSubmit}
                    disabled={type === "quote" ? true : false}
                  />
                  {formik.touched.notes && formik.errors.notes ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.notes}</div>
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <div className="col-md-12 text-right"></div>
            </div>
          </form>
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
