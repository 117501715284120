import React from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

const ActiveDialogCharge = ({ dialog, setDialog, formik }) => {
  const intl = useIntl();
  const handleChange = () => {
    formik.setFieldValue(
      "status",
      formik.values.status === "active" ? "archived" : "active"
    );
    setDialog(false);
  };
  return (
    <Modal show={dialog} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {formik.values.status === "active"
            ? "Archive Product"
            : "Activate Product"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          {intl.formatMessage({ id: "Are.you.sure" })}{" "}
          {formik.values.status === "active"
            ? intl.formatMessage({ id: "Archive" }) + " this Product"
            : intl.formatMessage({ id: "activate" }) + " this Product"}
          ?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setDialog(false)}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "No" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={handleChange}
            className="btn btn-delete btn-elevate"
          >
            {intl.formatMessage({ id: "Yes" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveDialogCharge;
