/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import { Button, InputGroup } from "react-bootstrap";
import React, { memo, useEffect, useState } from "react";
import {
  deleteFranchisePostCode,
  deleteFranchiseSector,
  editFranchiseSectorPostCode,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesPostCode,
  fetchFranchisesSectorPostCode,
} from "../../../_redux/franchises/franchisesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";
import { Form } from "react-bootstrap";
import { FranchiseSectorPostCodeSchema } from "../franchise-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

const EditSector = ({ type, data, setSectorDa }) => {
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [error, setError] = useState({ type: false, search: "" });
  const [check, setCheck] = useState(false);
  const [dialog, setDialog] = useState({ modal: false });
  const [dialogSector, setDialogSector] = useState({ modal: false });

  const dispatch = useDispatch();
  const { postcodeList, postcodeTotal } = useSelector(
    (state) => ({
      postcodeList: state.franchise.postcode,
      postcodeTotal: state.franchise.poscodeTotal,
    }),
    shallowEqual
  );

  const initialValues = {
    franchise_id: data && data.franchise_id ? data.franchise_id : "",
    name: data && data.name ? data.name : "",
    status: data && data.status ? data.status : "0",
    postcode: [],
    price: data?.price || "",
  };

  const handleSubmit = (values, actions) => {
    setSnack({ call: false, type: "", message: "" });
    editFranchiseSectorPostCode(`${data.id}?_method=PATCH`, values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchisesSectorPostCode(formik.values.franchise_id));
        setTimeout(() => {
          canvaCancel("kt_editsector_panel_close");
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const handleDelete = (e) => {
    setSnack({ call: false, type: "", message: "" });
    deleteFranchiseSector(dialogSector.data.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchisesSectorPostCode(formik.values.franchise_id, ""));
        setDialogSector({ modal: false });
        setTimeout(() => {
          setSectorDa({ type: "" });
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: "Error",
        });
        setDialogSector({ modal: false });
      });
    setDialogSector({ modal: false });
  };

  const handleDeletePostCode = () => {
    setSnack({ call: false, type: "", message: "" });
    deleteFranchisePostCode(dialog.data.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchisesPostCode(data?.franchise_id, ""));
        setDialog({ modal: false });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setDialog({ modal: false });
      });
    setDialog({ modal: false });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: FranchiseSectorPostCodeSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  useEffect(() => {
    if (data && !check) {
      const array = [...formik.values.postcode];
      data.postcode_data.forEach((item, index) => {
        array.push({ postcode: item?.sector_postcode?.postcode });
      });
      formik.setFieldValue("postcode", array);
      setCheck(true);
    }
  }, [check, data, formik]);

  useEffect(() => {
    dispatch(fetchFranchisesPostCode(data?.franchise_id, search));
  }, [data, dispatch, search]);
  const handleAdd = (name) => {
    let arr = [...formik.values.postcode];
    if (arr.length) {
      let newArr = [];
      let isExists = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].postcode !== name) {
          newArr.push(arr[i]);
        } else {
          isExists = true;
        }
      }
      if (!isExists) newArr.push({ postcode: name });
      arr = newArr;
    } else {
      arr.push({ postcode: name });
    }

    formik.setFieldValue("postcode", arr);
  };

  useEffect(() => {
    if (search !== "" && postcodeList && postcodeList.length < 1) {
      setError({ type: true, search: "Postcode not found" });
    } else {
      setError({ type: false, search: "" });
    }
  }, [postcodeList, search]);

  let arr = [];

  const newArr =
    postcodeList &&
    postcodeList.filter((data) => {
      if (
        formik?.values?.postcode?.find(
          (element) => element.postcode === data.postcode
        )
      ) {
        arr.push(data);
        return false;
      }
      return true;
    });

  const list = [...arr, ...newArr];

  const handleClose = () => {
    setSectorDa({ type: "" });
    formik.resetForm();
  };

  return (
    <div
      id={`kt_editsector_panel`}
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_editsector_panel_close"
            onClick={() => handleClose()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Edit.Sector" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_editsector_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          >
            <div className="mt-10 pb-10">
              <div className="pr-5">
                {" "}
                <div className="row">
                  <div className="col-md-4 ">
                    <Form.Group className="mb-0">
                      <InputGroup className="search-input">
                        <Form.Control
                          type="text"
                          placeholder={intl.formatMessage({ id: "Search" })}
                          aria-describedby="inputGroupPrepend"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <i className="fa fa-search "></i>
                      </InputGroup>
                      {error.type ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{error.search}</div>
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Sector.Name" })}
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      type="text"
                      placeholder={"Price"}
                      name="price"
                      {...formik.getFieldProps("price")}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.price}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className="mt-10 scroll scroll-pull "
                id="kt_quick_panel_notifications_kt_addsector_panel"
                style={{ width: "90%" }}
              >
                <div className="postcodelist">
                  {list &&
                    list.map((data, index) => {
                      return (
                        <div key={index}>
                          <Button
                            variant={
                              formik.values.postcode.find(
                                (element) => element.postcode === data.postcode
                              )
                                ? ""
                                : "outline-secondary"
                            }
                            className={
                              formik.values.postcode.find(
                                (element) => element.postcode === data.postcode
                              )
                                ? "btnClick w-100"
                                : "w-100 "
                            }
                            onClick={() => handleAdd(data.postcode)}
                          >
                            {data.postcode}&nbsp;&nbsp;&nbsp;
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="mt-5">
                {intl.formatMessage({ id: "Total.Postcode" })} :{" "}
                {postcodeTotal ? postcodeTotal : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_editsector_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm mr-3 "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
            <button
              type="button"
              className="btn btn-light font-weight-bolder font-size-sm "
              onClick={() =>
                setDialogSector({
                  modal: true,
                  data: {
                    id: data.id,
                  },
                })
              }
            >
              {intl.formatMessage({ id: "Delete" })}
            </button>
          </div>
        </div>
      </form>
      <DeleteDialogApi
        dialog={dialogSector}
        setDialog={setDialogSector}
        // status={data.status}
        title={"Sector"}
        handleChange={handleDelete}
      />
      <DeleteDialogApi
        dialog={dialog}
        setDialog={setDialog}
        // status={data.status}
        title={"Postcode"}
        handleChange={handleDeletePostCode}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};

export default memo(EditSector);
