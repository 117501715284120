export const ProductStatusCssClasses = ["success", "danger", ""];
export const ProductStatusTitles = ["Active", "Inactive"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: {
    contactName: "",
    search: "",
    search_by: "",
    nextOffset: [],
    search_value: "",
  },
  sortOrder: "", // asc||desc
  sortField: "",
  pageNumber: 1,
  pageSize: 10,
};

export const initialValues = {
  code: "",
  description: "",
  offer_type: "",
  value: "",
  valid_from: "",
  valid_to: "",
  status: "",
  is_all_franchises: "",
};

export const newPromoInitialValues = {
  offer_code: "",
  offer_description: "",
  offer_type: "",
  currency_code: "",
  value: "",
  apply_on: "",
  duration_type: "",
  duration: "",
  duration_length: "",
  all_franchise: "",
  valid_till: "",
  is_all_franchises: "",
  country: "",
  franchise_charbee_key: [],
  promotion_id: "",
};

export const editPromoValues = (editPromoData) => {
  return {
    offer_code: editPromoData && editPromoData.name ? editPromoData.name : "",
    offer_description:
      editPromoData && editPromoData.invoice_name
        ? editPromoData.invoice_name
        : "",
    offer_type:
      editPromoData && editPromoData.discount_type
        ? editPromoData.discount_type
        : "",
    currency_code:
      editPromoData && editPromoData.currency_code
        ? editPromoData.currency_code
        : " ",
    value:
      editPromoData && editPromoData.discount_percentage
        ? editPromoData.discount_percentage
        : editPromoData && editPromoData.discount_amount
        ? editPromoData.discount_amount
        : "",
    apply_on:
      editPromoData && editPromoData.apply_on ? editPromoData.apply_on : "",
    duration_type:
      editPromoData && editPromoData.duration_type
        ? editPromoData.duration_type
        : "",
    duration:
      editPromoData && editPromoData.period ? editPromoData.period : " ",
    duration_length:
      editPromoData && editPromoData.period_unit
        ? editPromoData.period_unit
        : " ",
    valid_till:
      editPromoData && editPromoData.valid_till ? editPromoData.valid_till : "",

    country:
      editPromoData &&
      editPromoData.meta_data &&
      editPromoData.meta_data.country
        ? editPromoData.meta_data.country
        : "",
    franchise_charbee_key:
      editPromoData &&
      editPromoData.meta_data &&
      editPromoData.meta_data.franchise
        ? editPromoData.meta_data.franchise
        : [],
    promotion_id: editPromoData && editPromoData.id ? editPromoData.id : "",
  };
};
