import axios from "axios";

export const PRODUCTS_URL = "product";
export const PRICING = "/update-price";
export const FRANCHISE_LIST = "/franchise-sector/list?";
export const LIST_UPDATE = "/product-link-franchise";
export const DELETE = "product/delete/product";

//product list
export function findProduct(queryParams, nextoffset) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + PRODUCTS_URL}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search
    }&search_by=${queryParams.filter.search_by}&search_type=${
      queryParams.filter.search_type
    }&search_status=${queryParams.filter.search_status}${
      queryParams.filter.search_value !== ""
        ? `&search_value=${queryParams.filter.search_value}`
        : ""
    }
    ${
      nextoffset && nextoffset.length > 0 && queryParams.pageNumber !== 1
        ? `&offset=${JSON.stringify(nextoffset[queryParams.pageNumber - 2])}`
        : ""
    }
    `
  );
}
//create product api
export function createProduct(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PRODUCTS_URL, data);
}

//update product api
export function updateProduct(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PRODUCTS_URL + `/${id}`,
    data
  );
}

//get product detail
export function getProductDetail(id) {
  return axios.get(process.env.REACT_APP_SITE_URL + PRODUCTS_URL + `/${id}`);
}

//update pricing of product api
export function updatePricing(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PRODUCTS_URL + PRICING + `/${id}`,
    data
  );
}

//get franchise list api
export function getfranchiseList(id) {
  return axios.get(
    process.env.REACT_APP_SITE_URL +
      PRODUCTS_URL +
      FRANCHISE_LIST +
      `country_id=${id}`
  );
}

//franchise update api
export function updateFranchise(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PRODUCTS_URL + LIST_UPDATE + `/${id}`,
    data
  );
}

//delete product api
export function deleteProduct(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE, data);
}
