/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import React, { useCallback, useEffect, useState } from "react";
import {
  addCards,
  autoCollect,
  changeType,
  deleteCard,
  getPaymentIntent,
  primaryCard,
} from "../_redux/order/orderCrud";
import {
  fetchBillingCardDetail,
  fetchCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";

import { Button } from "react-bootstrap";
import CustomeDialog from "../../../../_metronic/_partials/widgets/alert/CustomeDialog";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { OrderCardSchema } from "../order-form/validationSchema";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import { getAddress } from "../../ContactDeals/_redux/contact/contactCrud";
import { months } from "../_mocks/dummyData";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import valid from "card-validator";
import { FormGroup, Switch, FormControlLabel } from '@material-ui/core';

export function OrderCardDetailEdit({
  cardDetailArray,
  orderDetail,
  setCardCanva,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialValues = {
    cardNumber: "",
    expires: "",
    expiresYear: "",
    cvv: "",
    nameCard: "",
    address: "",
    city: "",
    postCode: "",
  };

  const [addNewCheck, setAddNewCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enterManual, setEnterManual] = useState(false);
  const [payment, setPayment] = useState({});
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [list, setList] = useState({ addressList: [] });
  const [dialogDis, setDialogDis] = useState(false);
  const [dialog, setDialog] = useState({ type: false, check: "", id: "" });

  const handleReset = () => { };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const addCard = (value) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      customer_chargebee_id: orderDetail?.customer_chargebee_id || "",
      // gw_token: value?.active_payment_attempt?.id_at_gateway || "",
      gw_token: value?.id || "",

      order_id: orderDetail?.id || "",
    };
    addCards(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(
          fetchCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.customer_chargebee_id,
            orderDetail?.subscription_id
          )
        );
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_id,
            "",
            orderDetail?.customer_chargebee_id,
            orderDetail?.id
          )
        );
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        setAddNewCheck(false);
        if (orderDetail?.payment_method !== "card") {

          autoCollect({ order_id: orderDetail?.id, auto_collection: state.checkedA ? "On" : "Off" })
            .then((res) => {
              changeType({ order_id: orderDetail?.id, payment_method: "card" }).then(() => {
                setTimeout(() => {
                  formik.resetForm();
                }, 2000);
                setLoading(false);
                dispatch(
                  fetchBillingCardDetail(
                    orderDetail?.franchise_id,
                    orderDetail?.subscription_id,
                    "",
                    orderDetail?.customer_chargebee_id,
                    orderDetail?.id
                  )
                );
                dispatch(fetchMyOrderDetailCard(orderDetail?.id));
              })


            })
            .catch((error) => {
              setSnack({
                call: true,
                type: "error",
                message: error?.response?.data?.message || "",
              });
            });
        } else {

          setTimeout(() => {
            formik.resetForm();
          }, 2000);
          setLoading(false);
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
        setLoading(false);
      });
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let cbInstance = window.Chargebee.getInstance();
    cbInstance.load3DSHandler().then((threeDSHandler) => {
      const intent = {
        id: payment?.data?.id || "",
        expires_at: Number(payment?.data?.expires_at) || "",
        amount: 1, //Number(payment?.data?.amount) || 0
        created_at: Number(payment?.data?.created_at) || "",
        currency_code: payment?.data?.currency_code || "",
        gateway: payment?.data?.gateway || "",
        gateway_account_id: payment?.data?.gateway_account_id || "",
        modified_at: Number(payment?.data?.modified_at) || "",
        object: payment?.data?.object || "",
        payment_method_type: payment?.data?.payment?.data_method_type || "",
        status: payment?.data?.status || "",
        customerId: payment?.data?.customer_id || "",
      };
      // Set the created paymentIntent
      threeDSHandler.setPaymentIntent(intent);
      threeDSHandler.handleCardPayment(
        {
          card: {
            firstName: values.nameCard,

            number: values.cardNumber.replace(/ /g, ""),
            cvv: values.cvv,
            expiryMonth: values.expires,
            expiryYear: values.expiresYear,
          },
          additionalData: {
            billingAddress: {
              firstName: orderDetail?.customer_details?.first_name || "",
              lastName: orderDetail?.customer_details?.last_name || "",

              phone: orderDetail?.customer_details?.phone || "",
              addressLine1: values?.address || "",
              city: values?.city || "",
              zip: values?.postCode || "",
            },
          },
        },
        {
          change: function (intent) {
            // Triggers on each step transition
          },
          success: function (intent) {
            addCard(intent);
          },
          error: function (intent, error) {
            setLoading(false);
            setSnack({
              call: true,
              type: "error",
              message: error.message,
            });
            // Triggers when 3DS authorization fails
          },
        }
      );
      // The paymentIntent can be updated whenever the amount gets changed
      // threeDSHandler.updatePaymentIntent(paymentIntent);
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: OrderCardSchema(valid),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handlePaymentIntent = (value, stValue) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      first_name: value?.customer_details?.first_name || "",
      last_name: value?.customer_details?.last_name || "",
      email: value?.customer_details?.email || "",
      address: value?.customer_details?.address || "",
      city: value?.customer_details?.city || "",
      state: "",
      zip: value?.customer_details?.postcode || "",
      country: value?.currency ? value?.currency.substring(0, 2) : "",
      amount: 1, //value?.amount ||
      currency_code: value?.currency || "",
      franchise_id: value?.franchise_id || "",
    };
    getPaymentIntent(data)
      .then((res) => {
        setPayment(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleIntent = useCallback(() => {
    handlePaymentIntent(orderDetail);
  }, [orderDetail]);
  useEffect(() => {
    if (orderDetail?.customer_details) {
      try {
        window.Chargebee.init({
          site: orderDetail?.franchise_details?.chargebee_key || "",
          publishableKey: orderDetail?.franchise_details?.stripe_pk || "",
        });
        // setCbI({ cbInstance: window.Chargebee.getInstance() });
      } catch (error) { }
    }
  }, [orderDetail]);

  const handleGetAddress = (postcode) => {
    setEnterManual(false);
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    getAddress(postcode)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: "Address got successfully",
        });
        setList({ ...list, addressList: res.data.addresses });
        formik.setFieldValue("city", res.data.addresses[0].town_or_city);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: "PostCode not found",
        });
      });
  };

  const handleAction = (type, source, sub) => {
    setLoading(true);
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    if (type === "primary") {
      const data = {
        franchise_id: orderDetail?.franchise_id || "",
        customer_chargebee_id: orderDetail?.customer_chargebee_id || "",
        payment_source_id: source,
        order_id: orderDetail?.id || "",
        role: "primary",
      };
      if (sub === "sub") {
        data.subscription_id = orderDetail?.subscription_id || "";
      }
      primaryCard(data)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(
            fetchCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.customer_chargebee_id,
              orderDetail?.subscription_id
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
          setLoading(false);
        });
    } else {
      const data = {
        order_id: orderDetail?.id || "",
        franchise_id: orderDetail?.franchise_id || "",
        payment_source_id: source,
      };
      deleteCard(data)
        .then((res) => {
          setDialog({ type: false, check: "", id: "" });
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(
            fetchCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.customer_chargebee_id,
              orderDetail?.subscription_id
            )
          );
          setLoading(false);
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
          setLoading(false);
        });
    }
  };
  const handleDiscard = () => {
    if (formik.dirty) {
      setDialogDis(true);
    } else {
      setCardCanva(false);
      formik.resetForm();
      setAddNewCheck(false);
    }
  };
  const fullYear = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + fullYear);

  const handleCardNumber = (incomingValue) => {
    const rawText = [...incomingValue.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });

    formik.setFieldValue("cardNumber", creditCard.join(""));
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChangeS = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <div
      id="kt_OrderCardDetailEdit_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
          <div id="kt_OrderCardDetailEdit_panel_close"></div>
        </div>
      </div>

      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content scrollhd px-10 ">
          <div>
            <div>
              <h5>{intl.formatMessage({ id: "Card.Details" })}</h5>
            </div>
            <div
              id="kt_quick_panel_logs_kt_OrderCardDetailEdit_panel"
              className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10 custom-scroll"
            >
              <div className="mt-5">
                <div className="border-bottom pb-4 mb-5">
                  <div className="row">
                    {cardDetailArray && cardDetailArray.length > 0
                      ? cardDetailArray.map((item, index) => (
                        <div className="col-sm-12">
                          <div
                            className="carddtl-box"
                            style={{ position: "relative" }}
                          >
                            <div>
                              <div>
                                {item?.brand
                                  ? capitalizeFirstLetter(item?.brand)
                                  : "-"}
                                &nbsp;
                                {intl.formatMessage({ id: "ending.in" })}
                                &nbsp;{item?.last4 || "-"}
                              </div>
                              <div>
                                {intl.formatMessage({ id: "Expires" })}{" "}
                                {item?.expiry_month || "-"}/
                                {item?.expiry_year || "-"}
                              </div>
                              <div>{item?.first_name || "-"}</div>
                            </div>
                            <div
                              className="text-right"
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                right: "0px",
                              }}
                            >
                              {item?.is_primary_card === "1" ? (
                                ""
                              ) : (
                                <Button
                                  variant="link"
                                  className="p-0"
                                  onClick={() =>
                                    handleAction(
                                      "primary",
                                      item.payment_source_id,
                                      "sub"
                                    )
                                  }
                                >
                                  Primary for subscription
                                </Button>
                              )}
                              &nbsp;|&nbsp;
                              {item?.is_customer_primary_card === "1" ? (
                                ""
                              ) : (
                                <>
                                  <Button
                                    variant="link"
                                    className="p-0"
                                    onClick={() =>
                                      handleAction(
                                        "primary",
                                        item.payment_source_id
                                      )
                                    }
                                  >
                                    Primary for Customer
                                  </Button>
                                  &nbsp;|&nbsp;
                                </>
                              )}
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() =>
                                  setDialog({
                                    type: true,
                                    check: "delete",
                                    id: item.payment_source_id,
                                  })
                                }
                              >
                                {intl.formatMessage({ id: "Delete" })}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))
                      : ""}

                    <div className="col-sm-4">
                      <div
                        className="carddtl-box d-flex align-items-center flex-column "
                        onClick={() => {
                          formik.resetForm();
                          handleIntent();
                          setAddNewCheck(addNewCheck ? false : true);
                        }}
                      >
                        <div className="pt-3">
                          <span className="svg-icon svg-icon-xxl ">
                            <SVG
                              className="h-75 align-self-end"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Plus.svg"
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div>{intl.formatMessage({ id: "Add.New.Card" })}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <img
                      src="../media/payment/visa.png"
                      alt=""
                      className="mr-1"
                    />
                    <img
                      src="../media/payment/master-card.png"
                      alt=""
                      className="mr-1"
                    />
                    <img
                      src="../media/payment/american-express.png"
                      alt=""
                      className="mr-1"
                    />
                    <img
                      src="../media/payment/stripe.png"
                      alt=""
                      className="mr-1"
                    />
                  </div>
                  {addNewCheck ? (
                    <div className="mt-10">
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Card Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "Card.Number",
                            })}
                            className={`form-control  ${getInputClasses(
                              "cardNumber"
                            )}`}
                            name="cardNumber"
                            maxLength={"19"}
                            value={formik?.values?.cardNumber}
                            onChange={(e) => handleCardNumber(e.target.value)}
                          />
                          {formik.touched.cardNumber &&
                            formik.errors.cardNumber ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.cardNumber}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-12 mb-5">
                              <div>
                                <Form.Label>
                                  {intl.formatMessage({ id: "Expires" })}
                                </Form.Label>
                              </div>

                              <div className="row">
                                <div className="col-5">
                                  <FormControl fullWidth>
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      IconComponent={(props) => (
                                        <i {...props}>
                                          <KeyboardArrowDownIcon />
                                        </i>
                                      )}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      displayEmpty
                                      name="expires"
                                      className={`form-control w-100  ${getInputClasses(
                                        "expires"
                                      )}`}
                                      {...formik.getFieldProps("expires")}
                                    >
                                      {months.map((item, index) => (
                                        <MenuItem value={item.id}>
                                          {item.value}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {formik.touched.expires &&
                                    formik.errors.expires ? (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.expires}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-2 mt-2 text-center">/</div>
                                <div className="col-5">
                                  <FormControl fullWidth>
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      IconComponent={(props) => (
                                        <i {...props}>
                                          <KeyboardArrowDownIcon />
                                        </i>
                                      )}
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      displayEmpty
                                      name="expiresYear"
                                      className={`form-control w-100  ${getInputClasses(
                                        "expiresYear"
                                      )}`}
                                      {...formik.getFieldProps("expiresYear")}
                                    >
                                      {years.map((data, index) => (
                                        <MenuItem value={data}>{data}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  {formik.touched.expiresYear &&
                                    formik.errors.expiresYear ? (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.expiresYear}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>
                              {intl.formatMessage({ id: "CVV" })}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={intl.formatMessage({ id: "CVV" })}
                              className={`form-control  ${getInputClasses(
                                "cvv"
                              )}`}
                              name="cvv"
                              {...formik.getFieldProps("cvv")}
                            />
                            {formik.touched.cvv && formik.errors.cvv ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.cvv}
                                </div>
                              </div>
                            ) : null}
                          </Form.Group>
                        </div>
                      </div>
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>
                            {intl.formatMessage({ id: "Name.On.Card" })}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={intl.formatMessage({
                              id: "Name.On.Card",
                            })}
                            className={`form-control  ${getInputClasses(
                              "nameCard"
                            )}`}
                            name="nameCard"
                            {...formik.getFieldProps("nameCard")}
                          />
                          {formik.touched.nameCard && formik.errors.nameCard ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.nameCard}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <h6>
                            {intl.formatMessage({ id: "Billing.addressOnly" })}
                          </h6>
                        </div>

                        <div className="col-12 mt-3">
                          <div className="row align-items-center">
                            <div className="col-6 d-flex justify-content-between align-items-center ">
                              <div className="form-groupB w-100">
                                <input
                                  type="text"
                                  className={`form-control  ${getInputClasses(
                                    "postCode"
                                  )}`}
                                  placeholder="Post Code"
                                  name="postCode"
                                  {...formik.getFieldProps("postCode")}
                                />
                              </div>
                              <div className="mb-2">
                                <span className="symbol-label ml-2">
                                  <span
                                    className="svg-icon svg-icon-lg svg-icon-primary"
                                    onClick={() =>
                                      handleGetAddress(formik.values.postCode)
                                    }
                                  >
                                    <SVG
                                      title=" "
                                      className="h-25 align-self-end"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Search.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6 mb-2 ">
                              <span
                                style={{ color: "#ff6601", cursor: "pointer" }}
                                onClick={() => setEnterManual(true)}
                              >
                                {intl.formatMessage({ id: "Enter.Manually" })}
                              </span>
                            </div>
                            {formik.touched.postCode &&
                              formik.errors.postCode ? (
                              <div className="fv-plugins-message-container ml-2">
                                <div className="fv-help-block ml-2">
                                  {formik.errors.postCode}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          {!enterManual ? (
                            <FormControl fullWidth>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                IconComponent={(props) => (
                                  <i {...props}>
                                    <KeyboardArrowDownIcon />
                                  </i>
                                )}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                name="address"
                                className={`form-control w-100  ${getInputClasses(
                                  "address"
                                )}`}
                                {...formik.getFieldProps("address")}
                              >
                                <MenuItem value="">Address Line</MenuItem>
                                {list?.addressList &&
                                  list?.addressList.map((data, index) => {
                                    const addressData = data.formatted_address.toString();

                                    return (
                                      <MenuItem value={addressData} key={index}>
                                        {addressData}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          ) : (
                            <Form.Control
                              type="text"
                              placeholder="Address"
                              className={`form-control w-100  ${getInputClasses(
                                "address"
                              )}`}
                              name="address"
                              {...formik.getFieldProps("address")}
                            />
                          )}
                          {formik.touched.address && formik.errors.address ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.address}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-5">
                          <Form.Control
                            type="text"
                            placeholder="City"
                            className={`form-control  ${getInputClasses(
                              "city"
                            )}`}
                            name="city"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.city}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {orderDetail?.payment_method !== "card" && addNewCheck &&
                    <>
                      <label>Auto Collection</label>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.checkedA}
                              onChange={handleChangeS}
                              name="checkedA"
                              color="primary"
                            />
                          }
                          label={state.checkedA ? "On" : "Off"}
                        />
                      </FormGroup>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            {addNewCheck && (
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder font-size-sm "
              >
                {intl.formatMessage({ id: "SAVE" })}
              </button>
            )}
          </div>
        </div>
      </form>
      {loading && <LoadingCustome />}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setActive={setCardCanva}
      />
      <CustomeDialog
        header={"Delete Payment Method?"}
        mainText={"Do you want to delete this card?"}
        handleAction={handleAction}
        setDialog={setDialog}
        dialog={dialog}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
