/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { FormControl, MenuItem, Select } from "@material-ui/core/";
import { depotOpenHours } from "../../../__mocks__/dummyProductData";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogModal from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { NewDepotSchema } from "../../depots/depot-form/validationSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editDepot } from "../../../_redux/depots/depotsCrud";
// import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { fetchDepotDetail } from "../../../_redux/depots/deoptsActions";
import { fetchFranchisesDepot } from "../../../_redux/franchises/franchisesActions";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import CountryPhoneNumber from "../../../../../../_metronic/layout/components/CountryPhoneNumber";
import { WeekComponent } from "../../depots/depot-component/WeekComponent";

export function DepotDetailsEdit({ type, data, check }) {
  const intl = useIntl();

  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const dispatch = useDispatch();
  const { detailData } = useSelector(
    (state) => ({ detailData: state.country.activeCountry }),
    shallowEqual
  );

  const initialValues = {
    status: data && data.status ? data.status : "0",
    name: data && data.name ? data.name : "",
    short_name: data && data.short_name ? data.short_name : "",
    depot_reference_id:
      data && data.depot_reference_id ? data.depot_reference_id : "",
    address_line_1: data && data.address_line_1 ? data.address_line_1 : "",
    address_line_2: data && data.address_line_2 ? data.address_line_2 : "",
    address_line_3: data && data.address_line_3 ? data.address_line_3 : "",
    city: data && data.city ? data.city : "",
    postcode: data && data.postcode ? data.postcode : "",
    country: data && data.country ? data.country : "",
    latitude: data && data.latitude ? data.latitude : "",
    longitude: data && data.longitude ? data.longitude : "",
    manager_name: data && data.manager_name ? data.manager_name : "",
    manager_email: data && data.manager_email ? data.manager_email : "",
    additional_emails:
      data && data.additional_emails ? data.additional_emails : "",
    phone: data && data.phone ? data.phone : "",
    depot_hours:
      data && data.depot_hours
        ? data.depot_hours
        : [
            { day: "1", open_time: "", close_time: "" },
            { day: "2", open_time: "", close_time: "" },
            { day: "3", open_time: "", close_time: "" },
            { day: "4", open_time: "", close_time: "" },
            { day: "5", open_time: "", close_time: "" },
            { day: "6", open_time: "", close_time: "" },
            { day: "7", open_time: "", close_time: "" },
          ],
  };

  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });

    const datas = {
      franchise_id: data && data.franchise_id,
      status: values.status === "" ? 1 : values.status,
      name: values.name,
      short_name: values.short_name,
      depot_reference_id: values.depot_reference_id,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3,
      city: values.city,
      postcode: values.postcode,
      country: values.country,
      latitude: values.latitude,
      longitude: values.longitude,
      manager_name: values.manager_name,
      manager_email: values.manager_email,
      additional_emails: values.additional_emails,
      phone: values.phone,
      depot_hours: values.depot_hours,
    };
    const dataDepot = {
      status: values.status,
      name: values.name,
      short_name: values.short_name,
      depot_reference_id: values.depot_reference_id,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3,
      city: values.city,
      postcode: values.postcode,
      country: values.country,
      latitude: values.latitude,
      longitude: values.longitude,
      manager_name: values.manager_name,
      manager_email: values.manager_email,
      additional_emails: values.additional_emails,
      phone: values.phone,
      depot_hours: values.depot_hours,
    };

    editDepot(
      check === "depot" ? dataDepot : datas,
      check === "depot" ? data?.id : data?.depot_id
    )
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });

        if (check === "depot") {
          dispatch(fetchDepotDetail(data?.id));
          canvaCancel(`kt_depotdetailsedit_panel${type}_close`);
        } else {
          dispatch(fetchFranchisesDepot(data?.franchise_id));
          canvaCancel(`kt_depotdetailsedit_panel${type}_close`);
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
          error: error.response.data.errors,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: NewDepotSchema(intl, Yup),
    onSubmit: handleSubmit,
  });

  const handleHours = (e, type, target) => {
    const { values } = formik;
    const { depot_hours } = values;
    const arr = Array.from(depot_hours);

    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].day === type) {
          const currentItem = { ...arr[i] };
          if (target === "open_time") {
            if (!e.target.value) {
              currentItem.open_time = e.target.value;
              currentItem.close_time = e.target.value;
            } else {
              currentItem.open_time = e.target.value;
            }
          } else {
            currentItem.close_time = e.target.value;
          }
          arr[i] = currentItem;
          formik.setFieldValue("depot_hours", arr);
        }
      }
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target.className === "offcanvas-overlay") {
        formik.resetForm();
      }
    };

    document.addEventListener("click", checkIfClickedOutside, true);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={`kt_depotdetailsedit_panel${type}`}
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-rightss"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1  pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id={`kt_depotdetailsedit_panel${type}_close`}
            onClick={() => formik.resetForm()}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className=" px-10">
          <div>
            <h5>{intl.formatMessage({ id: "Depot.Details" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id={`kt_quick_panel_logs_kt_depotdetailsedit_panel${type}`}
              className=" fade pt-3 pr-5 mr-n5 scroll  active show "
            >
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Depot.Name" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "name"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Depot.Name" })}
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Depot.Short" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "short_name"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Depot.Short" })}
                      name="short_name"
                      {...formik.getFieldProps("short_name")}
                    />
                    {formik.touched.short_name && formik.errors.short_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.short_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Depot.ID" })}</label>
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "depot_reference_id"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Depot.ID" })}
                      name="depot_reference_id"
                      {...formik.getFieldProps("depot_reference_id")}
                      disabled
                    />
                    {formik.touched.depot_reference_id &&
                    formik.errors.depot_reference_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.depot_reference_id}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-groupB">
                    <label>
                      {intl.formatMessage({ id: "Depot.Address.Line1" })}
                    </label>
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_1"
                      )}`}
                      placeholder={intl.formatMessage({
                        id: "Depot.Address.Line1",
                      })}
                      name="address_line_1"
                      {...formik.getFieldProps("address_line_1")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-groupB">
                    <label>
                      {intl.formatMessage({ id: "Depot.Address.Line2" })}
                    </label>
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_2"
                      )}`}
                      placeholder={intl.formatMessage({
                        id: "Depot.Address.Line2",
                      })}
                      name="address_line_2"
                      {...formik.getFieldProps("address_line_2")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-groupB">
                    <label>
                      {intl.formatMessage({ id: "Depot.Address.Line3" })}
                    </label>
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_3"
                      )}`}
                      placeholder={intl.formatMessage({
                        id: "Depot.Address.Line3",
                      })}
                      name="address_line_3"
                      {...formik.getFieldProps("address_line_3")}
                    />
                  </div>
                  {formik.touched.address_line_1 &&
                  formik.errors.address_line_1 ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.address_line_1}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "CITY" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "city"
                      )}`}
                      placeholder={intl.formatMessage({ id: "CITY" })}
                      name="city"
                      {...formik.getFieldProps("city")}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "POSTCODE" })}</label>
                    <input
                      type="text"
                      maxLength={"10"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "postcode"
                      )}`}
                      placeholder={intl.formatMessage({ id: "POSTCODE" })}
                      name="postcode"
                      {...formik.getFieldProps("postcode")}
                    />
                    {formik.touched.postcode && formik.errors.postcode ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.postcode}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <label>{intl.formatMessage({ id: "COUNTRY" })}</label>
                  <div className="form-groupB">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control w-100  ${getInputClasses(
                          formik,
                          "country"
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        name="country"
                        {...formik.getFieldProps("country")}
                      >
                        <MenuItem checked value="">
                          {intl.formatMessage({ id: "COUNTRY" })}
                        </MenuItem>
                        {detailData &&
                          detailData.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              <span style={{ color: "lightgrey" }}>
                                {data.timezone}
                              </span>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.country && formik.errors.country ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Latitude" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "latitude"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Latitude" })}
                      name="latitude"
                      {...formik.getFieldProps("latitude")}
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.latitude}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Longitude" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "longitude"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Longitude" })}
                      name="longitude"
                      {...formik.getFieldProps("longitude")}
                    />
                    {formik.touched.longitude && formik.errors.longitude ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.longitude}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "MANAGER.NAME" })}</label>
                    <input
                      type="text"
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "manager_name"
                      )}`}
                      placeholder={intl.formatMessage({ id: "MANAGER.NAME" })}
                      name="manager_name"
                      {...formik.getFieldProps("manager_name")}
                    />
                    {formik.touched.manager_name &&
                    formik.errors.manager_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Manager.Email" })}</label>
                    <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "manager_email"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Manager.Email" })}
                      name="manager_email"
                      {...formik.getFieldProps("manager_email")}
                    />
                    {formik.touched.manager_email &&
                    formik.errors.manager_email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB">
                    <label>{intl.formatMessage({ id: "Manager.Phone" })}</label>
                    <CountryPhoneNumber
                      countryPhoneValue={formik?.values?.phone}
                      formik={formik}
                      fieldKey={"phone"}
                    />
                    {/* <input
                      type="text"
                      className={`form-control  ${getInputClasses(
                        formik,
                        "phone"
                      )}`}
                      placeholder={intl.formatMessage({ id: "Manager.Phone" })}
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    /> */}
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-groupB">
                    <label>
                      {intl.formatMessage({ id: "Additional.Email" })}
                    </label>
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Additional Emails"
                      name="additional_emails"
                      {...formik.getFieldProps("additional_emails")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 m-1">
                  <label>
                    <strong>
                      {intl.formatMessage({ id: "Opening.Hours" })}
                    </strong>
                  </label>
                </div>
                <WeekComponent
                  handleHours={handleHours}
                  formik={formik}
                  depotOpenHours={depotOpenHours}
                />

                <div className="col-sm-12 col-md-12"></div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-groupB row">
                    <label className="col-6 col-sm-8 col-md-8 col-form-label ">
                      {intl.formatMessage({ id: "ACTIVE" })}
                    </label>
                    <div className="col-6 col-sm-8 col-md-4 text-right mt-1">
                      <span className="switch switch-outline switch-icon switch-primary">
                        <label>
                          <input
                            type="checkbox"
                            checked={formik.values.status === 1 ? true : false}
                            name="quick_panel_notifications_2"
                            onChange={() => setDialog(true)}
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>

        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              type="reset"
              onClick={() =>
                canvaCancel(`kt_depotdetailsedit_panel${type}_close`)
              }
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>

        <ActiveDialogModal
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
          depotDefault={data?.is_default_depot || data?.is_default}
        />
      </form>

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
