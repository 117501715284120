/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal, Table } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { LoadingSpinner } from "../../../../../../_metronic/_partials/controls/LoadingSpinner";
import { getInputClasses } from "../../../../../utils/commonFunction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { FormControl, MenuItem, Select } from "@material-ui/core";
// import { chargebee } from "../../../_mocks/dummyData";
import { addProduct, getProduct } from "../../../_redux/order/orderCrud";
import { useEffect } from "react";
import { useOrderUIContext } from "../../../OrderUIContext";
import { getPruductObject } from "../../../EditOrderUtils";
// import { editProductCharge } from "../../../order-form/validationSchema";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  // FormLabel,
} from "@material-ui/core";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";

const AddProductModal = ({ dialog, setDialog }) => {
  const orderUIContext = useOrderUIContext();
  // const dispatch = useDispatch();
  const { editOrderDetail } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
    }),
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const initialValues = {
    id: "",
    name: "",
    billing: "",
    days: "1",
    search: "",
    charge_once: "once",
    is_packaging_material: "",
  };

  const handleGetList = (value) => {
    setIsLoading(true);
    const data = {
      franchise_id: editOrderDetail?.franchise_id,
      type: dialog?.type,
      search: value,
    };
    addProduct(data)
      .then((res) => {
        setIsLoading(false);
        setList(res.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      franchise_id: editOrderDetail?.franchise_id || "",
      item_id: values?.id || "",
    };
    if (dialog?.type === "addon") {
      data.period =
        orderUIContext?.state?.orderData?.subscription_billing_period || "";
      data.period_unit =
        orderUIContext?.state?.orderData?.subscription_billing_period_unit ||
        "";
    }
    getProduct(data)
      .then((res) => {
        const item =
          res.data.data && res.data.data.length > 0 && res.data.data[0];
        const dataProduct = getPruductObject(item);

        if (dataProduct?.type === "charge") {
          dataProduct.name = values?.name || "";

          dataProduct.service_period_days = values?.days || "";
          dataProduct.charge_on_event =
            values?.billing === "immediately" ? "" : values?.billing || "";
          dataProduct.charge_on_option =
            values?.billing === "immediately"
              ? "immediately"
              : values?.billing === ""
              ? ""
              : "on_event" || "";
        }
        // eslint-disable-next-line eqeqeq
        if (values?.is_packaging_material == 1) {
          dataProduct.is_packaging_material = 1;
        }
        if (
          values?.billing === "subscription_trial_start" ||
          values?.billing === "plan_activation" ||
          values?.billing === "subscription_activation"
        ) {
          dataProduct.charge_once =
            values?.charge_once === "once" ? true : false;
        }
        if (
          dataProduct.pricing_model === "stairstep" ||
          dataProduct.pricing_model === "tiered"
        ) {
          dataProduct.chargeAmountList = item?.amount;
          dataProduct.amount = "";
          dataProduct.price =
            dataProduct.chargeAmountList &&
            dataProduct.chargeAmountList?.length > 0 &&
            dataProduct.chargeAmountList[0]?.price
              ? dataProduct.chargeAmountList[0]?.price / 100
              : "0";
        }
        const updatedProductArray = [
          ...orderUIContext.state.productArray,
          dataProduct,
        ];
        orderUIContext.handleAddProductEdit(updatedProductArray);
        setIsLoading(false);
        setSnack({ call: true, type: "success", message: res.data.message });
        handleClose();
      })
      .catch((error) => {
        setIsLoading(true);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: dialog?.type === "charge" ? editProductCharge : "",
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setDialog({ check: false });
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      handleGetList(formik?.values?.search);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.search]);
  return (
    <Modal
      show={dialog?.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          {dialog?.title}
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body
          style={{
            height:
              page === 2 && dialog.type === "addon"
                ? "15vh"
                : page === 2 && dialog.type !== "addon"
                ? "40vh"
                : "60vh",
            overflow: "scroll",
          }}
        >
          {page === 1 && (
            <div className="row">
              <div className="w-100 mb-4" style={{ position: "relative" }}>
                <input
                  style={{ paddingRight: "40px" }}
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder={"Search"}
                  value={formik.values.search}
                  onChange={(e) => {
                    formik.setFieldValue("search", e.target.value);
                    // const value = e.target.value;
                    // setTimeout(() => {
                    //   console.log("123123", value);
                    //   handleGetList(value);
                    // }, 1500);
                    // setFieldValue("searchTextBook", "");
                    // setFieldValue("searchText", e.target.value);
                    // setTimeout(() => {
                    //   handleSubmit();
                    // }, 1000);
                  }}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: "15px",
                  }}
                  className="fa fa-search"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="w-100">{isLoading && <LoadingSpinner />}</div>
              <Table responsive className="summerytable customColor">
                <tbody>
                  {list && list?.length > 0 ? (
                    list.map((item, index) => {
                      const check = orderUIContext.state?.productArray.some(
                        (e) => e.id === item.id
                      );
                      if (check) {
                        return;
                      }
                      return (
                        <tr
                          onClick={() => {
                            formik.setValues({
                              ...formik.values,
                              id: item.id,
                              name: item.name,
                              is_packaging_material:
                                item?.is_packaging_material || "",
                            });
                            setPage(2);
                          }}
                        >
                          <td>{item?.name}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <>{!isLoading && <NoRecord />}</>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          {page === 2 && dialog.type === "addon" && (
            <span>Are you sure you want add this addon</span>
          )}
          {page === 2 && dialog.type !== "addon" && (
            <div className="row">
              <div className="col-12">
                <label>Charge customers on</label>
                <div className="form-groupB">
                  <FormControl fullWidth>
                    <Select
                      name="billing"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "billing"
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      {...formik.getFieldProps("billing")}
                      // disabled={checked === "customers"}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="subscription_creation">
                        On subscription creation
                      </MenuItem>

                      <MenuItem value="subscription_trial_start">
                        On subscription trial start
                      </MenuItem>

                      <MenuItem value="plan_activation">
                        On plan activation
                      </MenuItem>

                      <MenuItem value="subscription_activation">
                        On subscription activation
                      </MenuItem>

                      <MenuItem value="immediately">Immediately</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.billing && formik.errors.billing ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.billing}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>{" "}
              {(formik.values?.billing === "subscription_trial_start" ||
                formik.values?.billing === "plan_activation" ||
                formik.values?.billing === "subscription_activation") && (
                <div className="col-12">
                  <FormControl component="fieldset">
                    <label>Apply charges</label>
                    <RadioGroup
                      aria-label="gender"
                      name="gender2"
                      value={formik.values.charge_once}
                      onChange={(e) => {
                        formik.setFieldValue("charge_once", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={"once"}
                        control={<Radio />}
                        label="Charge once"
                      />

                      <FormControlLabel
                        value={"every"}
                        control={<Radio />}
                        label="Every time this event occurs"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {/* <div className="col-12">
                <label>Quantity</label>
                <div className="form-groupB">
                  <input
                    type="text"
                    className={`form-control  `}
                    placeholder={"Quantity"}
                    name="days"
                    {...formik.getFieldProps("days")}
                  />
                  {formik.touched.days && formik.errors.days ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.days}</div>
                    </div>
                  ) : null}
                </div>
              </div> */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              //   disabled={isLoading}
            >
              Dismiss
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={dialog?.type === "addon" && list && list.length === 0}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </Modal>
  );
};

export default AddProductModal;
