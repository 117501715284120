/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";

import SVG from "react-inlinesvg";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import { capitalizeFirstLetter } from "../../../../../utils/canvaCloseFunction";

export function Details({ detailData, permission }) {
  const init = ["kt_franchisesdetailsedit_panel"];
  const intl = useIntl();

  useEffect(() => {
    initIds(init);
  }, [init]);

  return (
    <>
      {detailData ? (
        <div className="card card-custom  gutter-b mb-10">
          <div className="card-body">
            <div className="d-flex ">
              <h3 className="card-title align-items-start flex-column d-flex">
                <span className="font-weight-bolder text-dark">
                  {detailData?.name}
                </span>
                <span
                  className={`text-${
                    detailData?.status === 1 ? "success" : "danger"
                  } font-weight-bold font-size-sm mt-3`}
                >
                  {detailData?.status === 1
                    ? intl.formatMessage({ id: "ACTIVE" })
                    : intl.formatMessage({ id: "INACTIVE" })}
                </span>
              </h3>
              {permission?.setting_franchise?.edit ? (
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                  id="kt_franchisesdetailsedit_panel_toggle"
                  style={{ cursor: "pointer" }}
                >
                  <SVG
                    style={{ color: "orange" }}
                    title="Edit"
                    className="h-22 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  ></SVG>
                </span>
              ) : (
                ""
              )}
            </div>
            {!detailData?.id ? (
              <div className="text-center mt-8">
                <CircularProgress />
              </div>
            ) : (
              <div className="row">
                <div className="col-md-4 mb-5">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "FRANCHISE.SHORT" })}
                    </strong>{" "}
                  </div>
                  <div className="text-muted">{detailData?.short_name}</div>
                </div>
                <div className="col-md-3 mb-5">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "FRANCHISE.ID" })}
                    </strong>{" "}
                  </div>
                  <div className="text-muted">
                    {detailData?.franchise_reference_id}
                  </div>
                </div>
                <div className="col-md-5 mb-5">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "Franchise.Manager" })}
                    </strong>{" "}
                  </div>
                  <div className="text-muted">{detailData?.manager_name}</div>
                </div>

                <div className="col-md-7 mb-5">
                  <div>
                    <strong>
                      {intl.formatMessage({ id: "TRADING.NAME" })}
                    </strong>{" "}
                  </div>
                  <div className="text-muted">{detailData?.trading_name}</div>
                </div>
                <div className="col-md-5 mb-5">
                  <div>
                    <strong>{intl.formatMessage({ id: "VAT.Reg.Nr" })}</strong>{" "}
                  </div>
                  <div className="text-muted">
                    {detailData?.vat_registration_no}
                  </div>
                </div>

                <div className="col-md-7 mb-5">
                  <div>
                    <strong>{intl.formatMessage({ id: "Address" })}</strong>{" "}
                  </div>
                  <div className="text-muted">
                    {detailData?.address_line_1}
                    <br></br>

                    {detailData && detailData.address_line_2 ? (
                      <>
                        {detailData.address_line_2}

                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {detailData && detailData.address_line_3 ? (
                      <>
                        {detailData.address_line_3}

                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {detailData?.city}
                    <br></br>
                    {detailData?.postcode}
                  </div>
                </div>
                <div className="col-md-5 mb-5">
                  <div className="mb-5">
                    <div>
                      <strong>{intl.formatMessage({ id: "PHONE" })}</strong>{" "}
                    </div>
                    <div className="text-muted"> {detailData?.phone}</div>
                  </div>
                  <div className="mb-5">
                    <div>
                      <strong>{intl.formatMessage({ id: "EMAIL" })}</strong>{" "}
                    </div>
                    <div className="text-muted"> {detailData?.email}</div>
                  </div>
                </div>
                {/* <div className="col-md-4 mb-5">
                  <div className="mb-5">
                    <div>
                      <strong>Price increase type</strong>{" "}
                    </div>
                    <div className="text-muted"> {detailData?.price_increase_type ?capitalizeFirstLetter(detailData?.price_increase_type):"-"}</div>
                  </div>
                 
                </div> */}
                {/* <div className="col-md-4 mb-5">
                  <div className="mb-5">
                    <div>
                      <strong>Price increase value</strong>{" "}
                    </div>
                    <div className="text-muted"> {detailData?.price_increase_value||"-"}</div>
                  </div>
                 
                </div> */}
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
