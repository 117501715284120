import { createSlice } from "@reduxjs/toolkit";

const initialProductsState = {
  detail: {},
  depotLoading: false,
  vanLoading: false,
  driverLoading: false,
  driverAddLoading: false,
  fraPostcodeLoading: false,
  secPostcodeLoading: false,
  list: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    productForEdit: undefined,
    lastError: null,
  },
  franchiseCount: {},
  countryFranchise: [],
  workingDays: {},
  van: [],
  depot: [],
  driverLoader: [],
  driverSearch: [],
  timeline:[],
  siteList:[],
  siteLoading:false,
  classList:[],
  classLoading:false
};
export const callTypes = {
  list: "list",
  action: "action",
  depot: "depot",
  van: "van",
  driver: "driver",
  driverAddList: "driverAddList",
  fraPostcode: "fraPostcode",
  secPostcode: "secPostcode",
  siteList:"siteList",
  classList:"classList",
  
};

export const franchiseSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = false;
      } else if (action.payload.callType === callTypes.depot) {
        state.depotLoading = false;
      } else if (action.payload.callType === callTypes.van) {
        state.vanLoading = false;
      } else if (action.payload.callType === callTypes.driver) {
        state.driverLoading = false;
      } else if (action.payload.callType === callTypes.driverAddList) {
        state.driverAddLoading = false;
      } else if (action.payload.callType === callTypes.fraPostcode) {
        state.fraPostcodeLoading = false;
      } else if (action.payload.callType === callTypes.secPostcode) {
        state.secPostcodeLoading = false;
      }else if (action.payload.callType === callTypes.siteList) {
        state.siteLoading = false;
      }else if (action.payload.callType === callTypes.classList) {
        state.classLoading = false;
      }  else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.list.listLoading = true;
      } else if (action.payload.callType === callTypes.depot) {
        state.depotLoading = true;
      } else if (action.payload.callType === callTypes.van) {
        state.vanLoading = true;
      } else if (action.payload.callType === callTypes.driver) {
        state.driverLoading = true;
      } else if (action.payload.callType === callTypes.driverAddList) {
        state.driverAddLoading = true;
      } else if (action.payload.callType === callTypes.fraPostcode) {
        state.fraPostcodeLoading = true;
      } else if (action.payload.callType === callTypes.secPostcode) {
        state.secPostcodeLoading = true;
      }else if (action.payload.callType === callTypes.siteList) {
        state.siteLoading = true;
      }else if (action.payload.callType === callTypes.classList) {
        state.classLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    resetState: () => {
      return initialProductsState;
    },
    resetStateDetail: (state) => {
      state.detail = {};
    },
    // getProductById

    // findProducts
    franchiseFetched: (state, action) => {
      const { totalCount, entities } = action.payload;

      state.list.listLoading = false;
      state.list.error = null;
      state.list.entities = entities;
      state.list.totalCount = totalCount;
    },
    franchiseFetchedTotalCount: (state, action) => {
      const { totalCount } = action.payload;

      state.franchiseCount.totalCount = totalCount;
    },
    franchiseDetailFetched: (state, action) => {
      const { data } = action.payload;
      state.detail = data;
    },
    franchisePostCodeFetched: (state, action) => {
      const { data, total } = action.payload;
      state.postcode = data;
      state.poscodeTotal = total;
      state.fraPostcodeLoading = false;
    },
    franchiseSectorPostCodeFetched: (state, action) => {
      const { data } = action.payload;
      state.sectorList = data;
      state.secPostcodeLoading = false;
    },
    franchiseDetailVan: (state, action) => {
      const { data } = action.payload;
      state.van = data;
      state.vanLoading = false;
    },
    franchiseDetailDepot: (state, action) => {
      const { data } = action.payload;
      state.depot = data;
      state.depotLoading = false;
    },
    franchiseDetailDriverLoader: (state, action) => {
      const { data } = action.payload;
      state.driverLoader = data;
      state.driverLoading = false;
    },
    franchiseDetailSearch: (state, action) => {
      const { data } = action.payload;
      state.depotSearch = data;
    },
    franchiseDriverSearch: (state, action) => {
      const { data } = action.payload;
      state.driverSearch = data;
      state.driverAddLoading = false;
    },
    franchiseCountryFetched: (state, action) => {
      const { data } = action.payload;
      state.countryFranchise = data;
    },
    franchiseWorkingDaysFetched: (state, action) => {
      const { data } = action.payload;
      state.workingDays = data;
    },
    franchiseTimeline: (state, action) => {
      const { data } = action.payload;
      state.timeline = data;
    },
    franchiseSiteListFetched: (state, action) => {
      const { data } = action.payload;
      state.siteList = data;
      state.siteLoading= false
    },
    franchiseClassListFetched: (state, action) => {
      const { data } = action.payload;
      state.classList = data;
      state.classLoading = false
    },
  },
});
