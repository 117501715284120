import {
  PricingArray,
  editAddon,
  editCharge,
  editPlan,
} from "../../ProductHelper";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { NewProductCanvas } from "../../product-canvas/NewProductCanvas";
import SVG from "react-inlinesvg";
import currencyApi from "../../../../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../../../_redux/country/countryAction";
import { fetchFranchise } from "../../../../_redux/products/productsActions";
import { initIds } from "../../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../../../../utils/commonFunction";

const ProductDetailCard = ({ setCanva, detailData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const init = ["kt_plan_panel"];

  useEffect(() => {
    initIds(init);
  }, [init]);

  const [canvaDetail, setCanvaDetail] = useState({ type: false, data: {} });

  const [pricingArray, setPricingArray] = useState(PricingArray);
  const [currency, setCurrency] = useState("");

  const handlePrice = (data) => {
    const arr = [...pricingArray];

    for (let item of data) {
      switch (true) {
        case item?.item_price.period_unit === "week" &&
          item?.item_price.period === 1 &&
          !arr[0].check:
          if (item?.item_price.currency_code === currency) {
            arr[0] = {
              ...item.item_price,
              title: "Weekly",
              check: true,
              disable: false,
            };
          } else {
            arr[0] = { ...item.item_price, title: "Weekly", disable: false };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 1 &&
          !arr[1].check:
          if (item.item_price.currency_code === currency) {
            arr[1] = {
              ...item.item_price,
              title: "Every 1 month",
              check: true,
              disable: false,
            };
          } else {
            arr[1] = {
              ...item.item_price,
              title: "Every 1 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 2 &&
          !arr[2].check:
          if (item.item_price.currency_code === currency) {
            arr[2] = {
              ...item.item_price,
              title: "Every 2 month",
              check: true,
              disable: false,
            };
          } else {
            arr[2] = {
              ...item.item_price,
              title: "Every 2 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 3 &&
          !arr[3].check:
          if (item.item_price.currency_code === currency) {
            arr[3] = {
              ...item.item_price,
              title: "Every 3 month",
              check: true,
              disable: false,
            };
          } else {
            arr[3] = {
              ...item.item_price,
              title: "Every 3 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 4 &&
          !arr[4].check:
          if (item.item_price.currency_code === currency) {
            arr[4] = {
              ...item.item_price,
              title: "Every 4 month",
              check: true,
              disable: false,
            };
          } else {
            arr[4] = {
              ...item.item_price,
              title: "Every 4 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 5 &&
          !arr[5].check:
          if (item.item_price.currency_code === currency) {
            arr[5] = {
              ...item.item_price,
              title: "Every 5 month",
              check: true,
              disable: false,
            };
          } else {
            arr[5] = {
              ...item.item_price,
              title: "Every 5 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 6 &&
          !arr[6].check:
          if (item.item_price.currency_code === currency) {
            arr[6] = {
              ...item.item_price,
              title: "Every 6 month",
              check: true,
              disable: false,
            };
          } else {
            arr[6] = {
              ...item.item_price,
              title: "Every 6 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 7 &&
          !arr[7].check:
          if (item.item_price.currency_code === currency) {
            arr[7] = {
              ...item.item_price,
              title: "Every 7 month",
              check: true,
              disable: false,
            };
          } else {
            arr[7] = {
              ...item.item_price,
              title: "Every 7 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 8 &&
          !arr[8].check:
          if (item.item_price.currency_code === currency) {
            arr[8] = {
              ...item.item_price,
              title: "Every 8 month",
              check: true,
              disable: false,
            };
          } else {
            arr[8] = {
              ...item.item_price,
              title: "Every 8 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 9 &&
          !arr[9].check:
          if (item.item_price.currency_code === currency) {
            arr[9] = {
              ...item.item_price,
              title: "Every 9 month",
              check: true,
              disable: false,
            };
          } else {
            arr[9] = {
              ...item.item_price,
              title: "Every 9 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 10 &&
          !arr[10].check:
          if (item.item_price.currency_code === currency) {
            arr[10] = {
              ...item.item_price,
              title: "Every 10 month",
              check: true,
              disable: false,
            };
          } else {
            arr[10] = {
              ...item.item_price,
              title: "Every 10 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "month" &&
          item.item_price.period === 11 &&
          !arr[11].check:
          if (item.item_price.currency_code === currency) {
            arr[11] = {
              ...item.item_price,
              title: "Every 11 month",
              check: true,
              disable: false,
            };
          } else {
            arr[11] = {
              ...item.item_price,
              title: "Every 11 month",
              disable: false,
            };
          }
          break;
        case item.item_price.period_unit === "year" &&
          item.item_price.period === 1 &&
          !arr[12].check:
          if (item.item_price.currency_code === currency) {
            arr[12] = {
              ...item.item_price,
              title: "Yearly",
              check: true,
              disable: false,
            };
          } else {
            arr[12] = { ...item.item_price, title: "Yearly", disable: false };
          }
          break;

        default:
          break;
      }
      setPricingArray(arr);
    }
  };

  useEffect(() => {
    if (detailData?.metadata?.country) {
      dispatch(fetchFranchise(detailData?.metadata?.country));
      handleCurrency(detailData?.metadata?.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData.metadata, dispatch]);

  useEffect(() => {
    if (detailData?.price && detailData?.price?.length > 0) {
      handlePrice(detailData.price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData.price, currency, detailData?.metadata?.country]);

  useEffect(() => {
    dispatch(fetchActiveCountry({ type: "data_two" }));
  }, [dispatch]);

  const { countryData } = useSelector(
    (state) => ({
      countryData: state.country.activeCountry,
    }),
    shallowEqual
  );

  const handleCurrency = (dd) => {
    const contryCurrency = countryData?.filter(
      (element) => element.id === Number(dd)
    );

    if (contryCurrency?.length) {
      const currencyCode = currencyApi?.filter(
        (element) => element.name === contryCurrency[0].currency
      );

      if (currencyCode?.length) {
        setCurrency(currencyCode[0].cc);
      } else {
        setCurrency("");
      }
    }
  };

  return (
    <>
      <div className="d-flex ">
        <h3 className="card-title align-items-start flex-column d-flex">
          {" "}
          <span
            className="font-weight-bolder text-dark"
            style={{ whiteSpace: "break-spaces", width: "100%" }}
          >
            {detailData?.name}
          </span>
          <span
            className={`text-${
              detailData?.status === "active" ? "success" : "danger"
            } font-weight-bold font-size-sm mt-3`}
          >
            {detailData?.status === "active" ? "Active" : "Archived"}
          </span>
        </h3>
      </div>

      <div className="row">
        <div className="col-md-6 mb-5 d-flex">
          <h3 className="card-title align-items-start flex-column d-flex">
            {" "}
            <span className="font-weight-bolder text-dark">
              {intl.formatMessage({ id: "Product.Details" })}
            </span>
          </h3>

          <span
            className="svg-icon svg-icon-sm svg-icon-primary ml-3"
            onClick={() =>
              setCanvaDetail({
                type: true,
                data: {
                  title:
                    detailData?.type === "charge"
                      ? "Edit Charge"
                      : detailData?.type === "plan"
                      ? "Edit Plan"
                      : "Edit Addon",
                  type: detailData?.type,
                },
              })
            }
            style={{ cursor: "pointer" }}
          >
            <SVG
              style={{ color: "orange" }}
              title="edit"
              className="h-22 align-self-end"
              src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
            ></SVG>
          </span>
        </div>
        <div className="col-md-6 mb-5 d-flex"></div>
        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Product.ID" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.id}</div>
        </div>
        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "External.Name" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.external_name}</div>
        </div>

        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Internal.Name" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.name}</div>
        </div>

        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Description" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.description}</div>
        </div>
        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "Product.Type" })}</strong>{" "}
          </div>
          <div className="text-muted">
            {detailData?.type === "charge"
              ? "Charge"
              : detailData?.type === "plan"
              ? "Plan"
              : "Addon"}
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <div>
            <strong>{intl.formatMessage({ id: "COUNTRY" })}</strong>{" "}
          </div>
          <div className="text-muted">{detailData?.country}</div>
        </div>
        {detailData?.type === "charge" ? (
          <div className="col-md-12 mb-5">
            <div>
              <strong>{intl.formatMessage({ id: "Unit.Price" })}</strong>{" "}
            </div>
            <div className="text-muted">
              {detailData &&
              detailData.price &&
              detailData.price?.length &&
              detailData.price[0]
                ? convertGBPtoPoundSymbol(
                    detailData.price[0].item_price.currency_code
                  ) + detailData.price[0].item_price.price
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}

        {detailData?.type === "charge" ? (
          ""
        ) : (
          <>
            <div className="col-md-12 mb-5 d-flex">
              <h3 className="card-title align-items-start flex-column d-flex">
                {" "}
                <span className="font-weight-bolder text-dark">
                  {intl.formatMessage({ id: "Pricing" })}
                </span>
              </h3>
            </div>
            {pricingArray &&
              pricingArray?.length &&
              pricingArray.map((data, index) => (
                <div className="col-md-12 mb-5 " key={index}>
                  <div>
                    <strong>{data?.title}</strong>{" "}
                  </div>
                  <div className="text-muted row ">
                    <div className="col-3">
                      {data && data.currency_code
                        ? convertGBPtoPoundSymbol(data.currency_code)
                        : currency
                        ? convertGBPtoPoundSymbol(currency)
                        : ""}{" "}
                      {data && data.price ? data.price : "0.00"}
                      &nbsp;
                    </div>
                    <div
                      className={`col-4 svg-icon svg-icon-sm ${
                        data && data.disable ? "" : "svg-icon-primary"
                      } ml-3`}
                    >
                      <button
                        style={{
                          border: "transparent",
                          background: "transparent",
                        }}
                        disabled={data && data.disable}
                        onClick={() =>
                          setCanva({
                            type: true,
                            title: `${intl.formatMessage({
                              id: "Edit.Pricing",
                            })} ${data?.title}`,
                            data: data,
                            detailData: detailData,
                          })
                        }
                      >
                        <SVG
                          style={{ color: "orange" }}
                          title="edit"
                          className="h-22 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>

      {canvaDetail.type ? (
        <>
          <NewProductCanvas
            setCanva={setCanvaDetail}
            canva={canvaDetail}
            editValue={
              detailData.type === "plan"
                ? editPlan(detailData)
                : detailData.type === "addon"
                ? editAddon(detailData)
                : editCharge(detailData)
            }
            type="edit"
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setCanvaDetail({ type: false, data: {} })}
          ></div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductDetailCard;
