import * as Yup from "yup";
// eslint-disable-next-line no-useless-escape
// const phoneReg = /^\+[1-9]{1}[0-9]{3,14}$/;
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[123456789]\d{9}$/;

export const NewFranchiseSchema = (intl, yp) => {
  return Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "Franchise.Name.Required" })
    ),
    short_name: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Franchise.Short.Required" })),
    franchise_reference_id: Yup.string().required(
      intl.formatMessage({ id: "Franchise.ID.Required" })
    ),
    manager_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Manager.Name.Required" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: Yup.string()

      // .matches(phoneReg, intl.formatMessage({ id: "Country.Code.valid" }))
      // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    trading_name: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "Trader.Name.Required" })),
    vat_registration_no: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "VAT.Required" })),
    address_line_1: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9,-\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Address.Required" })),
    address_line_2: Yup.string().matches(
      /^[aA-zZ-Z0-9,-\s]+$/,
      intl.formatMessage({ id: "Only.alphabets.Number" })
    ),

    address_line_3: Yup.string().matches(
      /^[aA-zZ-Z0-9,-\s]+$/,
      intl.formatMessage({ id: "Only.alphabets.Number" })
    ),

    city: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9,.-\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required("City is Required"),
    postcode: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Post.Code.Required" })),
    country_id: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
    telephone_number: Yup.string()
      .matches(
        phoneRegExp,
        intl.formatMessage({ id: "Telephone.number.10 digit" })
      )
      .required(intl.formatMessage({ id: "Telephone.Number.Required" })),
    // additional_emails: Yup.string()
    //   .email(intl.formatMessage({ id: "Wrong.email.format" }))
    //   .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
    //   .max(50, intl.formatMessage({ id: "Maximum.50.symbols" })),
    // depot_notification_email: Yup.string().required(
    //   intl.formatMessage({ id: "Notification.Email.Required" })
    // ),
    stripe_sk: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Stripe.Secret.Required" })),
    stripe_pk: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Stripe.Public.Required" })),
    chargebee_key: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required("Chargebee Key is required"),
    chargebee_value: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required("Chargebee Value is required"),
    // qbo_id: Yup.string()
    //   .matches(
    //     /^[aA-zZ-Z0-9\s]+$/,
    //     intl.formatMessage({ id: "Only.alphabets.Number" })
    //   )
    //   .trim()
    //   .required(intl.formatMessage({ id: "Quick.Client.Required" })),
    // qbo_csk: Yup.string()
    //   .matches(
    //     /^[aA-zZ-Z0-9\s]+$/,
    //     intl.formatMessage({ id: "Only.alphabets.Number" })
    //   )
    //   .trim()
    //   .required(intl.formatMessage({ id: "Quick.Secret.Required" })),
    term_and_conditions: Yup.string().required(
      intl.formatMessage({ id: "Terms.Required" })
    ),
    summary_of_insurance: Yup.string().required(
      intl.formatMessage({ id: "Summary.Required" })
    ),
    cookie_policy: Yup.string().required(
      intl.formatMessage({ id: "Cookie.Required" })
    ),
    privacy_policy: Yup.string().required(
      intl.formatMessage({ id: "Privacy.Required" })
    ),
  });
};
export const Term = (intl, yp) => {
  return Yup.object().shape({
    term_and_conditions: Yup.string().required(
      intl.formatMessage({ id: "Terms.Required" })
    ),
  });
};
export const InsuranceSchema = (intl, yp) => {
  return Yup.object().shape({
    summary_of_insurance: Yup.string().required(
      intl.formatMessage({ id: "Insurance.Required" })
    ),
  });
};
export const Privacy = (intl, yp) => {
  return Yup.object().shape({
    privacy_policy: Yup.string().required(
      intl.formatMessage({ id: "Privacy.Required" })
    ),
  });
};
export const Cookie = (intl, yp) => {
  return Yup.object().shape({
    cookie_policy: Yup.string().required(
      intl.formatMessage({ id: "Cookie.Required" })
    ),
  });
};
export const Qbd = (intl, yp) => {
  return Yup.object().shape({
    qbo_id: Yup.string().required(
      intl.formatMessage({ id: "Quick.Client.Required" })
    ),
    qbo_csk: Yup.string().required(
      intl.formatMessage({ id: "Quick.Secret.Required" })
    ),
  });
};
export const EditFranchiseSchema = (intl, yp) => {
  return Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "Franchise.Name.Required" })
    ),
    short_name: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Franchise.Short.Required" })),
    franchise_reference_id: Yup.string().required(
      intl.formatMessage({ id: "Franchise.ID.Required" })
    ),
    manager_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required(intl.formatMessage({ id: "Manager.Name.Required" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Email.Required" })),
    phone: Yup.string()

      // .matches(phoneReg, intl.formatMessage({ id: "Country.Code.valid" }))
      // .min(12, intl.formatMessage({ id: "Country.Code.valid" }))
      .required(intl.formatMessage({ id: "Phone.Number.Required" })),
    trading_name: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required(intl.formatMessage({ id: "Trader.Name.Required" })),
    vat_registration_no: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "VAT.Required" })),
    address_line_1: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9,-\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required("Address is Required"),
    address_line_2: Yup.string().matches(
      /^[aA-zZ-Z0-9,-\s]+$/,
      intl.formatMessage({ id: "Only.alphabets.Number" })
    ),

    address_line_3: Yup.string().matches(
      /^[aA-zZ-Z0-9,-\s]+$/,
      intl.formatMessage({ id: "Only.alphabets.Number" })
    ),
    city: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9,.-\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required("City is Required"),
    postcode: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Post.Code.Required" })),
    country_id: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
    telephone_number: Yup.string()
      .matches(
        phoneRegExp,
        intl.formatMessage({ id: "Telephone.number.10 digit" })
      )
      .required(intl.formatMessage({ id: "Telephone.Number.Required" })),
    // additional_emails: Yup.string()
    //   .email(intl.formatMessage({ id: "Wrong.email.format" }))
    //   .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
    //   .max(50, intl.formatMessage({ id: "Maximum.50.symbols" })),
    // depot_notification_email: Yup.string().required(
    //   intl.formatMessage({ id: "Notification.Email.Required" })
    // ),
  });
};
export const VanSchema = (intl, yp) => {
  return Yup.object().shape({
    make: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required("Make is Required"),
    model: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required("Model is Required"),
    van_name: Yup.string()
      .matches(/^[aA-zZ\s]+$/, intl.formatMessage({ id: "Only.alphabets" }))
      .trim()
      .required("Van Name is Required"),
    registration: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required("Registration is Required"),
    no_of_pods: Yup.string()
      .matches(/^[0-9\s]+$/, "Only Numbers are allowed ")
      .trim()
      .required("Pods is Required"),
  });
};
export const StripeSchema = (intl, yp) => {
  return Yup.object().shape({
    stripe_sk: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9*\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Stripe.Secret.Required" })),
    stripe_pk: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "Stripe.Public.Required" })),
  });
};

export const ChargebeeSchema = (intl, yp) => {
  return Yup.object().shape({
    chargebee_key: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9*\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required("Chargebee Key Required"),
    chargebee_value: Yup.string()
      // .matches(
      //   /^[aA-zZ-Z0-9\s]+$/,
      //   intl.formatMessage({ id: "Only.alphabets.Number" })
      // )
      .trim()
      .required("Chargebee value is required"),
  });
};

export const AddDepotSchema = (intl, yp) => {
  return Yup.object().shape({
    depotName: Yup.string().required("Depot Name is Required"),
    depotShort: Yup.string().required("Depot Short is Required"),
    depotId: Yup.string().required("Depot Id is Required"),
    country: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
    // latitude: Yup.string().required("Latitude is Required"),
    // longitude: Yup.string().required("Longitude is Required"),
    managerName: Yup.string().required(
      intl.formatMessage({ id: "Manager.Name.Required" })
    ),
    managerEmail: Yup.string()
      .email(intl.formatMessage({ id: "Wrong.email.format" }))
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Email.Required" })),
    managerPhone: Yup.string().required("Phone Number is Required"),
    postCode: Yup.string().required("Post Code is Required"),
    depotAddress: Yup.string().required("Depot Address is Required"),
    city: Yup.string().required("City is Required"),
  });
};

export const FranchisePostCodeSchema = (intl, yp) => {
  return Yup.object().shape({
    postcode: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "This.Field.Required" })),
    // tempPostCode: Yup.string().required(intl.formatMessage({ id: "This.Field.Required" })),
  });
};
export const FranchiseSectorPostCodeSchema = (intl, yp) => {
  return Yup.object().shape({
    name: Yup.string()
      .matches(
        /^[aA-zZ-Z0-9\s]+$/,
        intl.formatMessage({ id: "Only.alphabets.Number" })
      )
      .trim()
      .required(intl.formatMessage({ id: "This.Field.Required" })),
    price: Yup.string().required(
      intl.formatMessage({ id: "This.Field.Required" })
    ),
  });
};

export const AutomateValidation = () => {
  return Yup.object().shape({
    price_increase_type: Yup.string().required('This field is required'),
    price_increase_value: Yup.string()
      .required('This field is required')
      .test(
        'is-non-negative',
        'Value cannot be negative or zero',
        (value) => value === '' || (parseFloat(value) > 0 && /^\d*\.?\d*$/.test(value))
      ),
  });
};