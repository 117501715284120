/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { findPostcodeFranchise } from "../../_redux/order/orderCrud";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { useDispatch } from "react-redux";
import { fetchOrderSummary } from "../../_redux/order/orderAction";
import { productArrayNewData, productArrayUpdated } from "../../orderUtils";
import { orderSlice } from "../../_redux/order/orderSlice";
import PackagingMaterialListModal from "../../order-modal/PackagingMaterialListModal";

export const PostcodeSearch = ({
  state,
  dispatchReducer,
  quantity,
  setProductArray,
  setParamData,
  paramData,
  cardDetail,
  handleChangeOrder,
}) => {
  const { actions } = orderSlice;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [postcode, setPostcode] = useState("");
  const [dialog, setDialog] = useState({ check: false });
  const handleFind = (e, id) => {
    if (e.id === id) {
      return e.qty;
    }
  };
  const handleGetAddress = () => {
    dispatch(actions.cardDetailReset());
    setSnack({ call: false, type: "", message: "" });
    findPostcodeFranchise(postcode)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(
          fetchOrderSummary(
            state?.deletedProduct,
            "9",
            1,
            "month",
            [],
            res.data.data?.franchise_id,
            postcode,
            handleAddProduct,
            "postcode"
          )
        );

        dispatchReducer({
          type: "currency",
          value: res.data.data?.currency_code,
        });
        dispatchReducer({
          type: "postcode",
          postcode: { postcode, franchise_id: res.data.data?.franchise_id },
        });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleAddProduct = (data, id) => {
    const arr = [...state?.productArray];

    let subTotal = 0;
    for (let items of data) {
      let qtys = state?.quantity?.find((e) => handleFind(e, items.item.id))
        ?.qty;
      const currencyIndex = items.price.findIndex(
        (e) => e?.item_price?.currency_code === "GBP"
      );
      let update = arr.some((e) => e.id === items.item.id);
      let updateIndex = arr.findIndex((e) => e.id === items.item.id);

      if (!update) {
        const data = productArrayNewData(items, qtys, currencyIndex, quantity);
        arr.push(data);

        subTotal = subTotal + Number(data.total === "N/A" ? 0 : data.total);
      } else if (update) {
        productArrayUpdated(arr, updateIndex, items, currencyIndex, state);
      }
    }

    setProductArray(arr);
    const arrPlan = arr.filter((e) => e.type === "plan");
    const arrAddon = arr.filter((e) => e.type === "addon");
    const arrCharge = arr.filter((e) => e.type === "charge");

    const planAddon = arrPlan.concat(arrAddon);
    const sortOrder = planAddon.concat(arrCharge);

    const arrProductId = [];
    sortOrder.forEach((e) => {
      arrProductId.push(e.id);
    });

    setParamData({ ...paramData, products_id: arrProductId });
    dispatchReducer({
      type: "productArray",
      productArray: sortOrder,
      check: "6",
    });
  };

  useEffect(() => {
    if (cardDetail.length && postcode !== "") {
      setPostcode("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail.length]);

  return (
    <div className="d-flex justify-content-between">
      <div className="form-groupB w-25 d-flex">
        {/* <input
          type="text"
          className={`form-control  `}
          placeholder={intl.formatMessage({
            id: "Post.Code",
          })}
          value={postcode}
          onChange={(e) => {
            const inputValue = e.target.value;
            const alphanumericValue = inputValue.replace(/\W/g, "");
            const formattedValue = alphanumericValue.replace(
              /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
              "$1 $2"
            );
            setPostcode(formattedValue);
          }}
        /> */}
        {postcode ? (
          <div className="mt-2">
            <span className="symbol-label ml-2">
              <span
                className="svg-icon svg-icon-lg svg-icon-primary"
                onClick={() => handleGetAddress()}
              >
                <SVG
                  className="h-25 align-self-end"
                  style={{ cursor: "pointer" }}
                  title=" "
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                ></SVG>
              </span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <button
          className="btn btn-secondary font-weight-bolder font-size-sm mr-1 "
          onClick={() => setDialog({ check: true })}
        >
          Packaging Material List
        </button>
      </div>

      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <PackagingMaterialListModal
        dialog={dialog}
        setDialog={setDialog}
        cardDetail={cardDetail}
        handleChangeOrder={handleChangeOrder}
        dispatchReducer={dispatchReducer}
        state={state}
      />
    </div>
  );
};
