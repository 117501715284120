import * as Yup from "yup";
export const NewProductSchema = Yup.object().shape({
  productName: Yup.string().required("Product Name is Required"),
  productId: Yup.string().required("Product Id is Required"),
  productCategory: Yup.string().required("Product Category is Required"),
  unitPrice: Yup.string().required("Unit Price is Required"),
  vat: Yup.string().required("VAT is Required"),
  recurring: Yup.string().required("Recurring is Required"),
  billingPeriod: Yup.string().required("Billing Period is Required"),
  priceIncrease: Yup.string().required("Price Increase is Required"),
  denominator: Yup.string().required("Denominator is Required"),
  value: Yup.string().required("Value is Required"),
  increaseAfter: Yup.string().required("Increase After is Required"),
  country: Yup.string().required("Country is Required"),
  description: Yup.string().required("Description is Required"),
});

export const PlanSchema = (intl) => {
  return Yup.object().shape({
    external_name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "External.required" })),
    name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Internal.required" })),
    // product_count: Yup.string().required("No. of Pods is required"),
    // description: Yup.string().required(
    //   intl.formatMessage({ id: "Description.Required" })
    // ),
    // id: Yup.string().required(intl.formatMessage({ id: "ID.required" })),
    country: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
  });
};
export const AddonSchema = (intl) => {
  return Yup.object().shape({
    external_name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "External.required" })),
    name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Internal.required" })),
    // description: Yup.string().required(
    //   intl.formatMessage({ id: "Description.Required" })
    // ),
    id: Yup.string().required(intl.formatMessage({ id: "ID.required" })),
    // unit: Yup.string().required(intl.formatMessage({ id: "Unit.required" })),
    country: Yup.string().required(
      intl.formatMessage({ id: "CountryRequired" })
    ),
  });
};
export const ChargeSchema = (intl) => {
  return Yup.object().shape({
    external_name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "External.required" })),
    name: Yup.string()
      .min(3, intl.formatMessage({ id: "Minimum.3.symbols" }))
      .max(50, intl.formatMessage({ id: "Maximum.50.symbols" }))
      .required(intl.formatMessage({ id: "Internal.required" })),
    // description: Yup.string().required(
    //   intl.formatMessage({ id: "Description.Required" })
    // ),
    id: Yup.string().required(intl.formatMessage({ id: "ID.required" })),
    pricing_model: Yup.string().required(
      intl.formatMessage({ id: "Pricing.required" })
    ),
    price: Yup.string().required(intl.formatMessage({ id: "Price.required" })),
    // price_description: Yup.string().required(
    //   intl.formatMessage({ id: "Price.description.required" })
    // ),
    // invoice_notes: Yup.string().required(
    //   intl.formatMessage({ id: "ID.required" })
    // ),
    currency_code: Yup.string().required(
      intl.formatMessage({ id: "GBP.required" })
    ),
  });
};

export const ProductPricingSchema = (intl) => {
  return Yup.object().shape({
    pricing_model: Yup.string().required("Pricing Model Name is required"),
    id: Yup.string().required("Price Id is required"),
    name: Yup.string().required("Internal Name is required"),
    external_name: Yup.string().required("External Name is required"),

    price: Yup.string()
      .matches(
        /^[0-9.-\s]+$/,
        intl.formatMessage({ id: "Only.Numbers.allowed" })
      )
      .required("Price is required"),
    // price_description: Yup.string().required("Description is required"),
    // invoice_notes: Yup.string().required("Invoice Notes is required"),
  });
};

export const ProductCategorySchema = Yup.object().shape({
  productName: Yup.string().required("Product Category Name is required"),
  country: Yup.string().required("Country is required"),
  // description: Yup.string().required("Description is required"),
  // id: Yup.string().required("ID is required"),
  status: Yup.boolean().required("status is required"),
});
