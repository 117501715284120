import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { EditOrderTableAction } from "./EditOrderTableAction";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { EditOrderTableButtons } from "./EditOrderTableButtons";
import BillingCycleEditOrderModal from "./Modal/BillingCycleEditOrderModal";
import DeleteIcon from "@material-ui/icons/Delete";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";
import { LoadingSpinner } from "../../../../../_metronic/_partials/controls/LoadingSpinner";
import { insuranceCoverList } from "../../_redux/order/orderCrud";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MenuItem, Select } from "@material-ui/core";
import ChangePlanModal from "./Modal/ChangePlanModal";

export const EditOrderList = ({ editOrderDetail, orderUIContext }) => {
  const [dialog, setDialog] = useState({ check: false });
  const [coverList, setCoverList] = useState(null);

  const handleUpdateProduct = (value, type, id, modal) => {
    // if (value === 0 || value === "") return;
    let updatedProductArray;

    if (modal === "tiered" || modal === "stairstep") {
      const product = orderUIContext.state?.productArray.find(
        (e) => e.id === id
      );
      const productAmount = product.chargeAmountList.find((e) =>
        Number(e.starting_unit) <= Number(value) && e?.ending_unit
          ? Number(e.ending_unit) >= Number(value)
          : true
      );

      updatedProductArray = orderUIContext.state.productArray.map(
        (productItem) => {
          if (productItem.id === id) {
            return {
              ...productItem,
              [type]: value,
              price: +productAmount?.price / 100 || 0,
              amount: +productAmount?.price / 100 || 0,
            };
          }
          return productItem;
        }
      );
    } else {
      updatedProductArray = orderUIContext.state.productArray.map(
        (productItem) => {
          if (productItem.id === id) {
            const newAmount =
              type === "price"
                ? Number(value) * Number(productItem.quantity)
                : Number(value) * Number(productItem.price);
            return {
              ...productItem,
              [type]: value,
              amount: newAmount,
            };
          }
          return productItem;
        }
      );
    }

    orderUIContext.handleAddProductEdit(updatedProductArray, "noCall");
  };

  const handleRemoveCoupon = (item, type, i) => {
    if (type === "coupon") {
      const updatedCoupons = orderUIContext.state.coupon.filter(
        (e, index) => e.id !== item?.id
      );
      orderUIContext.dispatchReducer({
        type: "SET_DATA",
        data: {
          ...orderUIContext.state,
          coupon: updatedCoupons,
          isBreakeDownApiCall: true,
        },
      });
    } else if (type === "manual") {
      if (item?.type === "old") {
        const arr = [...orderUIContext.state.manualDiscount];
        arr[i].operation_type = "remove";
        orderUIContext.dispatchReducer({
          type: "SET_DATA",
          data: {
            ...orderUIContext.state,
            manualDiscount: arr,
            isBreakeDownApiCall: true,
          },
        });
      } else {
        const updatedCoupons = orderUIContext.state.manualDiscount.filter(
          (e, index) => index !== i
        );
        orderUIContext.dispatchReducer({
          type: "SET_DATA",
          data: {
            ...orderUIContext.state,
            manualDiscount: updatedCoupons,
          },
        });
      }
    }
  };

  //api calls when length of coupons changes
  useEffect(() => {
    if (
      orderUIContext?.state?.coupon?.length > 0 &&
      orderUIContext?.state?.coupon.some((e) => e?.isRemove)
    ) {
      orderUIContext.handleBreakDownApiCall(orderUIContext?.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUIContext?.state?.coupon?.length]);
  // useEffect(() => {
  //   if()
  // }, [third])
  useEffect(() => {
    const pod = orderUIContext.state.productArray.find(
      (e) => e.type === "plan"
    );
 
    if (pod?.type && !coverList?.list) {
      insuranceCoverList(pod?.price).then((res) => setCoverList(res.data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUIContext.state.productArray?.length]);


  return (
    <div className="card-body pt-0">
      <div className="py-4">
        {" "}
        {orderUIContext?.state?.productArray &&
          orderUIContext?.state?.productArray.length === 0 && (
            <LoadingSpinner />
          )}
        <Table responsive className="summerytable customColor">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Items</th>

              <th style={{ width: "15%" }}>
                Price&nbsp;per&nbsp;Unit(
                {orderUIContext?.state?.currencyCode
                  ? convertGBPtoPoundSymbol(orderUIContext?.state?.currencyCode)
                  : ""}
                )
              </th>
              <th style={{ width: "15%" }}>Quantity</th>
              <th className="text-right w-25">Amount</th>
            </tr>
          </thead>
          <tbody>
            {orderUIContext?.state?.productArray?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex">
                      {item?.type === "addon"&&
                      item?.pricing_model === "flat_fee" ? (
                        <span className="mt-3">{item?.name}</span>
                      ) : (
                        item?.name
                      )}
                      {item?.type === "addon" &&
                      item?.pricing_model === "flat_fee"&& (item.id === "Container-Protect"|| item.id === "Store-Protect" || item.id === "Insurance-Cover" )  ? (
                        <div
                          style={{ width: "36%", marginBottom: "-10px" }}
                          className="ml-4 "
                        >
                          <Select
                            name="address"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control   `}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            value={item?.insurance_cover}
                            onChange={(e) =>
                              handleUpdateProduct(
                                e.target.value,
                                "insurance_cover",
                                item.id
                              )
                            }
                          >
                            {coverList?.list &&
                              Object.entries(coverList?.list).map(
                                ([keys, values], index) => {
                                  const number = keys ? Number(keys) : "";
                                  return (
                                    <MenuItem value={keys}>
                                      {orderUIContext?.state?.currencyCode
                                        ? convertGBPtoPoundSymbol(
                                            orderUIContext?.state?.currencyCode
                                          )
                                        : ""}
                                      {number.toLocaleString("en-IN")}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          {/* <input
                            type="text"
                            className="form-control"
                            value={item?.insurance_cover}
                            placeholder="Cover Cost"
                            onChange={(e) =>
                              handleUpdateProduct(
                                e.target.value,
                                "insurance_cover",
                                item.id
                              )
                            }
                          /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {item?.service_period_days
                      ? "On subscription creation"
                      : ""}
                    <br />
                    {item?.service_period_days
                      ? "Count " + item?.service_period_days
                      : ""}
                  </td>

                  <td className="">
                    {item?.pricing_model === "per_unit" ? (
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={item?.price}
                        onChange={(e) =>
                          handleUpdateProduct(e.target.value, "price", item.id)
                        }
                        onBlur={() =>
                          orderUIContext?.handleBreakDownApiCall(
                            orderUIContext?.state
                          )
                        }
                      />
                    ) : (
                      "—"
                    )}
                  </td>
                  <td>
                    {item?.pricing_model === "per_unit" ||
                    item?.pricing_model === "tiered" ||
                    item?.pricing_model === "stairstep" ? (
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={item?.quantity}
                        onChange={(e) =>
                          handleUpdateProduct(
                            e.target.value,
                            "quantity",
                            item.id,
                            item?.pricing_model
                          )
                        }
                        onBlur={() =>
                          orderUIContext?.handleBreakDownApiCall(
                            orderUIContext?.state
                          )
                        }
                      />
                    ) : (
                      "—"
                    )}
                  </td>
                  <td className="text-right">
                    {item?.pricing_model !== "per_unit" ? (
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={item?.price}
                        disabled={
                          item?.pricing_model === "tiered" ||
                          item?.pricing_model === "stairstep"
                        }
                        onChange={(e) =>
                          handleUpdateProduct(e.target.value, "price", item.id)
                        }
                        onBlur={() =>
                          orderUIContext?.handleBreakDownApiCall(
                            orderUIContext?.state
                          )
                        }
                      />
                    ) : (
                      convertGBPtoPoundSymbol(
                        orderUIContext?.state?.currencyCode
                      ) + Number(item?.amount).toFixed(2)
                    )}
                  </td>
                  <td>
                    <EditOrderTableAction
                      setDialog={setDialog}
                      subItem={item}
                      orderUIContext={orderUIContext}
                      editOrderDetail={editOrderDetail}
                    />
                  </td>
                </tr>
              );
            })}
          
            {orderUIContext?.state?.coupon &&
              orderUIContext?.state?.coupon.length > 0 &&
              orderUIContext?.state?.coupon.map((item, index) => (
                <tr style={{ backgroundColor: "#ecf9ee" }}>
                  <td>
                    <div className="mb-2  d-flex">
                      <LocalOfferIcon className="flipIconFont15 mt-1" />
                      <strong className="ml-2">{item?.name}</strong>
                    </div>
                    <div className="ml-8">
                      <p className="mb-1">{item?.id}</p>
                      <p className="mb-1">applied</p>
                      {/* <p className="mb-1">Applicable for 3 months on lineitems</p> */}
                    </div>
                  </td>

                  <td className="d-flex"></td>
                  <td></td>
                  <td className="text-right">
                    {" "}
                    {item?.type !== "percentage"
                      ? convertGBPtoPoundSymbol(
                          orderUIContext?.state?.currencyCode
                        ) + item?.amount || "0"
                      : item?.amount || "0"}
                    {item?.type === "percentage" ? "%" : ""}
                    {/* {item.amount} */}
                  </td>
                  <td>
                    {/* {item?.isRemove && ( */}
                    <DeleteIcon
                      className="flipIconFont15 mb-1"
                      onClick={() => handleRemoveCoupon(item, "coupon")}
                    />
                    {/* )} */}
                  </td>
                </tr>
              ))}
            {orderUIContext?.state?.manualDiscount &&
              orderUIContext?.state?.manualDiscount.length > 0 &&
              orderUIContext?.state?.manualDiscount.map((item, index) => {
                if (item?.operation_type === "remove") {
                  return true;
                }
                return (
                  <tr style={{ backgroundColor: "#ecf9ee" }} key={index}>
                    <td>
                      <div className="mb-2  d-flex">
                        <LocalOfferIcon className="flipIconFont15 mt-1" />
                        <strong className="ml-2">Manual&nbsp;Discount</strong>
                      </div>
                      <div className="ml-8">
                        <p className="mb-1">Manual&nbsp;Discount</p>
                        <p className="mb-1">applied</p>
                        {/* <p className="mb-1">Applicable for 3 months on lineitems</p> */}
                      </div>
                    </td>

                    <td className="d-flex"></td>
                    <td></td>
                    <td className="text-right">
                      {item.discount_type !== "percentage"
                        ? convertGBPtoPoundSymbol(
                            orderUIContext?.state?.currencyCode
                          ) + item.discount_amount || item.discount_percentage
                        : item.discount_amount || item.discount_percentage}
                      {item.discount_type === "percentage" ? "%" : ""}
                    </td>
                    <td>
                      {/* {item?.isRemove && ( */}
                      <DeleteIcon
                        className="flipIconFont15 mb-1 "
                        onClick={() =>
                          handleRemoveCoupon(item, "manual", index)
                        }
                      />
                      {/* )} */}
                    </td>
                  </tr>
                );
              })}

            <EditOrderTableButtons orderUIContext={orderUIContext} />
          </tbody>
        </Table>
      </div>
      {dialog?.check && (
        <BillingCycleEditOrderModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.plan && (
        <ChangePlanModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
    </div>
  );
};
