import React, { useEffect, useState } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchNearSite } from "../../_redux/order/orderAction";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { LoadingCustome } from "../../../../../_metronic/_partials/controls/LoadingCustome";
import { LoadingSpinner } from "../../../../../_metronic/_partials/controls/LoadingSpinner";
import { orderSlice } from "../../_redux/order/orderSlice";
import moment from "moment/moment";
const { actions } = orderSlice;
export const NearSiteModal = ({
  show,
  setShow,
  dispatchReducer,
  state,
  cardDetail,
}) => {
  const dispatch = useDispatch();
  const { siteData, siteLoading } = useSelector(
    (state) => ({
      siteData: state.order.siteData,
      siteLoading: state.order.siteLoading,
    }),
    shallowEqual
  );
  const handleRadioChange = (e) => {
    dispatchReducer({ type: "siteSelect", value: e });
    dispatch(
      actions.newCustomerFetched({
        id: e.franchise_id,
        chargebee_key: e?.franchise?.chargebee_key,
        stripe_pk: e?.franchise?.stripe_pk,
      })
    );
    dispatchReducer({type:"planReplaced", value:0})

    dispatchReducer({ type: "initialBilingCycle", value: false });
    // setShow(false);
  };
  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length &&
      cardDetail[0]?.contact?.franchise_id
    ) {
      const data = {
        franchise_id: cardDetail[0]?.contact?.franchise_id,
        containerSize: state?.containerListSelect,
        type: state?.containerList.find(
          (e) => e.id === state?.containerListSelect
        )?.type,
      };
      const address = cardDetail[0]?.address + cardDetail[0]?.postcode;
      dispatch(fetchNearSite(data, address));
    }
  }, []);
  return (
    <Modal
      size="xl"
      show={show.open}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Select Site
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {siteLoading && <LoadingSpinner />}
        {siteData && siteData.length > 0
          ? siteData.map((e) => {
            if(e.id == 5)return;
            return (
              <Card
                body
                className="mb-2 cardDemo"
                onClick={() => e.capacity && handleRadioChange(e)}
              >
                <div className="row marginSite">
                  <div className="col-9 row">
                    <div className="col-4 d-flex">
                      <label className="checkbox alignCu">
                        <input
                          type="checkbox"
                          name="Checkboxes4"
                          checked={
                            state?.siteSelect?.id === e.id ? true : false
                          }
                          onClick={() => e.capacity && handleRadioChange(e)}
                        />
                        <span className="mr-2"></span>
                      </label>
                      <span className="font-weight-bolder text-dark">
                        {e.name}
                      </span>
                    </div>
                    <div className="col-4">
                      Capacity:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {e.capacity || "-"}
                      </span>
                    </div>
                    <div className="col-4">
                      Franchise:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {e.franchise?.name}
                      </span>
                    </div>
                    <div className="col-12">Distance:&nbsp;{e.distance} Km</div>
                    <div className="col-12">
                      Available&nbsp;From:&nbsp;
                      {e.available_from
                        ? moment(e.available_from).format("DD-MM-yyyy")
                        : "-"}
                    </div>
                    <div className="col-12 mb-2">Address:&nbsp;{e.address}</div>
                  </div>
                  {/* <div className="col-6 row">
                   
                    
                  </div> */}
                  <div className="col-3">
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="newSwiper"
                    >
                      {e.images &&
                        e.images.map((value) => (
                          <SwiperSlide>
                            <img src={value.image} alt="hello" />
                          </SwiperSlide>
                        ))}

                      {/* <SwiperSlide>
                        <img
                          src={toAbsoluteUrl("/media/demos/demo2.png")}
                          alt="hello"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={toAbsoluteUrl("/media/demos/demo3.png")}
                          alt="hello"
                        />
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
              </Card>
            )})
          : ""}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => setShow(false)}
          disabled={!state?.siteSelect?.id}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
