/* eslint-disable eqeqeq */
import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CustomerDetailSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is Required"),
  lname: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone Number is Required"),
  postCode: Yup.string().required("Post Code is Required"),
  address: Yup.string().required("Address is Required"),
  city: Yup.string().required("City is Required"),
  franchise: Yup.string().required("Franchise is Required"),
  //   owner: Yup.string().required("Contact Owner is Required"),
});

export const orderSummarySchema = Yup.object().shape({
  offerCode: Yup.string().required("Offer Code is Required"),
});
export const PaymentPlanSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .required("Due Amount is Required"),
  comment: Yup.string().required("Description is Required"),
});
export const RefundSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .required("Amount is Required"),
  reason_code: Yup.string().required("Reason is Required"),
  // notes: Yup.string().required("Notes is Required"),
});
export const CreditSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .required("Amount is Required"),
  reason_code: Yup.string().required("Description is Required"),
});
export const PartSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.-\s]+$/, "Only Number allowed")
    .required("Amount is Required"),
  // payment_method: Yup.string().required("Payment method is Required"),
  // payment_date: Yup.string().required("Payment date is Required"),
  // comment: Yup.string().required("Payment date is Required"),
});
export const HoldSchema = Yup.object().shape({
  reason: Yup.string().required("Reason for hold is Required"),
});
export const CancelSubscriptionSchema = Yup.object().shape({
  cancel_reason: Yup.string().required("Cancel Reason for hold is Required"),
  // credit_option: Yup.string().required("Credit Option for hold is Required"),
  refundable_credits_handling: Yup.string().required(
    "Refundable Credits Handling for hold is Required"
  ),
});
export const NotesSchema = Yup.object().shape({
  notes: Yup.string().required("Notes is Required"),
});

export const AdhocSchema = Yup.object().shape({
  amount: Yup.string()
    .required("Amount is Required")
    .matches(/^[0-9.\s]+$/, "Only Number allowed"),
  // date_from: Yup.string().required("From Date is Required"),
  // date_to: Yup.string().required("To Date is Required"),
  description: Yup.string().required("Description is Required"),
  invoice_option: Yup.string().required("Invoicing Option is Required"),
});

export const SendQuoteSchema = Yup.object().shape({
  to: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is Required"),
  // task_type: Yup.string().required("This Field is Required"),
  task_type: Yup.string().required("Task type is Required"),
  task_name: Yup.string().required("Task Name is Required"),
  task_owner_id: Yup.string().required("Task Owner is Required"),
  task_description: Yup.string().required("Task Description is Required"),
  due_date: Yup.string().required("Due Date is Required"),
  priority: Yup.string().required("Priority is Required"),
  time: Yup.string().required("Time is Required"),
});
export const OrderPaymentSchema = Yup.object().shape({
  billing: Yup.string().required("Billing is Required"),
  street1: Yup.string().when("billing", {
    is: (value) => value === "manual",
    then: Yup.string()
      .trim()
      .required("Street1 is Required"),
    otherwise: Yup.string().nullable(),
  }),
  city: Yup.string().when("billing", {
    is: (value) => value === "manual",
    then: Yup.string()
      .trim()
      .required("City is Required"),
    otherwise: Yup.string().nullable(),
  }),
  postCode: Yup.string().when("billing", {
    is: (value) => value === "manual",
    then: Yup.string()
      .trim()
      .required("Postcode is Required"),
    otherwise: Yup.string().nullable(),
  }),
  firstName: Yup.string().required("Name is Required"),
});
export const OrderPaymentSchemaN = Yup.object().shape({
  firstName: Yup.string().required("firstName is Required"),
});

export const OrderCardSchema = (valid) => {
  return Yup.object().shape({
    cardNumber: Yup.string()
      .required("Card number is Required")
      .test(
        "test-number",
        "Card number is invalid",
        (value) => valid.number(value).isValid
      ),
    expires: Yup.string().required("This Field is Required"),
    expiresYear: Yup.string().required("This Field is Required"),
    cvv: Yup.string()
      .matches(/^[0-9.-\s]+$/, "Only Number allowed")
      .min(3, "Minimum 3 symbols")
      .max(4, "Maximum 4 symbols")
      .trim()
      .required("Cvv is Required"),
    nameCard: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only Alphabet allowed ")
      .required("This Field is Required"),
    address: Yup.string().required("Address is Required"),
    city: Yup.string()
      .matches(/^[aA-zZ.-\s]+$/, "Only Alphabet allowed ")
      .required("City is Required"),
    postCode: Yup.string()
      .matches(/^[aA-zZ-Z0-9\s]+$/, "Only Alphabet or Number allowed ")
      .required("Post Code is Required"),
  });
};

export const AddEditSchema = Yup.object().shape({
  notes: Yup.string().required("Notes is Required"),
  weight: Yup.string().required("This Field is Required"),
  pods: Yup.string().required("This Field is Required"),
});

export const AddDiscount = Yup.object().shape({
  discountType: Yup.string().required("Discount Type is Required"),
  enterDiscount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .trim()
    .required("Enter Discount is Required"),
  duration: Yup.string().required("Duration is Required"),
  limitedPeriod: Yup.string().when("duration", {
    is: (value) => value === "limited_period",
    then: Yup.string()
      .matches(/^[0-9.\s]+$/, "Only Number allowed")
      .trim()
      .min(1, "Minimum 1 symbols")
      .max(3, "Maximum 3 symbols")
      .required("Enter Discount is Required"),
    otherwise: Yup.string().nullable(),
  }),
});

export const EditNextBillingSchema = Yup.object().shape({
  billing_date: Yup.string().required("Start date is Required"),
  // comment: Yup.string().required("Comments is Required"),
});
export const OfflinePaymentSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .required("Amount is Required"),
  payment_method: Yup.string().required("Payment Method is Required"),
  payment_date: Yup.string().required("Payment date is Required"),
});
export const CreditNote = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .required("Amount is Required"),
  type: Yup.string().required("Credit Note is Required"),
  reason_code: Yup.string().required("Reson Code is Required"),
});
export const StorageSchema = Yup.object().shape({
  pod_data: Yup.array().of(
    Yup.object().shape({
      pod_number: Yup.string()
        .trim()
        .required("Pod Number is Required"),
      seal_number_one: Yup.string()
        .trim()
        .required("Seal Number One is Required"),
      seal_number_two: Yup.string()
        .trim()
        .required("Seal Number two is Required"),
    })
  ),
  // .required(intl.formatMessage({ id: "Email.Required" })),
});

export const DriverSchema = Yup.object().shape({
  driver_note: Yup.string().required("Driver note is Required"),
});

export const CollectionSchema = Yup.object().shape({
  depot_id: Yup.string().required("Depot is Required"),
  collection_postcode: Yup.string().when("is_collection_address", {
    is: (is_collection_address) => is_collection_address == 1,
    then: Yup.string()
      .trim()
      .required("Postcode is required"),
  }),
  collection_address_line: Yup.string().when("is_collection_address", {
    is: (is_collection_address) => is_collection_address == 1,
    then: Yup.string()
      .trim()
      .required("Address field is required"),
  }),
  collection_address_city: Yup.string().when("is_collection_address", {
    is: (is_collection_address) => is_collection_address == 1,
    then: Yup.string()
      .trim()
      .required("City field is required"),
  }),
});

export const ReturnSchema = Yup.object().shape({
  return_postcode: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string().required("Postcode field is required"),
  }),
  return_address_line: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string()
      .trim()
      .required("Address field is required"),
  }),
  return_address_city: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string()
      .trim()
      .required("City field is required"),
  }),
  // return_reason: Yup.string().required("Return reason is required"),
  // return_reason_description: Yup.string().required("Description is required"),
});
export const ReturnWizSchema = Yup.object().shape({
  return_postcode: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string().required("Postcode field is required"),
  }),
  return_address_line: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string()
      .trim()
      .required("Address field is required"),
  }),
  return_address_city: Yup.string().when("is_return_address", {
    is: (is_return_address) => is_return_address == 1,
    then: Yup.string()
      .trim()
      .required("City field is required"),
  }),
  return_reason: Yup.string().required("Return reason is required"),
  return_reason_description: Yup.string().trim().required("Description is required"),
});

export const BookingAccessModalSchema = Yup.object().shape({
  // amount: Yup.string()
  //   .matches(/^[0-9.\s]+$/, "Only Number allowed")
  //   .trim()
  //   .required("Quantity is Required"),
  price_id: Yup.string().required("Charge Type is required"),
});
export const BookingParModalSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .trim()
    .required("Amount is Required"),
  // price_id: Yup.string().required("Charge Type is required"),
});

export const CheckOutCardSchema = (valid) => {
  return Yup.object().shape({
    cardNumber: Yup.string()
      .required("Card number is Required")
      .test(
        "test-number",
        "Card number is invalid",
        (value) => valid.number(value).isValid
      ),
    expires: Yup.string().required("This Field is Required"),
    expiresYear: Yup.string().required("This Field is Required"),
    cvv: Yup.string()
      .matches(/^[0-9.-\s]+$/, "Only Number allowed")
      .min(3, "Minimum 3 symbols")
      .max(4, "Maximum 4 symbols")
      .trim()
      .required("Cvv is Required"),
    nameCard: Yup.string()
      .matches(/^[aA-zZ\s]+$/, "Only Alphabet allowed ")
      .required("This Field is Required"),
  });
};

export const CompetitorModalSchema = Yup.object().shape({
  amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .trim()
    .required("Amount is Required"),
  name: Yup.string()
    .trim()
    .required("Competitor Name is Required"),
});

export const editProductCharge = Yup.object().shape({
  billing: Yup.string().required("This Field is Required"),
  days: Yup.string().required("This Field is Required"),
});
export const AddDiscountEdit = Yup.object().shape({
  discount_type: Yup.string().required("Discount Type is Required"),
  discount_amount: Yup.string()
    .matches(/^[0-9.\s]+$/, "Only Number allowed")
    .trim()
    .required("Enter Discount is Required"),
  // duration_type: Yup.string().required("Duration is Required"),
  manual_discount_item_price_id: Yup.string().when("apply_on", {
    is: (value) => value === "specific_item_price",
    then: Yup.string().required("Discount Type is Required"),
    otherwise: Yup.string().nullable(),
  }),
});
