export const planValues = {
  type: "plan",
  external_name: "",
  name: "",
  country: "",
  description: "",
  enabled_in_portal: true,
  enabled_for_checkout: true,
  id: "",
  // product_count: "",
};

export const chargeValues = {
  type: "charge",
  external_name: "",
  name: "",
  description: "",
  id: "",
  enabled_in_portal: true,
  pricing_model: "",
  price: 0,
  price_description: "",
  show_description_in_invoices: "",
  show_description_in_quotes: "",
  invoice_notes: "",
  is_taxable: true,
  country: "",
  category_type: "",
};

export const addonValues = {
  type: "addon",
  external_name: "",
  name: "",
  country: "",
  description: "",
  id: "",
  unit: "",
  enabled_in_portal: true,
  currency_code: "",
};
