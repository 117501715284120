// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../InvoiceUIHelpers";

import React, { useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  getSelectRowChargeCr,
  getSelectRowChargeIn,
  getSelectRowChargePromo,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { fetchCreditInvoice, fetchInvoice } from "../_redux/InvoiceAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "../InvoiceUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { viewPdfCreditInvoice, viewPdfInvoice } from "../_redux/InvoiceCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";



const CreditTable = ({ show, setShow, permission }) => {
  const intl = useIntl();
  const InvoiceUIContext = useInvoiceUIContext();
  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  let history = useHistory();
  const TasksUIProps = useMemo(() => {
    return {
      ids: InvoiceUIContext?.ids,
      setIds: InvoiceUIContext?.setIds,
      queryParams: InvoiceUIContext?.queryParams,
      setQueryParams: InvoiceUIContext?.setQueryParams,
      openEditContactPage: InvoiceUIContext?.openEditContactPage,
      openDeleteContactDialog: InvoiceUIContext?.openDeleteContactDialog,
    };
  }, [InvoiceUIContext]);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.invoice.credit }),
    shallowEqual
  );

  const { entities, nextoffset, totalCount, loading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    // TasksUIProps.setIds([]);
    dispatch(fetchCreditInvoice(TasksUIProps.queryParams, TasksUIProps?.ids));
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);

  // useEffect(() => {
  //   TasksUIProps.setQueryParams(uiHelpers.initialFilter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const columns = [
    {
      dataField: "invoice",
      text: "Credit Note",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.id ? data.credit_note.id : "-"}
          </span>
        );
      },
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return (
    //       <span
    //         className={`label label-lg label-light-${e === "Paid"
    //           ? "success"
    //           : e === "Medium"
    //             ? "warning"
    //             : e === "Payment Due"
    //               ? "danger"
    //               : ""
    //           } label-inline`}
    //       >
    //         {e ? e : "-"}
    //       </span>
    //     );
    //   },

    // },

    {
      dataField: "info",
      text: "Customer Name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.billing_address ? data.credit_note.billing_address?.first_name + " " + data.credit_note.billing_address?.last_name : "-"}
            {data?.order_id && <span style={{ color: "#ff6601", textDecoration: "underline" }} onClick={() => {
              if (data.order_id) {

                history.push(`/order-details/${data.order_id}`);
              }
              // window.open(`contact-details/${data.id}`, "_blank");
            }}>
              <br />
              {data?.booking_reference}
            </span>}
            {data?.email && <>
              <br />
              {data?.email}
            </>}
            {data?.phone && <>
              <br />
              {data?.phone}
            </>}
          </span>
        );
      },
    },
    {
      dataField: "info",
      text: "Service",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.is_container == 1 ? "Container" : data?.is_container == "3" ? "Box" : "Pod"}
          </span>
        );
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        const type = data?.credit_note?.type || "-";

        // Capitalize the first letter
        const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

        return <span>{capitalizedType}</span>;
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.total ? (
              <>
                &pound;{Number((+data.credit_note.total / 100))?.toLocaleString()}
              </>
            ) : (
              "-"
            )}
          </span>
        );
      },

    },
    {
      dataField: "date",
      text: "Available Credit",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.amount_available ? (
              <>
                &pound;{Number((+data.credit_note.amount_available / 100)).toLocaleString()}
              </>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Created At",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.date ? moment(data.credit_note.date * 1000).format("DD-MM-YYYY") : "-"}
          </span>
        );
      },
    },
    {
      dataField: "payment_retry",
      text: "Reason code",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.credit_note && data.credit_note.create_reason_code ? data.credit_note.create_reason_code : "-"}
          </span>
        );
      },

    },
    // {
    //   // dataField: "created_at",
    //   text: "View",
    //   // sort: true,
    //   // sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return (
    //       <span
    //         onClick={() => {
    //           if (data.order_id) {

    //             history.push(`/order-details/${data.order_id}`);
    //           }
    //           // window.open(`contact-details/${data.id}`, "_blank");
    //         }}
    //       >
    //         {data.order_id ? <VisibilityIcon /> : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      // dataField: "created_at",
      text: "Action",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => {
              const datas = {
                franchise_id: InvoiceUIContext?.queryParams?.filter?.search_franchise,
                credit_note_id: data.credit_note.id

              }

              viewPdfCreditInvoice(datas).then((res) => {
                window.open(res.data?.data.download_url, '_blank');
              }).catch((error) => {

              })
            }}>
            <span className="svg-icon svg-icon-sm svg-icon-primary">
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Files/Download.svg"
                )}
              ></SVG>
            </span>

          </span>
        );
      },
    },

  ];
  // const [total, setTotal] = useState(5);

  // useEffect(() => {
  //   if (TasksUIProps.queryParams?.pageNumber) {
  //     setTotal(total + 5);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [TasksUIProps.queryParams.pageNumber]);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
    offset: nextoffset,
    ids: TasksUIProps?.ids,
    setIds: TasksUIProps.setIds,
  };
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     if (row?.stage === "Contact") {
  //       setDialog({ type: "contact", open: true, contact_id: row.contact_id });
  //       // history.push(`/contact-details/${row.contact_id}`);
  //     } else {
  //       setDialog({
  //         type: "deal",
  //         open: true,
  //         deal_id: row.deal_id,
  //         contact_id: row.contact_id,
  //         from_tasks: true,
  //       });
  //       // history.push(`/deal-view/${row.deal_id}`);
  //     }
  //   },
  // };
  console.log("TasksUIProps", TasksUIProps);
  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };
  console.log("entities", entities);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
              customePagination={true}
              loading={loading}
            >
              {show && (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  // rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  hover
                  keyField="invoice"
                  // selectRow={
                  //   getSelectRowChargeCr({
                  //     entities,
                  //     ids: TasksUIProps.ids,
                  //     setIds: TasksUIProps.setIds,
                  //   })

                  // }
                  // defaultSorted={uiHelpers.defaultSorted}
                  data={entities === null ? [] : entities}
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                ""
                // <NotAuthorized />
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default CreditTable;
