import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";

import { Formik } from "formik";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { isEqual } from "lodash";
import { promotions } from "../../../__mocks__/dummyData";
import { useIntl } from "react-intl";
import { usePromotionsUIContext } from "../PromotionsUIContext";
import { CustomeSearch } from "../../../../../../_metronic/_partials/controls/CustomeSearch";

const prepareFilter = (queryParams, values) => {
  const { searchText, deal, franchise, search_by, search_value } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.search_by = search_by !== "" ? search_by : "";
  filter.dealStage = deal !== "" ? deal : undefined;
  filter.franchise = franchise !== "" ? franchise : undefined;
  filter.search = searchText ? searchText : "";
  filter.search_value = search_value !== "" ? search_value : "";
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function PromotionsFilter() {
  const intl = useIntl();
  const promotionsUIContext = usePromotionsUIContext();

  const promotionsUIProps = useMemo(() => {
    return {
      setQueryParams: promotionsUIContext.setQueryParams,
      queryParams: promotionsUIContext.queryParams,
    };
  }, [promotionsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(promotionsUIProps.queryParams, values);

    if (!isEqual(newQueryParams, promotionsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      promotionsUIProps.setQueryParams(newQueryParams);
    }
  };
  const dummy = {
    search_by: "",

    searchText: "",
    search_value: "",
  };

  return (
    <>
      <Formik
        initialValues={{
          search_by: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          search_value: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row d-flex justify-content-between">
              <div className="col-md-8 col-lg-10 mb-5">
                <div className="row">
                  <div className="col-lg-4">
                    <FormControl fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className="form-control"
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        onChange={(e) => {
                          setFieldValue("search_by", e.target.value);
                          setFieldValue("search_value", "");

                          applyFilter(dummy);
                        }}
                        // value={""}
                        onBlur={handleBlur}
                        value={values.search_by}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "All.Promotions" })}
                        </MenuItem>
                        {promotions.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {values.search_by === "discountType" ? (
                    <div className="col-lg-4">
                      <FormControl fullWidth>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className="form-control"
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          onChange={(e) => {
                            setFieldValue("search_value", e.target.value);
                            handleSubmit();
                          }}
                          // value={""}
                          onBlur={handleBlur}
                          value={values.search_value}
                        >
                          <MenuItem value="">Discount Type</MenuItem>
                          <MenuItem value="percentage">Percentage</MenuItem>
                          <MenuItem value="fixed_amount">Fixed Amount</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : values.search_by === "status" ? (
                    <div className="col-lg-4">
                      <FormControl fullWidth>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          className="form-control"
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          onChange={(e) => {
                            setFieldValue("search_value", e.target.value);
                            handleSubmit();
                          }}
                          // value={""}
                          onBlur={handleBlur}
                          value={values.search_value}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="expired">Expired</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-4 col-lg-2 mb-5">
                <CustomeSearch
                  intl={intl}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleSubmit={handleSubmit}
                />
                {/* <div style={{ position: "relative" }}>
                  <input
                    style={{ paddingRight: "40px" }}
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder={intl.formatMessage({ id: "Search" })}
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      setTimeout(() => {
                        handleSubmit();
                      }, 1000);
                    }}
                  />
                  <i
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "15px",
                    }}
                    className="fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
