import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NewBreadCrumbs from "../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { NewPromotionsCanva } from "./promotions-canva/NewPromotions";
import SVG from "react-inlinesvg";
import { fetchPromoDetail } from "../../_redux/promotions/promotionsAction";
import { initIds } from "../../../../../_metronic/_partials/controls/RightPanel";
import moment from "moment";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../../utils/commonFunction";

const PromotionsDetailPage = (props) => {
  const init = ["kt_new_promotions_panel", "kt_link_franchise_panel"];
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    initIds(init);
  }, [init]);

  const { detailData, permission } = useSelector(
    (state) => ({
      detailData: state.promotions.detail,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchPromoDetail(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);

  return (
    <>
      <div>
        <NewBreadCrumbs title={intl.formatMessage({ id: "PROMOTIONS" })} />

        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-10">
              <div className="card card-custom card-stretch">
                <div className="card-body">
                  <div className="d-flex ">
                    <h3 className="card-title align-items-start flex-column d-flex">
                      {" "}
                      <span className="font-weight-bolder text-dark">
                        {detailData && detailData.name}
                      </span>
                      <span
                        className={`text-${
                          detailData &&
                          detailData.status &&
                          detailData.status === "active"
                            ? "success"
                            : "danger"
                        } font-weight-bold font-size-sm mt-3`}
                      >
                        {detailData &&
                        detailData.status &&
                        detailData.status === "active"
                          ? "Active"
                          : "Inactive"}
                      </span>
                    </h3>
                    {permission?.setting_promotion?.edit ? (
                      <span
                        className="svg-icon svg-icon-sm svg-icon-primary ml-3"
                        id="kt_new_promotions_panel_toggle"
                        style={{ cursor: "pointer" }}
                      >
                        <SVG
                          style={{ color: "orange" }}
                          title="edit"
                          className="h-22 align-self-end"
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Edit.svg"
                          )}
                        ></SVG>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Promotion.ID" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.id}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Offer.Description" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.invoice_name}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Offer.Type" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData &&
                      detailData.discount_type &&
                      detailData.discount_type === "percentage"
                        ? "Percentage"
                        : detailData.discount_type === "fixed_amount"
                        ? "Fixed Amount"
                        : "-"}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Value" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.discount_percentage
                        ? detailData.discount_percentage + "%"
                        : detailData && detailData.discount_amount
                        ? convertGBPtoPoundSymbol(detailData?.currency_code) +
                          detailData.discount_amount
                        : "-"}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Valid.From" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.created_at
                        ? moment(detailData.created_at * 1000).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "Valid.To" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {" "}
                      {detailData && detailData.valid_till
                        ? moment(detailData.valid_till * 1000).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </span>
                  </div>
                  <div className="mt-4">
                    <span className="font-weight-bolder ">
                      {intl.formatMessage({ id: "COUNTRY" })}
                    </span>
                    <br />
                    <span className="text-muted">
                      {detailData && detailData.country}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 mb-10">
              <div className="container card card-custom card-stretch">
                <h5 className="mt-4">
                  {intl.formatMessage({ id: "Metrics" })}
                </h5>
                <div className="row">
                  <div className="col-6">
                    <img
                      src={toAbsoluteUrl("/media/chart/bar.png")}
                      alt="img"
                    />
                  </div>
                  <div className="col-6">
                    {" "}
                    <img
                      src={toAbsoluteUrl("/media/chart/bar2.png")}
                      alt="img"
                    />
                  </div>
                  <div className="col-6">
                    {" "}
                    <img
                      src={toAbsoluteUrl("/media/chart/line.png")}
                      alt="img"
                    />
                  </div>
                  <div className="col-6">
                    {" "}
                    <img
                      src={toAbsoluteUrl("/media/chart/pie.png")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <NewPromotionsCanva
        type="edit"
        editPromoData={detailData.loading ? {} : detailData}
      />
    </>
  );
};

export default PromotionsDetailPage;
