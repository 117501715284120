import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchStats } from "../_redux/order/orderAction";
import RefreshIcon from "@material-ui/icons/Refresh";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  refreshIcon: {
    transition: "transform 0.3s linear",
    animation: "$rotate 1s linear infinite",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
export const SuccessfulOrder = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { statsData, role } = useSelector(
    (state) => ({
      statsData: state.order.statsData,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (role && role?.length && role[0] === "super-admin") {
      dispatch(fetchStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const handleRefreshClick = () => {
    dispatch(fetchStats());
    // setRotating(true);
  };
  const { width, height } = useWindowSize();
  return (
    <>
      <div className="text-center successful-order">
        <Confetti width={width} height={height} numberOfPieces={50} />
        <CheckCircleIcon />
        <h1>Congratulations!</h1>
        <p>You are all set. Well done!</p>
        <div className="d-flex justify-content-center mb-6">
          <button
            //   type="reset"
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            onClick={() => history.push("/new-order")}
          >
            New Order
          </button>
          <button
            type="submit"
            className="btn btn-primary font-weight-bolder font-size-sm "
            onClick={() =>
              history.push(`/order-details/${props?.match?.params?.id}`)
            }
          >
            View Order
          </button>
        </div>
      </div>
      {role && role?.length && role[0] === "super-admin" && (
        <div className="col-lg-12">
          <hr />
          <div className="row orderTableCard p-2" style={{ fontSize: "13px" }}>
            <div className="col-lg-2 col-md-6  d-flex  ">
              Collections Yesterday:&nbsp;
              <strong>{statsData?.collectionsYesterday || "-"}</strong>
            </div>
            <div className="col-lg-2 col-md-6  d-flex  ">
              Collections Today:&nbsp;
              <strong>{statsData?.collectionsToday || "-"}</strong>
            </div>

            <div className="col-lg-2 col-md-6  d-flex  ">
              Collection Last Month:&nbsp;
              <strong>{statsData?.collectionsPreviousMonth || "-"}</strong>
            </div>
            <div className="col-lg-2 col-md-6  d-flex  ">
              Collection This Month:&nbsp;
              <strong>{statsData?.collectionsThisMonth || "-"}</strong>
            </div>
            <div className="col-lg-4 col-md-12  d-flex    justify-content-between">
              <span>
                Time Elapsed Since Last Order (HH:MM):&nbsp;
                <strong>{statsData?.elapsedTime || "-"}</strong>
              </span>
              <div onClick={handleRefreshClick} style={{ cursor: "pointer" }}>
                <RefreshIcon
                  className={` ${statsData?.loading ? classes.refreshIcon : ""
                    }`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
