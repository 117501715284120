/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.scss"; // Standard version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";

import * as _redux from "./redux";

import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import store, { persistor } from "./redux/store";

import App from "./app/App";
import { ContactUIProvider } from "./app/modules/ContactDeals/ContactUIContext";
import { CustomerUIProvider } from "./app/modules/Customer/CustomerUIContext";
import { DealsUIProvider } from "./app/modules/ContactDeals/DealsUIContext";
import ErrorBoundary from "./app/pages/ErrorBoundary";
import { MetronicI18nProvider } from "./_metronic/i18n";
import { OrderUIProvider } from "./app/modules/Order/OrderUIContext";
import React from "react";
import ReactDOM from "react-dom";
import { TasksUIProvider } from "./app/modules/Tasks/TasksUIContext";
import axios from "axios";
import { QTasksUIProvider } from "./app/modules/Quotes/TasksUIContext";
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import { InvoiceUIProvider } from "./app/modules/Invoice/InvoiceUIContext";

if (typeof window !== 'undefined') {
  // posthog.init('phc_IMGhJUhQ01oC6PDWz24SCl0rvzpJWwWkhJ5DLy3jKbC', { session_recording: { maskAllInputs: false } })

  posthog.init('phc_S5J6QMgbgZ22KN12s7Uiqe9zHpjqm7zqYGbVczmH0RY', { session_recording: { maskAllInputs: false } })
}
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   debug: false,
//   dsn:
//     "https://0c7471ae78c9855297d1b46ecf1c9375@o4505742328397824.ingest.sentry.io/4505782733701120",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["https://vaultstaging.easystorage.com/"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ _redux.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

ReactDOM.render(
  <ErrorBoundary>
    <MetronicI18nProvider>
      <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
          <MetronicSplashScreenProvider>
            <ContactUIProvider>
              <DealsUIProvider>
                <CustomerUIProvider>
                  <OrderUIProvider>
                    <TasksUIProvider>
                      <InvoiceUIProvider>
                        <QTasksUIProvider>
                          <PostHogProvider client={posthog}>
                            <App
                              store={store}
                              persistor={persistor}
                              basename={PUBLIC_URL}
                            />
                          </PostHogProvider>
                        </QTasksUIProvider>
                      </InvoiceUIProvider>
                    </TasksUIProvider>
                  </OrderUIProvider>
                </CustomerUIProvider>
              </DealsUIProvider>
            </ContactUIProvider>
          </MetronicSplashScreenProvider>
        </MetronicSubheaderProvider>
      </MetronicLayoutProvider>
    </MetronicI18nProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
