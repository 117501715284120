/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
export function DistanceReturn({ className }) {
  return (
    <>
      <div className="mt-10">
        <div className="d-flex justify-content-between align-items-center pt-5 mb-4">
          <div className="text-center">
            <div>Customer</div>
            <div>
              <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary"
                  id="kt_sendquote_panel_toggle"
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
            <div>N1 1NN</div>
          </div>
          <div className="distance"></div>
          <div className="text-center">
            <div>Depot</div>
            <div>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-sm svg-icon-primary">
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
                  ></SVG>
                </span>
              </span>
            </div>
            <div>N2 2NN</div>
          </div>
        </div>

        <div className="mt10 mb-10">
          <img src="../media/users/map.png" alt="logo" className="border" />
        </div>

        <div className="row">
          <div className="col-md-6 mb-5">
            <strong>Distance:</strong> 12 Miles
          </div>
          <div className="col-md-6 mb-5">
            <strong>Duration:</strong> 50 minutes
          </div>
          <div className="col-md-6 mb-5">
            <strong>Extra Miles:</strong> 12 Miles
          </div>
          <div className="col-md-6 mb-5">
            <strong>Extra Miles Charges:</strong> £78.00
          </div>
        </div>
      </div>
    </>
  );
}
