import axios from "axios";
export const PROMOTIONS_URL = "api/promotions";
export const PROMOTIONS = "promotion";
export const EDIT_PROMOTIONS = "Test_Coupones_tes";
export const DELETE = "promotion/delete/promotion";

export function getAllPromotions() {
  return axios.get(PROMOTIONS_URL);
}
export function findPromotions(queryParams, nextoffset) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + PROMOTIONS}?page=${
      queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${
      queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${
      queryParams.filter.search
    }&search_by=${queryParams.filter.search_by}${
      queryParams.filter.search_value !== ""
        ? `&search_value=${queryParams.filter.search_value}`
        : ""
    }
    ${
      nextoffset && nextoffset.length > 1 && queryParams.pageNumber !== 1
        ? `&offset=${JSON.stringify(nextoffset[queryParams.pageNumber - 1])}`
        : ""
    }
    `
  );
}

export function createPromotion(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + PROMOTIONS, data);
}
export function promoDetail(id) {
  return axios.get(process.env.REACT_APP_SITE_URL + PROMOTIONS + `/${encodeURIComponent(id).replace(/%252B/g, '+').replace(/%2B/g, '+')}`);
}
export function editPromotion(data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + PROMOTIONS + `/${data.promotion_id}`,
    data
  );
}

export function deletePromotion(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE, data);
}
