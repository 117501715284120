/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Tab, Tabs } from "react-bootstrap";

import DistanceCollectionReturn from "../../../../_metronic/_partials/widgets/lists/DistanceCollectionReturn";
import { DistanceReturn } from "../../../../_metronic/_partials/widgets/lists/DistanceReturn";
import React from "react";

export function NewDistanceDuration() {
  return (
    <div
      id="kt_NewDistanceDuration_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <a
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_NewDistanceDuration_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </a>
        </div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content px-10 ">
        <div>
          <h5>Distance / Duration</h5>
        </div>
        <div
          className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
          id="kt_quick_panel_logs_kt_NewDistanceDuration_panel"
        >
          <div className="deals-tabs">
            <Tabs defaultActiveKey="collection" id="uncontrolled-tab-example">
              <Tab eventKey="collection" title="Collection">
                <DistanceCollectionReturn />
              </Tab>
              <Tab eventKey="return" title="Return">
                <DistanceReturn />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button className="btn btn-dark font-weight-bolder font-size-sm mr-3 ">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
