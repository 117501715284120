import axios from "axios";
export const Tasks_URL = "api/tasks";
export const TASKS = "subscription/invoice-list";
export const CREDIT = "subscription/credit-note-list"
export const COMPLETE = "subscription/view-in-pdf?";
export const DELETE = "/delete-tasks";
export const OWNERS = "/change-task-owner";
export const SEARCH = "/search-contact-deals";
export const CREATE_TASKS = "/add/tasks";
export const EXPORT_API = "subscription/export-invoices"
export const EXPORT_GET = "subscription/retrieve-export"

export function getAllProducts() {
  return axios.get(Tasks_URL);
}
export function findTasks(queryParams, nextoffset) {
  // Make an HTTP GET request to the tasks endpoint with query parameters
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + TASKS}?franchise_id=${queryParams.filter.search_franchise}&page=${queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search
    }&search_type=${queryParams.filter.search_type}&search_view=${queryParams.filter.search_view
    }&search_owner=${queryParams.filter.search_owner}&search_franchise=${queryParams.filter.search_franchise
    }&search_by=${queryParams.filter.invoice
    }
    &from_date=${queryParams.filter.from_date
    }
    &to_date=${queryParams.filter.to_date
    }
    &status=${queryParams.filter.status
    }
     ${nextoffset && nextoffset.length > 1 && queryParams.pageNumber !== 1
      ? `&offset=${JSON.stringify(nextoffset[queryParams.pageNumber - 1])}`
      : ""
    }
    `
  );
}
export function findCredit(queryParams, nextoffset) {
  // Make an HTTP GET request to the tasks endpoint with query parameters
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CREDIT}?franchise_id=${queryParams.filter.search_franchise}&page=${queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search
    }&search_type=${queryParams.filter.search_type}&search_view=${queryParams.filter.search_view
    }&search_owner=${queryParams.filter.search_owner}&search_franchise=${queryParams.filter.search_franchise
    }
    &search_by=${queryParams.filter.invoice
    }
     &from_date=${queryParams.filter.from_date
    }
    &to_date=${queryParams.filter.to_date
    }
     ${nextoffset && nextoffset.length > 1 && queryParams.pageNumber !== 1
      ? `&offset=${JSON.stringify(nextoffset[queryParams.pageNumber - 1])}`
      : ""
    }
    `
  );
}
export function viewPdfInvoice(data) {
  // Make an HTTP POST request to the tasks complete endpoint with data
  return axios.get(process.env.REACT_APP_SITE_URL + COMPLETE + `franchise_id=${data?.franchise_id}&invoice_id=${data?.invoice_id}`);
}
export function viewPdfCreditInvoice(data) {
  // Make an HTTP POST request to the tasks complete endpoint with data
  return axios.get(process.env.REACT_APP_SITE_URL + COMPLETE + `franchise_id=${data?.franchise_id}&credit_note_id=${data?.credit_note_id}`);
}
export function exportApi(data) {
  // Make an HTTP POST request to the tasks complete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + EXPORT_API, data);
}
export function viewGetInvoice(data) {
  // Make an HTTP POST request to the tasks complete endpoint with data
  return axios.get(process.env.REACT_APP_SITE_URL + EXPORT_GET + `?franchise_id=${data?.franchise_id}&id=${data?.id}`);
}
export function assignComplete(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + DELETE, data);
}
export function assignDelete(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + DELETE, data);
}
export function assignOwner(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(process.env.REACT_APP_SITE_URL + TASKS + OWNERS, data);
}
export function searchTaskApi(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.get(
    process.env.REACT_APP_SITE_URL + TASKS + SEARCH + `?search_contact=${data}`
  );
}
export function createTask(data) {
  // Make an HTTP POST request to the tasks delete endpoint with data
  return axios.post(
    process.env.REACT_APP_SITE_URL + TASKS + CREATE_TASKS,
    data
  );
}
