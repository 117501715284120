import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { useFormik } from "formik";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ type: "", message: "" });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().when("@", {
      is: (value) => value && value.includes("@"),
      then: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
      otherwise: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols"),
    }),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  function display(x) {
    const obj = {};
    for (let item of x) {
      const split = item.split("_");
      let key = "";
      for (let i = 0; i < split.length - 1; i++) {
        key += `${split[i]}_`;
      }

      obj[key.substring(0, key.length - 1)] = {
        ...obj[key.substring(0, key.length - 1)],
        [split[split.length - 1]]: true,
      };
    }
    return obj;
  }
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting, setErrors }) => {
      enableLoading();
      setTimeout(() => {
        setSuccess({
          type: "",
          message: "",
        });
        login(values.email, values.password)
          .then(async ({ data }) => {
            disableLoading();
            props.login(data.data.token);
            props.setUser(data.data);
            localStorage.setItem(
              "franchise",
              JSON.stringify(data.data.countryWiseFranchise)
            );
            const permission = await display(data.data.permissions);
            props.permission(permission);
          })
          .catch((error) => {
            if (
              error?.response?.data?.data?.error === "The user is inactive."
            ) {
              setSuccess({
                type: false,
                message: `Your account is inactive. Contact your administrator to activate it.`,
              });
            } else if (
              error?.response?.data?.data?.error ===
              "The user franchise is inactive."
            ) {
              setSuccess({
                type: false,
                message: `The user franchise is inactive.`,
              });
            } else {
              setSuccess({
                type: false,
                message:
                  error?.response?.data?.message ||
                  `The email address / password you entered did  not match our records `,
              });
              // setSuccess({
              //   type: false,
              //   message: `The email address / password you entered did  not match our records `,
              // });
            }

            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10">
        <img src="../media/logos/easystorage-small.png" alt="logo" />
      </div>
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h2">Sign in</h3>
        <p className="text-muted font-weight-bold">
          Enter your email and password to sign in
        </p>
      </div>
      {values.check === "pass"&&
      <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
          <div className="alert-text font-weight-bold">Your password has been changed successfully. Please login with new password.</div>
        </div>
}
      {/* end::Head */}
      {success.type === false && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{success.message}</div>
        </div>
      )}
      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <label className="font-weight-bold">Email/Username</label>
          <input
            placeholder="Email"
            type={formik.values.email.includes("@") ? "email" : "text"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="font-weight-bold">Password</label>
          <div className=" d-flex position-relative">
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6 `}
              name="password"
              {...formik.getFieldProps("password")}
            />

            <SVG
              style={{
                color: "orange",
                position: "absolute",
                right: "3%",
                top: "30%",
              }}
              title=" "
              className="h-22 align-self-end "
              src={toAbsoluteUrl(
                showPassword
                  ? "/media/svg/icons/General/Visible.svg"
                  : "/media/svg/icons/General/Hidden.svg"
              )}
              onClick={togglePasswordVisibility}
            ></SVG>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div>
          <div>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn org-btn font-weight-bold px-9 py-4 my-3 w-100`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div className="forgot">
            Forgot password?{" "}
            <Link
              to="/auth/forgot-password"
              className="text-primary text-hover-primary my-3 mr-2 "
              id="kt_login_forgot"
            >
              Click here
            </Link>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
