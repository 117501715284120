/* eslint-disable no-restricted-imports */
import * as actions from "../../../_redux/franchises/franchisesActions";

import {
  // AddDepot,
  AddSector,
  AddVan,
  CookiePolicy,
  FranchisePostcodes,
  FranchisesDetailsEdit,
  Insurance,
  PrivacyPolicy,
  QuickbookDetail,
  StripeDetail,
  TermsConditions,
} from "../franchise-canva/index";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChargeDetail } from "../franchise-canva/Chargebee";
import Depots from "../components/Depots";
import { Details } from "../components/Details";
import { DriversLoaders } from "../components/DriversLoaders";
import { Button, Dropdown } from "react-bootstrap";
import NewBreadCrumbs from "../../../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { Vans } from "../components/Vans";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { useIntl } from "react-intl";
import { WorkingDays } from "../components/WorkingDays";
// import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import AutomatePriceModal from "../franchise-canva/AutomatePriceModal";
import { Feeds } from "../../../../../../_metronic/_partials/widgets";
import { Site } from "../components/Site";
import { Container } from "../components/Container";
import { Class } from "../components/Class";
import { Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import StairStepModal from "../franchise-modal/StairStepModal";
import ChargePriceModal from "../franchise-modal/ChargePriceModal";
const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

export const FranchiseDetails = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const init = [
    "kt_franchisepostcodes_panel",
    "kt_stripedetail_panel",
    "kt_termscondition_panel",
    "kt_cookiepolicy_panel",
    "kt_quickbookdetail_panel",
    "kt_insurance_panel",
    "kt_privacypolicy_panel",
    "kt_chargedetail_panel",
  ];

  const [check, setCheck] = useState(false);
  const [openCanva, setOpenCanva] = useState({ fraPod: false });
  const [dialog, setDialog] = useState({ check: false });
  const [dialogStair, setDialogStair] = useState({ check: false });
  const [toggleCheck, setToggleCheck] = useState({ eco: true, pre: true, fc: true })
  const [chargeDailog, setChargeDialog] = useState({ open: false })


  const dispatch = useDispatch();
  const { detailData, permission, condition, timeline } = useSelector(
    (state) => ({
      detailData: state.franchise.detail,
      permission: state.auth.permission,
      condition: state.auth.user,
      timeline: state.franchise.timeline,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (check) initIds(init);
  }, [init, check]);
  useEffect(() => {
    dispatch(actions.fetchFranchisesDetail(props?.match?.params?.id));
    dispatch(actions.fetchTimeline(props?.match?.params?.id));
    // dispatch(actions.fetchFranchisesDriverLoader(props?.match?.params?.id));
    dispatch(actions.fetchWorkingDays(props?.match?.params?.id));
    dispatch(fetchActiveCountry());

    return () => {
      dispatch(actions.resetFranchiseDetail());
    };
  }, [dispatch, props.match.params.id]);
  // useEffect(() => {
  // if (detailData?.id) {
  // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, props.match.params.id]);

  return (
    <>
      {/* {!detailData?.id && <LoadingCustome />} */}
      <>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <NewBreadCrumbs
            title={intl.formatMessage({ id: "FRANCHISE.DETAILS" })}
          />
          <Dropdown
            className="dropdown-inline"
            drop="down"
            alignRight
            onClick={() => setCheck(check ? false : true)}
          >
            <Dropdown.Toggle
              id="dropdown-toggle-top2"
              variant="transparent"
              className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
              disabled={
                condition.roles &&
                  condition.roles?.length &&
                  condition.roles[0] === "super-admin"
                  ? false
                  : condition.roles &&
                    condition.roles?.length &&
                    condition.roles[0] === "agent" &&
                    condition.is_franchise_hangerlane === 1
                    ? false
                    : condition.roles &&
                      condition.roles?.length &&
                      condition.roles[0].name === "franchise-owner"
                      ? false
                      : true
              }
            >
              {intl.formatMessage({ id: "ACTION" })}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ul className="navi navi-hover">
                <li className="navi-item">
                  <span
                    // id="kt_franchisepostcodes_panel_toggle"
                    className="navi-link pointer"
                    onClick={() => setOpenCanva({ fraPod: true })}
                  >
                    <span className="navi-text">
                      {intl.formatMessage({ id: "Franchise.Postcodes" })}
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    id="kt_stripedetail_panel_toggle"
                  >
                    <span className="navi-text">
                      {intl.formatMessage({ id: "Stripe.Details" })}
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    id="kt_termscondition_panel_toggle"
                  >
                    <span className="navi-text">
                      {" "}
                      {/* {intl.formatMessage({ id: "Terms.&.Conditions" })} */}
                      {intl.formatMessage({ id: "CMS" })}
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    onClick={() => setDialog({ check: true })}
                  >
                    <span className="navi-text">
                      {" "}
                      Automated Price Increase
                    </span>
                  </span>
                </li>
                {/* <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    onClick={() => setChargeDialog({ open: true })}
                  >
                    <span className="navi-text">
                      {" "}
                      Collection Weekday
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    onClick={() => setChargeDialog({ open: true })}
                  >
                    <span className="navi-text">
                      {" "}
                      Collection Weekend
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    onClick={() => setChargeDialog({ open: true })}
                  >
                    <span className="navi-text">
                      {" "}
                      Return Weekday
                    </span>
                  </span>
                </li>
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    onClick={() => setChargeDialog({ check: true })}
                  >
                    <span className="navi-text">
                      {" "}
                      Return Weekend
                    </span>
                  </span>
                </li> */}
                {/* <li className="navi-item">
                <span
                  className="navi-link pointer"
                  id="kt_cookiepolicy_panel_toggle"
                >
                  <span className="navi-text">
                    {intl.formatMessage({ id: "Cookie.Policy" })}
                  </span>
                </span>
              </li> */}
                {/* <li className="navi-item">
                    <span
                      className="navi-link pointer"
                      id="kt_quickbookdetail_panel_toggle"
                    >
                      <span className="navi-text">
                        {intl.formatMessage({ id: "Quickbook.Details" })}
                      </span>
                    </span>
                  </li> */}
                {/* <li className="navi-item">
                <span
                  className="navi-link pointer"
                  id="kt_insurance_panel_toggle"
                >
                  <span className="navi-text">
                    {intl.formatMessage({ id: "Insurance" })}
                  </span>
                </span>
              </li> */}
                {/* <li className="navi-item">
                <span
                  className="navi-link pointer"
                  id="kt_privacypolicy_panel_toggle"
                >
                  <span className="navi-text">
                    {intl.formatMessage({ id: "Privacy.Policy" })}
                  </span>
                </span>
              </li> */}
                <li className="navi-item">
                  <span
                    className="navi-link pointer"
                    id="kt_chargedetail_panel_toggle"
                  >
                    <span className="navi-text">Chargebee</span>
                  </span>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="row mt-5">
          <div className="col-lg-12 col-xl-6">
            <Details detailData={detailData} permission={permission} />
            {/* <Feeds
            classNameSend="card-stretchs"
            data={timeline}
            type={"customer"}
            validation={"franchise"}
          /> */}

          </div>
          <div className="col-lg-12 col-xl-6">
            <WorkingDays />

            {/* <Metrics filterBtn={true} /> */}
          </div>
          <div className="col-lg-12 col-xl-12">
            <h3 className="card-title align-items-start flex-column d-flex">
              <span className="font-weight-bolder text-dark">Stairstep pricing breakdown </span>
            </h3>
            <div style={{ margin: "-2% 0 2% 0" }}>

              <span className="text-dark">Prices displayed below are without the 50% discount</span>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="card card-custom  gutter-b mb-10">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                      <h3 className="card-title p-0 m-0 ">
                        <span className="font-weight-bolder text-dark">
                          Economy
                        </span>
                        {/* <Switch
                          checked={toggleCheck?.eco}
                          onChange={() => setToggleCheck({...toggleCheck, eco:!toggleCheck?.eco})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                      </h3>
                      <div>
                        <Button variant="primary" size="sm" onClick={() => {
                          setDialogStair({ open: true, data: detailData?.plan_pricing_economy, plan_type: "Economy-POD" })
                        }} >
                          Add
                        </Button>
                      </div>
                    </div>
                    {toggleCheck?.eco &&
                      <div>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Pod (Units)</TableCell>
                                <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {detailData?.plan_pricing_economy?.length > 0 && detailData?.plan_pricing_economy.map((e) => {
                                return (
                                  <>
                                    <TableRow >
                                      <TableCell component="th" scope="row">
                                        {e?.tier == 7 ? "7+" : e?.tier}
                                      </TableCell>
                                      <TableCell align="right">&pound;{e?.price}</TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card card-custom  gutter-b mb-10">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                      <h3 className="card-title p-0 m-0 ">
                        <span className="font-weight-bolder text-dark">
                          Premium
                        </span>
                        {/* <Switch
                          checked={toggleCheck?.pre}
                          onChange={() => setToggleCheck({...toggleCheck, pre:!toggleCheck?.pre})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                      </h3>
                      <div>
                        <Button variant="primary" size="sm" onClick={() => {
                          setDialogStair({ open: true, data: detailData?.plan_pricing_premium, plan_type: "POD" })
                        }}  >
                          Add
                        </Button>
                      </div>
                    </div>
                    {toggleCheck?.pre &&
                      <div>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Pod (Units)</TableCell>
                                <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {detailData?.plan_pricing_premium?.length > 0 && detailData?.plan_pricing_premium.map((e) => {
                                return (
                                  <>
                                    <TableRow >
                                      <TableCell component="th" scope="row">
                                        {e?.tier == 7 ? "7+" : e?.tier}
                                      </TableCell>
                                      <TableCell align="right">&pound;{e?.price}</TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card card-custom  gutter-b mb-10">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                      <h3 className="card-title p-0 m-0 ">
                        <span className="font-weight-bolder text-dark">
                          First Class
                        </span>
                        {/* <Switch
                          checked={toggleCheck?.fc}
                          onChange={() => setToggleCheck({...toggleCheck, fc:!toggleCheck?.fc})}
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        /> */}
                      </h3>
                      <div>
                        <Button variant="primary" size="sm" onClick={() => {
                          setDialogStair({ open: true, data: detailData?.plan_pricing_first_class, plan_type: "First-Class" })
                        }} >
                          Add
                        </Button>
                      </div>
                    </div>
                    {toggleCheck?.fc &&
                      <div>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Pod (Units)</TableCell>
                                <TableCell align="right">Price per Unit (&pound;/mo)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {detailData?.plan_pricing_first_class?.length > 0 && detailData?.plan_pricing_first_class.map((e) => {
                                return (
                                  <>
                                    <TableRow >
                                      <TableCell component="th" scope="row">
                                        {e?.tier == 7 ? "7+" : e?.tier}
                                      </TableCell>
                                      <TableCell align="right">&pound;{e?.price}</TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {detailData?.plan_type && detailData?.plan_type.length > 0 && detailData?.plan_type.some((e) => e === "pod") &&
            <div className="col-lg-12 col-xl-12">
              <h3 className="card-title align-items-start flex-column d-flex">
                <span className="font-weight-bolder text-dark">POD</span>
              </h3>
              <div className="row">
                <div className="col-6">
                  <Vans permission={permission} franchData={detailData} />
                  <DriversLoaders
                    permission={permission}
                    franchData={detailData}
                  />
                </div>
                <div className="col-6">
                  <Depots franch={detailData} permission={permission} />
                </div>
              </div>
            </div>
          }

          {detailData?.plan_type && detailData?.plan_type.length > 0 && detailData?.plan_type.some((e) => e === "container") &&
            <div className="col-lg-12 col-xl-12 ">
              <h3 className="card-title align-items-start flex-column d-flex">
                <span className="font-weight-bolder text-dark">Container</span>
              </h3>
              <div className="row">
                <div className="col-8">
                  <Site franchData={detailData} permission={permission} fraId={props.match.params.id} />
                </div>
                <div className="col-4">
                  <Class franch={detailData} permission={permission} fraId={props.match.params.id} />
                </div>
                <div className="col-12">
                  <Container franch={detailData} permission={permission} fraId={props.match.params.id} />
                </div>
              </div>
            </div>
          }
          <div className="col-6">
            <Feeds
              classNameSend="card-stretchs"
              data={timeline}
              type={"customer"}
              validation={"franchise"}
            />
          </div>
        </div>

        <FranchisesDetailsEdit />
        <AddVan detailData={detailData} />

        {/* <AddDepot fData={detailData} /> */}
        {openCanva?.fraPod ? (
          <>
            <FranchisePostcodes
              detailData={detailData?.id}
              detail={detailData}
              setOpenCanva={setOpenCanva}
            />
            <div
              className="offcanvas-overlay"
              onClick={() => setOpenCanva({ fraPod: false })}
            ></div>
          </>
        ) : (
          ""
        )}
        <AddSector detailData={detailData?.id} />
        <StripeDetail detailData={detailData} />
        <QuickbookDetail detailData={detailData} />
        <TermsConditions detailData={detailData} />
        <CookiePolicy detailData={detailData} />
        <PrivacyPolicy detailData={detailData} />
        <Insurance detailData={detailData} />
        <ChargeDetail detailData={detailData} />
        <AutomatePriceModal
          dialog={dialog}
          setDialog={setDialog}
          franchiseId={detailData?.id}
        />
        {chargeDailog?.open && <ChargePriceModal dialog={chargeDailog} setDialog={setChargeDialog} fraId={detailData?.id} />}

        {dialogStair?.open &&
          <StairStepModal dialog={dialogStair} setDialog={setDialogStair} fraId={detailData?.id} />}
      </>
    </>
  );
};
