/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";

import ActiveDialogModal from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogModal";
import CountryForm from "../country-form/countryForm";
import { CountrySchema } from "../country-form/validateSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { addCountry } from "../../../_redux/country/countryCrud";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import countryApi from "../../../../../utils/countryApi.json";
import { fetchCountryList } from "../../../_redux/country/countryAction";
import { initialFilter } from "../CountryUIHelpers";
import languageByCountryApi from "../../../../../utils/languageByCountryApi.json";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function NewCountryCanva({ check }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  //country list json
  countryApi.sort((a, b) => (a.name.common > b.name.common ? 1 : -1));
  const [active, setActive] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [formData, setFormData] = useState();
  const [languageData, setLanguageData] = useState([]);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const initialValues = {
    name: "",
    short_name: "",
    language: "",
    currency: "",
    timezone: "",
    status: "0",
  };

  // Custom hook for handling form submission
  const handleSubmit = (values, { resetForm }) => {
    // Resets the snackbar state
    setSnack({ call: false, type: "", message: "" });
    // Calls the addCountry function and displays a success or error message in the snackbar
    addCountry(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchCountryList(initialFilter));
        resetForm();
        canvaCancel("kt_new_country_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues,
    validationSchema: CountrySchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  // Custom hook that handles the selection of a country
  const handleSelectCountry = async (e) => {
    let data = countryApi.find((element) => element.name.common === e);
    // Sets the form data and formik field values for the selected country
    setFormData(data);
    formik.setFieldValue("name", e);
    formik.setFieldValue("short_name", data.cca3, false);

    await formik.setFieldValue(
      "currency",
      data && data.currencies && data.currencies.length
        ? data.currencies[0].name
        : ""
    );
    if (data.timezones.length === 1) {
      formik.setFieldValue("timezone", data.timezones[0]);
    } else {
      await formik.setFieldValue("timezone", "");
    }
  };

  useEffect(() => {
    if (languageData.length === 1) {
      formik.setFieldValue(
        "language",
        languageData && languageData.length && languageData[0]
          ? languageData[0]
          : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageData]);

  useEffect(() => {
    if (formik?.values?.name) {
      const languageList = languageByCountryApi.find(
        (element) => element.country === formik?.values?.name
      );
      formik.setFieldValue("language", "");
      setLanguageData(languageList ? languageList.languages : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name]);

  useEffect(() => {
    // Event listener callback function that resets the form if the user clicks outside the form

    const checkIfClickedOutside = (e) => {
      if (e.target.className === "offcanvas-overlay") {
        formik.resetForm();
      }
    };

    document.addEventListener("click", checkIfClickedOutside, true);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="kt_new_country_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 ${
        check ? "offcanvas-on" : ""
      }`}
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_new_country_panel_close"
            onClick={() => formik.handleReset()}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </span>
        </div>
      </div>

      {/*end::Header*/}

      {/*begin::Content*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10">
          <div>
            <h5>{intl.formatMessage({ id: "New.Country" })}</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_new_country_panel"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              <CountryForm
                intl={intl}
                formik={formik}
                countryApi={countryApi}
                handleSelectCountry={handleSelectCountry}
                languageData={languageData}
                formData={formData}
                setDialog={setDialog}
              />
            </div>
          </div>
        </div>
        <ActiveDialogModal
          active={active}
          setActive={setActive}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
        />
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              // id="kt_new_country_panel_cancel"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => canvaCancel("kt_new_country_panel_close")}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
