import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
//import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
//import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
// import { Dropdown } from "react-bootstrap";

// import NotAuthorized from "../../../_partials/widgets/NotAuthorized";

export function Topbar() {
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      /*viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),*/
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      /*viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),*/
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);
  const intl = useIntl();
  const history = useHistory();
  const handleRedirect = (value) => {
    history.push(value);
  };
  return (
    <>
      <div style={{width:"60%"}}>
      {layoutProps.viewSearchDisplay && <SearchDropdown />}
      </div>
    <div className="topbar">
      
      <button
        style={{ height: "38.8px" }}
        className="btn btn-primary font-size-sm font-weight-bolder mt-1 mr-3"
        onClick={() => handleRedirect("/new-order")}
      >
        Create Order
      </button>
      {/* <Dropdown style={{ height: "38.8px" }} className="mt-1 mr-2">
        <Dropdown.Toggle
          variant="transparent"
          className="btn btn-primary font-size-sm font-weight-bolder dropdown-toggle "
          id="dropdown-toggle-top2"
        >
          Create
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleRedirect("/new-order")}>
            Collection
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleRedirect("/create-return")}>
            Return
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleRedirect("/create-access")}>
            Access
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      

      {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

      {permission?.setting?.view ? (
        <>
          <Link to="/setting-page">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  {intl.formatMessage({ id: "SETTINGS" })}
                </Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-lg mr-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    title=" "
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Settings-2.svg"
                    )}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </Link>
        </>
      ) : (
        ""
      )}

      {/* <LanguageSelectorDropdown /> */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
    </>
  );
}
