/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { CreditNotes } from "./CreditNotes";
import InvoiceActionModal from "../order-modal/InvoiceActionModal";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { OfflinePayment } from "./OfflinePayment";
import { Refund } from "./Refund";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { Table } from "react-bootstrap";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { downloadInvoice, getConditionStats } from "../_redux/order/orderCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { convertGBPtoPoundSymbol } from "../../../utils/commonFunction";
import moment from "moment";
import {
  fetchBillingCardDetail,
  fetchInvoiceList,
} from "../_redux/order/orderAction";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

export function OrderInvoice({ invoiceList }) {
  const { orderDetail } = useSelector(
    (state) => ({
      orderDetail: state.order.orderDetail,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const dispatch = useDispatch();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [canva, setCanva] = useState({ type: "", data: {}, check: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setCheck] = useState();

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCheck(index);
  };

  const handleClose = (type, data) => {
    setAnchorEl(null);

    if (type === "void") {
      setIsLoading(true);
      getConditionStats(data?.order_id, data?.invoice_id)
        .then((res) => {
          dispatch(
            fetchBillingCardDetail(
              orderDetail?.franchise_id,
              orderDetail?.subscription_id,
              "",
              orderDetail?.customer_chargebee_id,
              orderDetail?.id
            )
          );
          setTimeout(() => {
            dispatch(
              fetchInvoiceList(
                orderDetail?.franchise_id,
                orderDetail?.id,
                orderDetail?.subscription_id
              )
            );
          }, 2000);
          setIsLoading(false);
          setSnack({ call: true, type: "success", message: res.data.message });
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
      return;
    }
    setCanva({
      type: type,
      data: data,
      check: type !== "close" ? true : false,
    });
  };

  const handleDownloadInvoice = (franchise, invoice, type) => {
    setIsLoading(true);
    downloadInvoice(franchise, invoice, type)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });

        setIsLoading(false);
        window.open(res.data.data.download_url);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const handleCloseCanva = () => {
    canvaCancel("kt_OrderInvoice_panel_close");
  };

  return (
    <div
      id="kt_OrderInvoice_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {isLoading ? <LoadingCustome /> : ""}
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            onClick={() => handleCloseCanva()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_OrderInvoice_panel_close"></div>
      </div>
      {/*end::Header*/}
      <div className="offcanvas-content tableover px-10 ">
        <div>
          <div>
            <h5>{intl.formatMessage({ id: "Invoices" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_OrderInvoice_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y"
          >
            <div className="mt-10">
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "Invoice.No" })}</th>
                      <th>{intl.formatMessage({ id: "Type" })}</th>
                      <th>{intl.formatMessage({ id: "Amount" })}</th>
                      <th>{intl.formatMessage({ id: "Period" })}</th>
                      <th>{"Created At"}</th>
                      <th>{intl.formatMessage({ id: "Status" })}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList &&
                      invoiceList.length > 0 &&
                      [
                        ...new Map(
                          invoiceList.map((item) => [item["invoice_id"], item])
                        ).values(),
                      ].map((item, index) => {
                        const data =
                          item?.status.charAt(0).toUpperCase() +
                          item?.status.slice(1);
                        const status = data.replace("_", " ");
                        const splitData = item?.period.split(" ");
                        // const check = invoiceList.some((e) => e?.invoice_id);
                        return (
                          <tr key={index}>
                            <td>{item?.invoice_id || "-"}</td>
                            <td>{item?.invoice_type || "-"}</td>
                            <td>
                              {item?.currency
                                ? convertGBPtoPoundSymbol(item?.currency)
                                : null}

                              {item?.amount || "-"}
                            </td>
                            <td>
                              {splitData && splitData?.length > 0
                                ? splitData[0] + " - "
                                : "-"}
                              <br />
                              {splitData && splitData?.length > 1
                                ? splitData[2]
                                : ""}
                            </td>
                            <td>
                              {item?.created_at
                                ? moment(item?.created_at).format("DD-MM-yyyy")
                                : "-"}
                            </td>
                            <td> {status || null}</td>
                            <td>
                              <span
                                className="symbol-label ml-2 pointer"
                                onClick={() =>
                                  handleDownloadInvoice(
                                    item?.franchise_id,
                                    item?.invoice_id,
                                    item?.invoice_type
                                  )
                                }
                              >
                                <span className="svg-icon svg-icon-sm svg-icon-primary">
                                  <SVG
                                    title=" "
                                    className="h-25 align-self-end"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Files/Download.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </span>
                            </td>
                            {// item.invoice_type === "Refund" ||
                            item.invoice_type === "Credit Note" &&
                            item?.status !== "refund_due" ? null : (
                              <td>
                                <span
                                  className="symbol-label ml-2 pointer"
                                  onClick={(e) => handleClick(e, index)}
                                >
                                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                                    <SVG
                                      title=" "
                                      className="h-25 align-self-end"
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Other2.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </span>
                                <div>
                                  <div>
                                    {checked === index ? (
                                      <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={() => handleClose("close", "")}
                                      >
                                        <MenuItem
                                          onClick={() =>
                                            handleClose("refund", item)
                                          }
                                          // id="kt_Refund_panel_toggle"
                                        >
                                          {intl.formatMessage({ id: "Refund" })}
                                        </MenuItem>
                                        {item.invoice_type === "Credit Note" &&
                                        item?.status === "refund_due" ? (
                                          ""
                                        ) : (
                                          <MenuItem
                                            onClick={() =>
                                              handleClose("credit", item)
                                            }
                                          >
                                            {intl.formatMessage({
                                              id: "Credit.Note",
                                            })}
                                          </MenuItem>
                                        )}
                                        {item.invoice_type === "Credit Note" &&
                                        item?.status === "refund_due" ? (
                                          ""
                                        ) : (
                                          <MenuItem
                                            onClick={() =>
                                              handleClose("offline", item)
                                            }
                                          >
                                            {intl.formatMessage({
                                              id: "Offline",
                                            })}
                                          </MenuItem>
                                        )}
                                        {item.invoice_type === "Credit Note" &&
                                        item?.status === "refund_due" ? (
                                          ""
                                        ) : (
                                          <>
                                            {item?.status !== "paid" &&
                                            item?.status !== "voided" ? (
                                              <MenuItem
                                                onClick={() =>
                                                  handleClose("void", item)
                                                }
                                              >
                                                Mark as Void
                                              </MenuItem>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </Menu>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas-footer">
        <div className="offcanvas-content text-right ">
          <button
            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
            onClick={() => handleCloseCanva()}
          >
            {intl.formatMessage({ id: "CANCEL" })}
          </button>
        </div>
      </div>
      {snack.call ? <SnackBarTool {...snack} /> : ""}

      <InvoiceActionModal dialog={canva} setDialog={setCanva}>
        {canva.type === "refund" ? (
          <Refund
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : canva.type === "credit" ? (
          <CreditNotes
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : canva.type === "offline" ? (
          <OfflinePayment
            setCanva={setCanva}
            canva={canva}
            orderDetailData={orderDetail}
          />
        ) : (
          ""
        )}
      </InvoiceActionModal>
    </div>
  );
}
