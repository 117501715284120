/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Redirect, Switch } from "react-router-dom";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { ContentRoute } from "../../../../_metronic/layout";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Registration from "./Registration";
import ResetPassword from "./ResetPassword";
import CheckoutPage from "../../../pages/CheckoutPage";
// import ErrorsPage from "../../ErrorsExamples/ErrorsPage";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/password/reset/:id"
                  component={ResetPassword}
                />
                <ContentRoute path="/checkout" component={CheckoutPage} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
