/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
// import { fetchOrderSummary } from "../_redux/order/orderAction";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { CompetitorModalSchema } from "../order-form/validationSchema";
const CompetitorPriceModal = ({
  dialog,
  setDialog,
  dispatchReducer,
  state,
  handleCheck,
}) => {
  // const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const initialValues = {
    amount: "",
    name: "",
  };
  //   const { accessFeeList } = useSelector(
  //     (state) => ({
  //       accessFeeList: state.order.bookingAccessCharges,
  //     }),
  //     shallowEqual
  //   );

  // Call on mount of componet to get Booking
  //   useEffect(() => {
  //     if (orderDetail?.id) {
  //       dispatch(
  //         fetchBookingAccessCharges(orderDetail?.id, orderDetail?.franchise_id)
  //       );
  //     }
  //   }, [dispatch, orderDetail?.id]);

  const handleSubmit = (values) => {
    const dataPrice = {
      price: values.amount,
      check: state.competitorPrice?.check,
      name: values.name,
    };
    // dispatchReducer({ type: "competitorPrice", value: dataPrice });
    handleCheck("check", values.amount, dataPrice);
    setDialog({ check: false });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CompetitorModalSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    // if (formik.dirty && Object.keys(formik.touched).length !== 0) {
    //   setDialogDis(true);
    // } else {
    //   formik.resetForm();
    const data = {};
    dispatchReducer({ type: "competitorPrice", value: data });
    handleCheck("checked");
    setDialog({ check: false });
    // }
    // setSnack({ call: false, type: "", message: "" });
  };
  return (
    <Modal
      show={dialog.check}
      aria-labelledby="example-modal-sizes-title-lg"
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title id="example-modal-sizes-title-lg">
          Competitor Price
        </Modal.Title>
        <span
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          onClick={handleClose}
        >
          <i className="ki ki-close icon-sm text-muted"></i>
        </span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          {isLoading && <LoadingCustome />}
          <div className="col-12">
            <label>Please enter the competitor name</label>
            <div className="form-groupB">
              <input
                type="text"
                name="name"
                placeholder={"Competitor Name"}
                label="External Name"
                className={`form-control  `}
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value);
                }}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <label>Discounted Competitor Price/POD/Month</label>
            <div className="form-groupB">
              <input
                type="text"
                name="amount"
                placeholder={"Amount"}
                label="External Name"
                className={`form-control  `}
                value={formik.values.amount}
                onChange={(e) => {
                  formik.setFieldValue("amount", e.target.value);
                }}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.amount}</div>
                </div>
              ) : null}
              {(state?.productArray.length > 0 &&
                state?.productArray.find((e) => e.type === "plan")?.pod_25) <=
                +formik?.values?.amount && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    easyStorage is cheaper then the competitor hence can't
                    create order. Please review the Quote Details.
                  </div>
                </div>
              )}
              {+formik?.values?.amount <= 0 && formik?.values?.amount !== "" && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    value can't be negative or null
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => handleClose()}
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={
                isLoading ||
                (state?.productArray.length > 0 &&
                  state?.productArray.find((e) => e.type === "plan")?.pod_25) <=
                  +formik?.values?.amount ||
                +formik?.values?.amount <= 0 ||
                formik?.values?.amount === ""
              }
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      {/* <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        setCanva={setDialog}
      /> */}
    </Modal>
  );
};

export default CompetitorPriceModal;
