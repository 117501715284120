import React from "react";
import NewBreadCrumbs from "../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { EditOrderList } from "./components/EditOrderComponents/EditOrderList";
import { EditBreakDownCard } from "./components/EditOrderComponents/EditBreakDownCard";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchEditOrderDetailCard } from "./_redux/order/orderAction";
import { useEffect } from "react";
import { ApplyChangesCard } from "./components/EditOrderComponents/ApplyChangesCard";
import { useOrderUIContext } from "./OrderUIContext";
import { getPruductObject } from "./EditOrderUtils";
import { useState } from "react";
import UpdateSubscriptionModal from "./components/EditOrderComponents/Modal/UpdateSubscriptionModal";
import { findOfferCode, getProduct } from "./_redux/order/orderCrud";
// import { useOrderUIContext } from "../../OrderUIContext";
import { useHistory } from "react-router-dom";
// import { LoadingCustome } from "../../../_metronic/_partials/controls/LoadingCustome";

export const OrderEditPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialog, setDialog] = useState({ update: false });
  const orderUIContext = useOrderUIContext();
  const { editOrderDetail } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(fetchEditOrderDetailCard(props?.match?.params?.id));
  }, [dispatch, props.match.params.id]);

  const handleAddDefaultProduct = async (product) => {
    // const subArray = product?.map((item) => {
    //   const data = getPruductObject(item);
    //   if (
    //     data.pricing_model === "tiered" ||
    //     data.pricing_model === "stairstep"
    //   ) {
    //     const data = {
    //       franchise_id: editOrderDetail?.franchise_id || "",
    //       item_id: props?.match?.params?.id || "",
    //     };
    //     getProduct(data).then((res) => {
    //       const item =
    //         res.data.data && res.data.data.length > 0 && res.data.data[0];

    //       data.chargeAmountList = item?.amount;
    //     });
    //   }

    //   if (data?.type === "addon") {
    //     data.billing_cycle = item?.billing_cycle || "";
    //   }
    //   return data;
    // });

    const subArray = await Promise.all(
      product.map(async (item) => {
        const data = getPruductObject(item);

        if (
          data.pricing_model === "tiered" ||
          data.pricing_model === "stairstep"
        ) {
          const productData = {
            franchise_id: editOrderDetail?.franchise_id || "",
            item_id: data?.id || "",
          };
          const response = await getProduct(productData);
          const item =
            response.data.data &&
            response.data.data.length > 0 &&
            response.data.data[0];
          data.chargeAmountList = item?.amount;
        }

        if (data?.type === "addon") {
          data.billing_cycle = item?.billing_cycle || "";
        }
        if (data?.type === "addon" && data?.pricing_model === "flat_fee") {
          data.insurance_cover = editOrderDetail?.insurance_cover;
        }

        return data;
      })
    );
    const orderDetailData = {
      order_id: editOrderDetail?.id || "",
      franchise_id: editOrderDetail?.franchise_id || "",
      subscription_id: editOrderDetail?.subscription_id || "",
      subscription_billing_period:
        editOrderDetail?.subscription_data?.billing_period || "",
      subscription_billing_period_unit:
        editOrderDetail?.subscription_data?.billing_period_unit || "",
      next_billing_at:
        editOrderDetail?.subscription_data?.next_billing_at || "",
      status: editOrderDetail?.subscription_data?.status || "",
      startDate: +editOrderDetail?.subscription_data?.start_date * 1000 || "",
    };
    const couponArr = await Promise.all(
      (editOrderDetail?.subscription_data?.coupons || []).map(async (item) => {
        try {
          const res = await findOfferCode(item?.coupon_id, editOrderDetail?.franchise_id, 1);
          const cop = res.data.data[0].coupon;
    
          return {
            amount: cop?.discount_percentage || cop?.discount_amount || "",
            name: cop?.name || "",
            type: cop?.discount_type || "",
            id: item?.coupon_id || "",
          };
        } catch (error) {
          // Handle errors here (e.g., log, throw, or provide default values)
          console.error("Error fetching offer code:", error);
          return {}; // or any default values you want to provide for the failed coupon.
        }
      })
    ); 
    // const filteredCouponArr = couponArr.filter(coupon => coupon.name || coupon.type || coupon.id);
    // console.log("couponArr", filteredCouponArr);

    // const couponArr = editOrderDetail?.subscription_data?.coupons?.map(
    //   (item, index) => {
    //     const data = {};
    //     findOfferCode(item?.coupon_id, editOrderDetail?.franchise_id, 1).then( 
    //       (res) => {
    //         const cop = res.data.data[index].coupon;
    //         data.amount =
    //           cop?.discount_percentage || cop?.discount_amount || "";
    //         data.name = cop?.name || "";
    //         data.type = cop?.discount_type || "";
    //         data.id = item?.coupon_id || "";
    //       }
    //     );
    //     return data;
    //   }
    // );

    const manualArr = editOrderDetail?.subscription_data?.discounts?.map(
      (item) => {
        const data = {
          discount_type: item?.type || "",
          discount_id: item?.id || "",
          apply_on: item?.apply_on || "",
          duration_type: item?.duration_type || "",
          manual_discount_period: item?.period || "",
          manual_discount_period_unit: item?.period_unit || "",
          manual_discount_item_price_id: item?.item_price_id || "",
          operation_type: "add",
          type: "old",
        };
        if (data?.discount_type === "percentage") {
          data.discount_amount = item?.percentage;
        } else {
          data.discount_amount = Number(item?.amount) / 100;
        }
        return data;
      }
    );

    orderUIContext.dispatchReducer({
      type: "SET_DATA",
      data: {
        ...orderUIContext?.state,
        orderData: orderDetailData,
        manualDiscount: manualArr ? manualArr : [],
        productArray: subArray,
        coupon: couponArr,
        currencyCode: "GBP",
      },
    });
    // orderUIContext.handleAddProductEdit(subArray);
  };

  useEffect(() => {
    // When editOrderDetail changes, update the product array in the state
    if (
      editOrderDetail &&
      editOrderDetail.subscription_data &&
      editOrderDetail?.subscription_data?.subscription_items
    ) {
      handleAddDefaultProduct(
        editOrderDetail?.subscription_data?.subscription_items
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrderDetail]);

  return (
    <div>
      {/* {editOrderDetail?.id ? "" : <LoadingCustome />} */}
      <NewBreadCrumbs breadcrumb={true} title={"Edit Order"} />
      <div className="row">
        <div className="col-md-12">
          <div className="text-right mb-2">
            <button
              type="button"
              class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
              onClick={() =>
                history.push(`/order-details/${props?.match?.params?.id}`)
              }
            >
              Dismiss
            </button>
            <button
              type="button"
              class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
              onClick={() => setDialog({ update: true })}
            >
              Update
            </button>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card card-custom gutter-b">
            <EditOrderList
              editOrderDetail={editOrderDetail}
              orderUIContext={orderUIContext}
            />
          </div>
        </div>
        <div className="col-md-4">
          {Object.keys(orderUIContext?.state?.breakdown).length > 0 && (
            <div className="card card-custom gutter-b">
              <EditBreakDownCard orderUIContext={orderUIContext} />
            </div>
          )}
        </div>
        {orderUIContext?.state?.orderData?.status === "active" && (
          <div className="col-md-8">
            <div className="card card-custom gutter-b">
              <ApplyChangesCard orderUIContext={orderUIContext} />
            </div>
          </div>
        )}
      </div>
      {dialog.update && (
        <UpdateSubscriptionModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
    </div>
  );
};
