/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useEffect, useState } from "react";
import {
  AddonSchema,
  ChargeSchema,
  PlanSchema,
} from "../product-form/validationSchema";
import {
  addonValues,
  chargeValues,
  planValues,
} from "../product-form/InitialValues";
import {
  createProduct,
  updateProduct,
} from "../../../_redux/products/productsCrud";
import {
  fetchProductDetail,
  fetchProducts,
} from "../../../_redux/products/productsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { NewAddonsCanvas } from "./NewAddons";
import { NewChargesCanvas } from "./NewCharges";
import { NewPlanCanvas } from "./NewPlan";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import currencyApi from "../../../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { initialFilter } from "../ProductsUIHelpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function NewProductCanvas({ canva, setCanva, editValue, type }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { detailData } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
    }),
    shallowEqual
  );

  const [dialogDis, setDialogDis] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setSnack({ call: false, type: "", message: "" });
    setLoading(true);
    if (type === "edit") {
      updateProduct(values, values.id)
        .then((res) => {
          setTimeout(() => {
            setCanva({ type: false });
          }, 2000);
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(fetchProductDetail(values.id));
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    } else {
      createProduct(values)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(fetchProducts(initialFilter));
          setTimeout(() => {
            setCanva({ type: false });
          }, 2000);
          setLoading(false);
          formik.resetForm();
          // canvaCancel("kt_plan_panel_close");
        })
        .catch((error) => {
          setLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error.response.data.message,
          });
        });
    }
  };

  const formik = useFormik({
    initialValues:
      type === "edit"
        ? editValue
        : canva?.data.type === "plan"
        ? planValues
        : canva?.data.type === "addon"
        ? addonValues
        : chargeValues,
    validationSchema:
      canva?.data.type === "plan"
        ? PlanSchema(intl)
        : canva?.data.type === "addon"
        ? AddonSchema(intl)
        : ChargeSchema(intl),
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    if (formik.dirty && Object.keys(formik.touched)?.length !== 0) {
      setDialogDis(true);
    } else {
      setCanva({ type: false });
      formik.resetForm();
    }
  };

  const handleCurrency = () => {
    const contryCurrency = detailData?.filter(
      (element) => element.id === Number(formik.values.country)
    );
    if (contryCurrency?.length) {
      const currencyCode = currencyApi.filter(
        (element) => element.name === contryCurrency[0]?.currency
      );
      if (currencyCode?.length) {
        formik.setFieldValue("currency_code", currencyCode[0]?.cc);
      } else {
        formik.setFieldValue("currency_code", "");
      }
    }
  };

  useEffect(() => {
    if (formik.values.country) {
      handleCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  useEffect(() => {
    dispatch(fetchActiveCountry({ type: "data_two" }));
  }, [dispatch]);
  return (
    <>
      <div
        id="kt_plan_panel"
        className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1  pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <button
              type="button"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </button>
            <div id="kt_plan_panel_close"></div>
          </div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}

        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="offcanvas-content px-10">
            <div>
              <h5>{canva?.data?.title}</h5>
            </div>
            <div className="tab-content">
              <div
                id="kt_quick_panel_logs_kt_plan_panel"
                role="tabpanel"
                className={`tab-pane fade pt-3 pr-5 mr-n5 scroll active show overflowCanva`}
              >
                {canva?.data?.type === "plan" ? (
                  <NewPlanCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) : canva?.data?.type === "addon" ? (
                  <NewAddonsCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) : canva?.data?.type === "charge" ? (
                  <NewChargesCanvas
                    formik={formik}
                    detailData={detailData}
                    type={type}
                  />
                ) : (
                  ""
                )}
              </div>
              {/*end::Content*/}
            </div>
          </div>

          <div className="offcanvas-footer">
            <div className="offcanvas-content text-right ">
              <button
                type="button"
                className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                onClick={() => handleClose()}
              >
                {intl.formatMessage({ id: "CANCEL" })}
              </button>
              <button
                className="btn btn-primary font-weight-bolder font-size-sm "
                type="submit"
              >
                {intl.formatMessage({ id: "SAVE" })}
                {loading && (
                  <span className="spinner spinner-white mr-4"></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_plan_panel_close"}
        setCanva={setCanva}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
