// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as actions from "../../../_redux/products/productsActions";
import * as uiHelpers from "../ProductsUIHelpers";

import {
  NoRecordsFoundMessage,
  getHandlerTableChange,
  getSelectRowCharge,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import React, { useEffect, useMemo } from "react";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../../../_metronic/_partials/widgets/NoRecord";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useProductsUIContext } from "../ProductsUIContext";

export function ProductsTable() {
  let history = useHistory();

  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDeleteProductDialog: productsUIContext.openDeleteProductDialog,
    };
  }, [productsUIContext]);

  const dispatch = useDispatch();
  const intl = useIntl();
  // Getting curret state of products list from store (Redux)
  const { currentState, permission } = useSelector(
    (state) => ({
      currentState: state.products.list,
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const { entities, nextoffset, totalCount, loading } = currentState;
  // Products Redux state
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    dispatch(
      actions.fetchProducts(productsUIProps.queryParams, productsUIProps.ids)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: intl.formatMessage({ id: "Product.ID" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data?.item?.id}</span>;
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Product.Name" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return <span>{data?.item?.name}</span>;
      },
    },

    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "Product.Type" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data?.item?.type === "charge"
              ? "Charge"
              : data?.item?.type === "plan"
              ? "Plan"
              : data?.item?.type === "addon"
              ? "Addon"
              : "-"}
          </span>
        );
      },
    },

    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }),
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            className={`label label-lg label-light-${
              data && data.item && data.item.status === "archived"
                ? "danger"
                : "success  "
            } label-inline`}
          >
            {data && data.item && data.item.status
              ? data.item.status === "active"
                ? "Active"
                : "Archive"
              : "-"}
          </span>
        );
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: productsUIProps.queryParams.pageSize,
    page: productsUIProps.queryParams.pageNumber,
    offset: nextoffset,
    ids: productsUIProps?.ids,
    setIds: productsUIProps.setIds,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`products/product-details/${row?.item.id}`);
    },
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
              customePagination={true}
              loading={loading}
            >
              <BootstrapTable
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="item"
                data={entities === null ? [] : entities}
                columns={columns}
                selectRow={
                  permission?.setting_product?.delete
                    ? getSelectRowCharge({
                        entities,
                        ids: productsUIProps.ids,
                        setIds: productsUIProps.setIds,
                      })
                    : selectRow
                }
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
              {(entities && entities?.length) || loading ? "" : <NoRecord />}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
