/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { Button, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogApi from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogApiCall";
import { EditVan } from "../franchise-canva";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  createFranchiseVanStatus,
  deleteSite,
} from "../../../_redux/franchises/franchisesCrud";
import {
  fetchFranchisesVan,
  fetchSiteList,
} from "../../../_redux/franchises/franchisesActions";
import { initIds } from "../../../../../../_metronic/_partials/controls/RightPanel";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import InactiveDialog from "../franchise-active-dialog/InactiveDialog";
import { CircularProgress, Switch } from "@material-ui/core";
import SiteModal from "../franchise-modal/SiteModal";
import ClassModal from "../franchise-modal/ClassModal";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialogApi from "../franchise-active-dialog/DeleteDialog";

export function Site({ permission, franchData, fraId }) {
  const intl = useIntl();
  // Use the dispatch hook to dispatch actions
  const dispatch = useDispatch();
  const init = ["kt_container_panel"];

  // Use state hook to manage snackbar state
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // Use state hook to manage dialog state
  const [dialog, setDialog] = useState({ check: false });
  const [dialogClass, setDialogClass] = useState(false);
  const [deleteSites, setDeleteSite] = useState({ modal: false });

  const [toggleCheck, setToggleCheck] = useState(false);
  useEffect(() => {
    if (dialog.check) initIds(init);
  }, [init, dialog]);

  // Use the useSelector hook to access the state
  const { siteList, siteLoading } = useSelector(
    (state) => ({
      siteList: state.franchise.siteList,
      siteLoading: state.franchise.siteLoading,
    }),
    shallowEqual
  );

  // Event handler for changing the van status
  const handleChange = () => {
    setSnack({ call: false, type: "", message: "" });

    const data = {
      id: dialog.data.id,
      status: dialog.data.e ? 1 : 0,
    };
    // Call the createFranchiseVanStatus function to update the van status
    createFranchiseVanStatus(data)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data?.statusChangeAllowed !== 1) {
            return;
          }
          // Dispatch an action to fetch updated van data
          dispatch(fetchFranchisesVan(dialog.data.franchise));
          setSnack({
            call: true,
            type: "success",
            message: res.data.message,
          });
        } else {
          setSnack({ call: true, type: "error", message: res.data.message });
        }
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });

    setDialog({ check: false });
  };
  // Render the JSX for the component

  useEffect(() => {
    if (toggleCheck) {
      dispatch(fetchSiteList(fraId));
    }
  }, [toggleCheck]);

  const handleDelete = (id) => {
    setSnack({ call: false, type: "", message: "" });
    deleteSite({ site_id: [id] })
      .then((res) => {
        
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });setDeleteSite({modal:false})
      })
      .catch((err) => {
        setSnack({ call: true, type: "error", message: err });
        setDeleteSite({modal:false})
      });
  };

  return (
    <>
      <div className="card card-custom  gutter-b mb-10">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center w-100 ">
            <h3 className="card-title p-0 m-0 ">
              <span className="font-weight-bolder text-dark">Site</span>
              <Switch
                checked={toggleCheck}
                onChange={() => setToggleCheck(!toggleCheck)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </h3>
            <div>
              {/* {permission?.setting_franchise?.create ||
              permission?.setting_franchise?.edit ? ( */}
              <Button
                variant="primary"
                size="sm"
                onClick={() => setDialog({ check: true })}
              >
                Add Site
              </Button>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
          {toggleCheck && (
            <>
              {siteList && siteList.length ? (
                <Table responsive className="mt-5">
                  <thead>
                    <tr>
                      <th>Site Name</th>
                      <th>Manager Name</th>
                      <th>Manager Email</th>
                      {/* <th>Manager Phone</th> */}
                      <th>Capacity</th>
                      <th>From Date</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {siteList
                      ? siteList.map((data, index) => (
                          <tr key={index}>
                            <td>{data.name}</td>
                            <td>{data.manager_name}</td>
                            <td>{data.manager_email}</td>
                            {/* <td>{data.manager_phone}</td> */}
                            <td>{data.capacity}</td>
                            <td>{data.available_from}</td>

                            <td className="d-flex">
                              <span
                                className="symbol-label  pointer"
                                onClick={() =>
                                  setDialog({ check: true, data: data })
                                }
                              >
                                <span className="svg-icon svg-icon-sm svg-icon-primary mr-1">
                                  <SVG
                                    className="h-25 align-self-end"
                                    title="Edit"
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Design/Edit.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </span>
                              <span
                                className="symbol-label  pointer"
                                onClick={() =>
                                  setDeleteSite({ modal: true, data: data?.id })
                                }
                              >
                                <DeleteIcon />
                              </span>
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() =>
                                  setDialogClass({ check: true, data: data })
                                }
                              >
                                Add Class
                              </Button>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              ) : (
                <>
                  {siteLoading ? (
                    <div className="text-center mt-8">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="mt-4" style={{ textAlign: "center" }}>
                      <p>{intl.formatMessage({ id: "No.Record.Found" })}</p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {dialog.check && (
        <>
          <SiteModal
            dialog={dialog}
            setDialog={setDialog}
            franchData={franchData}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setDialog({ check: false })}
          ></div>
        </>
      )}
      {dialogClass.check && (
        <ClassModal
          dialog={dialogClass}
          setDialog={setDialogClass}
          franchData={franchData}
          fraId={fraId}
        />
      )}
      {deleteSites.modal && (
        <DeleteDialogApi
          title={"site"}
          handleChange={handleDelete}
          dialog={deleteSites}
          setDialog={setDeleteSite}
          deleteSite={true}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
