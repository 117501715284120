/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialogModal from "../../../../../../_metronic/_partials/widgets/alert/ActiveDialogModal";
import { EditFranchiseSchema } from "../franchise-form/validateSchema";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import { editFranchise } from "../../../_redux/franchises/franchisesCrud";
// import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { fetchFranchisesDetail } from "../../../_redux/franchises/franchisesActions";
import { formikFranchiseEdit } from "../FranchisesUIHelpers";
import { getInputClasses } from "../../../../../utils/commonFunction";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import CountryPhoneNumber from "../../../../../../_metronic/layout/components/CountryPhoneNumber";

export function FranchisesDetailsEdit(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { detailData, countryData, role } = useSelector(
    (state) => ({
      detailData: state.franchise.detail,
      countryData: state.country.activeCountry,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );

  const [active, setActive] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const handleSubmit = (values, { resetForm }) => {
    setSnack({ call: false, type: "", message: "" });
    editFranchise(values, detailData.id)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        dispatch(fetchFranchisesDetail(detailData?.id));
        resetForm();
        canvaCancel("kt_franchisesdetailsedit_panel_close");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleReset = () => {};

  const formik = useFormik({
    initialValues: formikFranchiseEdit(detailData),
    enableReinitialize: true,
    validationSchema: EditFranchiseSchema(intl, Yup),
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  const handleCheck=(event)=>{
    const arr=[...formik.values.plan_type]
if(arr.some((e)=>e === event.target.name)){
  const index = arr.findIndex((e)=>e === event)
  arr.splice(index, 1)
}else{

  arr.push(event.target.name)
}

    formik.setFieldValue("plan_type",arr)
    
  }
  // useEffect(() => {
  //   dispatch(fetchActiveCountry());
  // }, [dispatch]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (e.target.className === "offcanvas-overlay") {
        formik.resetForm();
      }
    };

    document.addEventListener("click", checkIfClickedOutside, true);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="kt_franchisesdetailsedit_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-rightss"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_franchisesdetailsedit_panel_close"
            onClick={() => formik.resetForm()}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className=" px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "FRANCHISE.DETAILS" })}</h5>
          </div>
          <div
            id="kt_quick_panel_logs_kt_franchisesdetailsedit_panel"
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y"
          >
            <div className="mt-10 pb-10">
              <div className="mb-5">
                <span className="switch switch-outline switch-icon switch-primary">
                  {intl.formatMessage({ id: "ACTIVE" })}{" "}
                  <label className="ml-5">
                    <input
                      type="checkbox"
                      checked={formik.values.status === 1 ? true : false}
                      name="quick_panel_notifications_2"
                      onChange={() => setDialog(true)}
                    />
                    <span></span>
                  </label>
                </span>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "FRANCHISE.NAME" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "FRANCHISE.NAME" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "name"
                      )}`}
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "FRANCHISE.SHORT" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({
                        id: "FRANCHISE.SHORT",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "short_name"
                      )}`}
                      name="short_name"
                      {...formik.getFieldProps("short_name")}
                    />
                    {formik.touched.short_name && formik.errors.short_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.short_name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "FRANCHISE.ID" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "FRANCHISE.ID" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "franchise_reference_id"
                      )}`}
                      name="franchise_reference_id"
                      {...formik.getFieldProps("franchise_reference_id")}
                      disabled
                    />
                    {formik.touched.franchise_reference_id &&
                    formik.errors.franchise_reference_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.franchise_reference_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>

                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "MANAGER.NAME" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({ id: "MANAGER.NAME" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "manager_name"
                      )}`}
                      name="manager_name"
                      {...formik.getFieldProps("manager_name")}
                    />
                    {formik.touched.manager_name &&
                    formik.errors.manager_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Email" })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "Email" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "email"
                      )}`}
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "PHONE" })}{" "}
                    </Form.Label>
                    <CountryPhoneNumber
                      countryPhoneValue={formik?.values?.phone}
                      formik={formik}
                      fieldKey={"phone"}
                    />
                    {/* <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "PHONE" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "phone"
                      )}`}
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />
                    */}
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "TRADING.NAME" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"100"}
                      placeholder={intl.formatMessage({ id: "TRADING.NAME" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "trading_name"
                      )}`}
                      name="trading_name"
                      {...formik.getFieldProps("trading_name")}
                    />
                    {formik.touched.trading_name &&
                    formik.errors.trading_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.trading_name}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "VAT.REGISTRATION.NUMBER" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"30"}
                      placeholder={intl.formatMessage({
                        id: "VAT.REGISTRATION.NUMBER",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "vat_registration_no"
                      )}`}
                      name="vat_registration_no"
                      {...formik.getFieldProps("vat_registration_no")}
                    />
                    {formik.touched.vat_registration_no &&
                    formik.errors.vat_registration_no ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.vat_registration_no}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Office.Address.Line.1" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Office.Address.Line.1",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_1"
                      )}`}
                      name="address_line_1"
                      {...formik.getFieldProps("address_line_1")}
                    />
                    {formik.touched.address_line_1 &&
                    formik.errors.address_line_1 ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address_line_1}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Office.Address.Line.2" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Office.Address.Line.2",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_2"
                      )}`}
                      name="address_line_2"
                      {...formik.getFieldProps("address_line_2")}
                    />
                    {formik.touched.address_line_2 &&
                    formik.errors.address_line_2 ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address_line_2}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Office.Address.Line.3" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Office.Address.Line.3",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address_line_3"
                      )}`}
                      name="address_line_3"
                      {...formik.getFieldProps("address_line_3")}
                    />
                    {formik.touched.address_line_3 &&
                    formik.errors.address_line_3 ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address_line_3}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "CITY" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({ id: "CITY" })}
                      maxLength={"30"}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "city"
                      )}`}
                      name="city"
                      {...formik.getFieldProps("city")}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "POSTCODE" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={"10"}
                      placeholder={intl.formatMessage({ id: "POSTCODE" })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "postcode"
                      )}`}
                      name="postcode"
                      {...formik.getFieldProps("postcode")}
                    />
                    {formik.touched.postcode && formik.errors.postcode ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.postcode}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "COUNTRY" })}{" "}
                    </Form.Label>

                    <FormControl fullWidth>
                      <Select
                        name="country_id"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        className={`form-control  ${getInputClasses(
                          formik,
                          "country_id"
                        )}`}
                        IconComponent={(props) => (
                          <i {...props}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        {...formik.getFieldProps("country_id")}
                      >
                        <MenuItem value="">
                          {intl.formatMessage({ id: "COUNTRY" })}
                        </MenuItem>
                        {countryData &&
                          countryData.map((data, index) => (
                            <MenuItem value={data.id} key={index}>
                              {data.name}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              <span style={{ color: "lightgrey" }}>
                                {data.timezone}
                              </span>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.country_id}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Local.Website.Tel.Number" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Local.Website.Tel.Number",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "telephone_number"
                      )}`}
                      name="telephone_number"
                      {...formik.getFieldProps("telephone_number")}
                    />
                    {formik.touched.telephone_number &&
                    formik.errors.telephone_number ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.telephone_number}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-3 d-sm-none d-md-block"></div>
                <div className="col-md-12 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Additional.Email" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Additional.Email",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "additional_emails"
                      )}`}
                      name="additional_emails"
                      {...formik.getFieldProps("additional_emails")}
                    />
                    {formik.touched.additional_emails &&
                    formik.errors.additional_emails ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.additional_emails}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                {(role && role?.length && role[0]?.name === "franchise-owner") ? "":
                <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                  Select
                    </Form.Label>
                    <FormControl fullWidth>
                    <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.plan_type?.some((e)=>e === "pod")}
            onChange={handleCheck}
            name="pod"
            color="primary"
          />
        }
        label="POD"
      />
        <FormControlLabel
        control={
          <Checkbox
          checked={formik.values.plan_type?.some((e)=>e === "container")}
          onChange={handleCheck}
            name="container"
            color="primary"
          />
        }
        label="Container"
      />
        <FormControlLabel
        control={
          <Checkbox
          checked={formik.values.plan_type?.some((e)=>e === "removals")}
          onChange={handleCheck}
            name="removals"
            color="primary"
          />
        }
        label="Removals"
      />
                     
                    </FormControl>
                    {formik.touched.plan_type && formik.errors.plan_type ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.plan_type}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
}
                  <div className="col-md-6 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                    Allow 25% Discount on pod
                    </Form.Label>
                    <FormControl fullWidth>
                    <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.discount_after_renewal ==1?true:false}
            onChange={(e)=>formik.setFieldValue("discount_after_renewal",e.target.checked?1:"0")}
            name="discount_after_renewal"
            color="primary"
            disabled={detailData.id == 73}
          />
        }
        label="25%"
      />
      </FormControl>
      </Form.Group>
      <Form.Group className="mb-0">
                    <Form.Label>
                    Allow 50% Discount on container
                    </Form.Label>
                    <FormControl fullWidth>
                    <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.container_fifty_percent_discount ==1?true:false}
            onChange={(e)=>formik.setFieldValue("container_fifty_percent_discount",e.target.checked?1:"0")}
            name="container_fifty_percent_discount"
            color="primary"
          />
        }
        label="50%"
      />
      </FormControl>
      </Form.Group>
      </div>
                {/* <div className="col-md-12 mb-3">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      {intl.formatMessage({ id: "Depot.Notification.Emails" })}{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "Depot.Notification.Emails",
                      })}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "depot_notification_email"
                      )}`}
                      name="depot_notification_email"
                      {...formik.getFieldProps("depot_notification_email")}
                    />
                    {formik.touched.depot_notification_email &&
                    formik.errors.depot_notification_email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.depot_notification_email}
                        </div>
                      </div>
                    ) : null}
                  </Form.Group>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() =>
                canvaCancel("kt_franchisesdetailsedit_panel_close")
              }
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
            </button>
          </div>
        </div>
        <ActiveDialogModal
          active={active}
          setActive={setActive}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
        />
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
