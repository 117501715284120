/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  fetchNearSite,
  fetchOrderSummary,
  fetchSearchProduct,
} from "../../_redux/order/orderAction";
import {
  billingCycleCoupons,
  containerList,
  findAdHoc,
  findOfferCode,
} from "../../_redux/order/orderCrud";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CalcumateModal from "../modal/CalcumateModal";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ManualDiscount from "../../order-canva/ManualDiscount";
// import { OrderNotes } from "../../components/OrderNotes";
import { OrderPayment } from "../../order-canva/OrderPayment";
import { OrderSummaryButton } from "./OrderSummaryButton";
import { OrderSummaryTable } from "../../Table/OrderSummaryTable";
import { SendQuote } from "../../order-canva/SendQuote";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { TextField } from "@material-ui/core";
import { actions } from "../../../Auth/_redux/authRedux";
import { chargebee } from "../../_mocks/dummyData";
import {
  handleUpdateNewOrderQuote,
  // handleUpdateQtyPackaging,
} from "../../orderUtils";
import { orderSummarySchema } from "../../order-form/validationSchema";
import { useFormik } from "formik";
import {
  WeekCounter,
  convertGBPtoPoundSymbol,
  getInputClasses,
  insuranceCalculation,
} from "../../../../utils/commonFunction";
import { PostcodeSearch } from "./PostcodeSearch";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { id } from "date-fns/locale";
// import { LoadingCustome } from "../../../../../_metronic/_partials/controls/LoadingCustome";

export function OrderSummary({
  franchise,
  location,
  setCheckLocation,
  checkLocation,
  quoteData,
  dispatchReducer,
  state,
  setParamData,
  paramData,
  setBillingCall,
  billingCall,
  orderDataLoading,
}) {
  const intl = useIntl();

  const {
    searchProduct,
    orderSummaryData,
    cardDetail,
    storage,
    siteData,
  } = useSelector(
    (state) => ({
      searchProduct: state.order.searchProduct,
      orderSummaryData: state.order.orderSummary,
      cardDetail: state.order.cardDetail,
      storage: state.auth.storageData,
      siteData: state.order.siteData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [selectV, setSelectV] = useState(false);
  const [productValue, setProductValue] = useState("");
  const [openCanva, setOpenCanva] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [openCanvaQuote, setOpenCanvaQuote] = useState({
    type: "",
    open: false,
  });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const [productArray, setProductArray] = useState([]);
  const [applyCode, setApplyCode] = useState("");
  const [totalData, setTotalData] = useState({
    discount: [],
    discountValue: "",
  });

  const [disableAll, setDisableAll] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [secCheck, setSecCheck] = useState(false);
  const [howdenCheck, setHowdenCheck] = useState(false);
  const [planSelected, setPlanSelected] = useState("POD");
  const [siteCheck, setSiteCheck] = useState(false);

  useEffect(() => {
    if (cardDetail && cardDetail.length && cardDetail[0].id && checkLocation) {
      dispatchReducer({ type: "customReset", value: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail && cardDetail.length && cardDetail[0].id]);

  useEffect(() => {
    if (location?.search.includes("cm-qty")) {
      setCheckLocation(false);
      dispatchReducer({ type: "allSet", value: storage?.reducer });

      const arr = [...storage.paramData.products_id];
      const arry = [...storage.reducer.quantity];

      dispatchReducer({ type: "paramsData", value: arr });

      setParamData({
        unit: storage.paramData.unit,
        products_id: arr,
        period: storage.paramData.period,
      });
      let locationSplit = location?.search.split("&");
      const value = locationSplit[0].replace("?cm-qty=", "");
      // const pod = storage.reducer

      const Id = storage?.reducer?.productArray.find((e) => e.type === "plan")
        ?.id;
      const qtyId = arry.findIndex((e) => e.id === Id);
      if (qtyId !== -1) {
        arry[qtyId].qty = value;
      } else {
        const data = {
          qty: value,
          i: 0,
          id: Id,
        };
        arry.push(data);
      }
      setQuantity(storage.reducer.quantity);
      const data = {
        pod: value,
      };
      dispatchReducer({ type: "quantity", value: arry });

      dispatch(
        fetchOrderSummary(
          state?.deletedProduct,
          "3",
          storage.paramData.unit,
          storage.paramData.period,
          arr,
          storage.customerData[0].contact.franchise_id,
          storage.customerData[0].postcode,
          "",
          "",
          "",
          "",
          data
        )
      );
      // const arr = [...state];
    } else if (location?.search.includes("quote") && !secCheck) {
      setCheckLocation(false);
      const quoteDatas = handleUpdateNewOrderQuote(quoteData);
      dispatchReducer({
        type: "planReplaced",
        value: quoteDatas?.is_plan_replaced,
      });
      if (quoteDatas?.productArray?.length > 0) {
        setParamData({
          ...paramData,
          products_id: quoteDatas.params.products_id,
        });
        const podQty = quoteDatas?.productArray.find((e) => e?.type === "plan");
        const data = { pod: podQty?.qty };
        if (quoteDatas?.containerTypeSelect === "container") {
          dispatchReducer({ type: "deletedProduct", value: quoteDatas?.deletedProduct })
          return;
        }
        dispatch(
          fetchOrderSummary(
            state?.deletedProduct,
            "90",
            quoteDatas.params.unit,
            quoteDatas.params.period,
            quoteDatas.params.products_id,
            location.state?.franchise_id,
            (quoteData?.customer &&
              quoteData?.customer?.length &&
              quoteData?.customer[0]?.postcode) ||
            "",
            "",
            "",
            "",
            "1",
            data
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, quoteData]);

  useEffect(() => {
    if (
      location?.search.includes("quote") &&
      siteData &&
      state?.siteId &&
      !state.siteSelect?.id
    ) {
      const value = siteData?.find((e) => e.id == state?.siteId);

      dispatchReducer({ type: "siteSelect", value: value });
      const couRemove = [...state?.coupon_id];
      const filteredCoupon = couRemove.filter((e) => e.check !== "billing");
      dispatchReducer({ type: "coupon", value: filteredCoupon, check: "1" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchReducer,
    siteData,
    state?.siteSelect?.id,
    state.siteId,
    // state?.coupon_id,
  ]);

  //quote or draft data set
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location?.search.includes("quote") && !secCheck) {
      const data = handleUpdateNewOrderQuote(
        quoteData,
        dispatch,
        fetchNearSite
      );
      // console.log("data123", data);
      // if(data?.containerTypeSelect === "container"){
      containerList().then((res) => {
        dispatchReducer({ type: "containerList", value: res.data.data });
        const datas = {
          franchise_id:
            quoteData?.customer?.length > 0
              ? quoteData?.customer[0]?.contact?.franchise_id
              : "",
          containerSize: data?.containerListSelect,
          type: res.data.data.find((e) => e.id === data?.containerListSelect)
            ?.type,
        };
        const address =
          quoteData?.customer?.length > 0 &&
          quoteData?.customer[0]?.address + quoteData?.customer[0]?.postcode;
        dispatch(fetchNearSite(datas, address));
      });
      // }
      // await containerList().then((res) => {
      //   data.containerList = res.data.data;
      // });
      dispatchReducer({ type: "allSet", value: data });
      dispatchReducer({
        type: "productArray",
        productArray: data.productArray,
        check: "1",
      });
      dispatchReducer({
        type: "cover",
        value: data.cover,
      });

      setQuantity(data.quantity);
      if (quoteData?.coupons && state?.coupon_id.length > 0) {
        // const check = state?.coupon_id.some((e) => e.check === "billing");
        const difference = quoteData?.coupons.filter(
          (item2) => !state?.coupon_id.some((item1) => item1?.id === item2?.id)
        );

        if (difference && difference?.length > 0) {
          dispatchReducer({ type: "initialBilingCycle", value: true });
          // eslint-disable-next-line no-unused-expressions
          difference?.forEach((e) => {
            handleOfferCode(e?.name, location.state?.franchise_id);
          });
        }
        setSecCheck(true);
      } else if (
        quoteData?.is_container == 1 &&
        quoteData?.coupons.length > 0
      ) {
        setSecCheck(true);
        // eslint-disable-next-line no-unused-expressions
        // quoteData?.coupons.forEach((e)=>{
        //   handleOfferCode(e?.name, location.state?.franchise_id, "quote");
        // })
      }
    } else if (location?.search.includes("quote")) {
      // const arr = [...state?.coupon_id];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, quoteData, state.coupon_id?.length]);

  useEffect(() => {
    if (
      location?.search.includes("quote") &&
      quoteData?.is_container == 1 &&
      quoteData?.coupons.length > 0 &&
      state?.siteSelect?.id &&
      state?.classSiteList?.length > 0 &&
      !siteCheck
    ) {
      // eslint-disable-next-line no-unused-expressions
      quoteData?.coupons.forEach((e) => {
        handleOfferCode(e?.name, location.state?.franchise_id, "quote");
      });
      setSiteCheck(true);
    }
  }, [state?.siteSelect?.id, state?.classSiteList?.length]);

  useEffect(() => {
    if (
      location?.search.includes("cm-qty") &&
      !firstCheck &&
      state?.productArray.length > 0
    ) {
      let locationSplit = location?.search.split("&");
      const arr = [...state?.productArray];
      if (state?.productArray.some((e) => e.type === "plan")) {
        const index = state?.productArray.findIndex((e) => e.type === "plan");
        const value = locationSplit[0].replace("?cm-qty=", "");

        arr[index].qty = Number(value);
        arr[index].total = arr[index].productPrice * Number(value);
        const insuranceCost = insuranceCalculation(value);
        const coverList = state.productArray.find((e) => e.type === "addon");
        dispatchReducer({
          type: "cover",
          value: {
            cover: insuranceCost,
            price: coverList?.cover_list[insuranceCost] || 1,
          },
        });
        setFirstCheck(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, state.productArray, firstCheck]);

  const handleBilling = (value, competitorData) => {
    if (!competitorData) {
      dispatchReducer({
        type: "competitorPrice",
        value: { price: 0, check: false },
      });
    }
    dispatchReducer({ type: "manualDiscount", manualDiscount: {} });
    let data = {
      unit: value === "12" || value === "1w" ? 1 : value,
      period: value === "12" ? "year" : value === "1w" ? "week" : "month",
    };
    dispatchReducer({ type: "params", value: data });
    const arr = [...paramData.products_id];
    if (state?.competitorPrice?.check) {
      const index = arr.findIndex((e) => e === state?.adHoc?.id);
      arr.splice(index, 1);
      const dataSet = {
        ...data,
        products_id: arr,
      };
      dispatchReducer({ type: "adhocGbpDummy", value: 0 });
      dispatchReducer({
        type: "adhocChargeName",
        value: "",
      });

      setParamData((prevParamData) => ({ ...prevParamData, ...dataSet }));
    } else {
      setParamData((prevParamData) => ({ ...prevParamData, ...data }));
    }
    handleBillingCycleCoupons(data);
    const dataOrder = {
      collection_date:
        state.containerTypeSelect === "container"
          ? state?.collectionRetrun?.container_collection_date
          : state?.collectionRetrun?.collection_date || "",
      pod:
        (state?.productArray?.length > 0 &&
          state?.productArray.find((e) => e.type === "plan")?.qty) ||
        "1",
      return_date:
        state.containerTypeSelect === "container"
          ? state?.collectionRetrun?.container_return_date
          : state.collectionRetrun?.return_date &&
            state.collectionRetrun?.return_start_time_slot
            ? state.collectionRetrun?.return_date
            : "",
      return_postcode:
        state.collectionRetrun?.return_postcode &&
          state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_postcode
          : "",
      is_student: state.collectionRetrun?.is_student || 0,
      is_student_return: state.collectionRetrun?.is_student_return || 0,
      competitor_price:
        state.competitorPrice?.check && competitorData
          ? state.competitorPrice?.price
          : 0,
      isCompetitor: state.competitorPrice?.check,
      container_size: state.containerListSelect,
      site_id: state?.siteSelect?.id,
      is_plan_replaced: state?.is_plan_replaced,
      // container_plan:state.containerPlanListSelect
    };
    let delete_base_product = "0";
    if (
      state?.productArray.length > 0 ||
      (competitorData && paramData.products_id?.length > 0)
    ) {
      delete_base_product = "1";
    }
    const competitorCondition = competitorData ? competitorData : dataOrder;
    const competitorProductId = state?.competitorPrice?.check
      ? arr
      : paramData?.products_id;
    dispatch(
      fetchOrderSummary(
        state?.deletedProduct,
        "5",
        data.unit,
        data.period,
        competitorProductId,
        cardDetail && cardDetail.length
          ? franchise
          : state?.postcode?.franchise_id ?? "",
        cardDetail && cardDetail.length && cardDetail[0].postcode
          ? cardDetail[0].postcode
          : state?.postcode?.postcode ?? "",
        "",
        "",
        "",
        delete_base_product,
        competitorCondition
      )
    );
  };

  const handleBillingCycleCoupons = (billingCycle) => {
    if (state.productArray.some((e) => e.id === "Howdens")) return;
    setSnack({ call: false, type: "", message: "" });
    billingCycleCoupons({
      id:
        franchise || (cardDetail && cardDetail.length)
          ? franchise
          : state?.postcode?.franchise_id ?? "",
      cycle: billingCycle.unit + " " + billingCycle.period,
      period: billingCycle.period,
      is_container: state.containerTypeSelect === "container" ? 1 : 0,
    })
      .then((res) => {
        let arr = [...state?.coupon_id];
        const removedBilling = arr.filter((e) => e.check !== "billing");
        // console.log("arr-123", arr , removedBilling);
        arr.push(removedBilling);
        arr = removedBilling;
        for (let items of res.data.data) {
          if (items.coupon.discount_type === "percentage") {
            arr.push({
              type: "percent",
              id: items.coupon.id,
              value: items.coupon.discount_percentage,
              name: items.coupon.name,
              check: "billing",
            });
          } else {
            arr.push({
              type: "fixed",
              id: items.coupon.id,
              value: items.coupon.discount_amount,
              name: items.coupon.name,
              check: "billing",
            });
          }
        }
        // const indexManualCoupon = arr.findIndex((e) => e.name === "manual");

        // console.log("arr-123", indexManualCoupon, arr);
        // if (indexManualCoupon !== -1) {
        // arr.splice(indexManualCoupon, 1);

        // }
        dispatchReducer({ type: "coupon", value: arr, check: "2" });
        // setSnack({
        //   call: true,
        //   type: "success",
        //   message: res.data.message,
        // });
        setApplyCode("");
      })
      .catch((error) => {
        // setSnack({
        //   call: true,
        //   type: "error",
        //   message: error?.response?.data?.message,
        //   error: error?.response?.data?.errors,
        // });
      });
  };

  useEffect(() => {
    if (selectV) {
      dispatch(
        fetchSearchProduct(franchise, productValue, paramData, setSnack)
      );
    }
  }, [dispatch, franchise, productValue, selectV, paramData]);

  const initialValues = {
    offerCode: "",
  };

  const handleSubmit = (values, actions) => {
    actions.resetForm({ values: "" });
  };

  const handleReset = () => { };

  const formik = useFormik({
    initialValues,
    validationSchema: orderSummarySchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleOrderTableDelete = async (id, index, checkDelete) => {
    const arrDelete = [...state.deletedProduct];
    if (checkDelete === "delete") {
      arrDelete.push(id);
      dispatchReducer({
        type: "deletedProduct",
        value: arrDelete,
      });
    }
    await setSnack({ call: false, type: "", message: "" });
    if (
      id === "CongestionCharge" &&
      !paramData.products_id.some((e) => e === "CongestionCharge")
    ) {
      setSnack({
        call: true,
        type: "error",
        message: "CongestionCharge can't be deleted",
      });
    } else {
      const arr = [...paramData.products_id];

      const deleteI = arr.findIndex((e) => e === id);
      arr.splice(deleteI, 1);
      setParamData({ ...paramData, products_id: arr });
      const delete_base_product = "1";
      const pod =
        (state?.productArray.length > 0 &&
          state?.productArray.find((e) => e.type === "plan")?.qty) ||
        "";
      const data = {
        collection_date:
          state.containerTypeSelect === "container"
            ? state?.collectionRetrun?.container_collection_date
            : state?.collectionRetrun?.collection_date || "",
        pod: pod,
        return_date:
          state.containerTypeSelect === "container"
            ? state?.collectionRetrun?.container_return_date
            : state.collectionRetrun?.return_date &&
              state.collectionRetrun?.return_start_time_slot
              ? state.collectionRetrun?.return_date
              : "",
        return_postcode:
          state.collectionRetrun?.return_postcode &&
            state.collectionRetrun?.return_start_time_slot
            ? state.collectionRetrun?.return_postcode
            : "",
        is_student: state.collectionRetrun?.is_student || 0,
        is_student_return: state.collectionRetrun?.is_student_return || 0,
        competitor_price: state.competitorPrice?.check
          ? state.competitorPrice?.price
          : 0,
        isCompetitor: state.competitorPrice?.check,
        container_size: state.containerListSelect,
        site_id: state?.siteSelect?.id,
        is_plan_replaced: state?.is_plan_replaced,
        // container_plan:state.containerPlanListSelect
      };
      dispatch(
        fetchOrderSummary(
          checkDelete === "delete" ? arrDelete : state?.deletedProduct,
          "6",
          paramData.unit,
          paramData.period,
          arr,
          franchise || state?.postcode?.franchise_id,
          cardDetail && cardDetail.length && cardDetail[0].postcode
            ? cardDetail[0].postcode
            : state?.postcode?.postcode || "",
          "",
          "",
          "",
          delete_base_product,
          data
        )
      );

      const proArr = [...state?.productArray];
      const proIndex = proArr.findIndex((e) => e.id === id);

      proArr.splice(proIndex, 1);
      dispatchReducer({
        type: "productArray",
        productArray: proArr,
        check: "2",
      });

      setSnack({
        call: true,
        type: "success",
        message: "Product Deleted Successfuly",
      });
    }
  };

  const handleOrderTableQuantity = () => { };

  const handleChangeOrder = (q, packaging, dat) => {
    const arrDelete = [...state?.deletedProduct];
    if (state.deletedProduct.some((e) => e === q?.item?.id)) {
      const index = state.deletedProduct.findIndex((e) => e === q?.item?.id);
      arrDelete.splice(index, 1);
      dispatchReducer({
        type: "deletedProduct",
        value: arrDelete,
      });
    }

    if (
      state.productArray.some((e) => e.id === "Howdens") &&
      packaging !== "packaging"
    ) {
      if (q.item.type === "plan" && q?.item?.id !== "Howdens") {
        dispatchReducer({ type: "initialBilingCycle", value: false });
        setHowdenCheck(false);
      }
    }
    const arr = [...paramData.products_id];
    if (q?.item?.id || packaging === "packaging") {
      if (packaging !== "packaging") {
        const check = state.productArray.find((e) => e.type === "plan");
        const checkAddon = state.productArray.find((e) => e.type === "addon");
        const arrProduct = [...state.productArray];
        if (q.item.type === "plan" && check?.id) {
          dispatchReducer({
            type: "planReplaced",
            value: state.containerTypeSelect === "container" ? 1 : 0,
          });
          const index = arr.findIndex((e) => e === check.id);
          const proindex = arrProduct.findIndex((e) => e.type === "plan");
          arrProduct.splice(proindex, 1);
          arr.splice(index, 1);
          dispatchReducer({
            type: "productArray",
            productArray: arrProduct,
            check: "3",
          });
        }
        if (
          q.item.id === "Standard-Access" ||
          q.item.id === "24hr-Access" ||
          q.item.id === "Extended-Access"
        ) {
          const check = state.productArray.find(
            (e) =>
              e.id === "Standard-Access" ||
              e.id === "24hr-Access" ||
              e.id === "Extended-Access"
          );
          if (check?.id) {
            const index = arr.findIndex((e) => e === check.id);
            arrProduct.splice(index, 1);
            arr.splice(index, 1);
            dispatchReducer({
              type: "productArray",
              productArray: arrProduct,
              check: "3",
            });
          }
        }
        if (q.item.type === "addon" && checkAddon?.id) {
          dispatchReducer({
            type: "productArray",
            productArray: arrProduct,
            check: "4",
          });
        }
        arr.push(q?.item?.id);

        setParamData({ ...paramData, products_id: arr });
        const data = {
          collection_date:
            state.containerTypeSelect === "container"
              ? state?.collectionRetrun?.container_collection_date
              : state?.collectionRetrun?.collection_date || "",
          pod:
            (state?.productArray.length > 0 &&
              state?.productArray.find((e) => e.type === "plan")?.qty) ||
            "",
          return_date:
            state.containerTypeSelect === "container"
              ? state?.collectionRetrun?.container_return_date
              : state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_date
                : "",
          return_postcode:
            state.collectionRetrun?.return_postcode &&
              state.collectionRetrun?.return_start_time_slot
              ? state.collectionRetrun?.return_postcode
              : "",
          is_student: state.collectionRetrun?.is_student || 0,
          is_student_return: state.collectionRetrun?.is_student_return || 0,
          competitor_price: state.competitorPrice?.check
            ? state.competitorPrice?.price
            : 0,
          isCompetitor: state.competitorPrice?.check,
          container_size: state.containerListSelect,
          site_id: state?.siteSelect?.id,
          is_plan_replaced: q.item.type === "plan" ? 1 : 0,
          // container_plan:state.containerPlanListSelect
        };
        dispatch(
          fetchOrderSummary(
            state.deletedProduct?.some((e) => e === q?.item?.id)
              ? arrDelete
              : state?.deletedProduct,
            "71",
            paramData.unit,
            paramData.period,
            arr,
            franchise,
            cardDetail && cardDetail.length && cardDetail[0].postcode
              ? cardDetail[0].postcode
              : "",
            "",
            "",
            "",
            "",
            data
          )
        );
      } else {
        setParamData({ ...paramData, products_id: q });
        const data = {
          collection_date:
            state.containerTypeSelect === "container"
              ? state?.collectionRetrun?.container_collection_date
              : state?.collectionRetrun?.collection_date || "",
          pod:
            (state?.productArray.length > 0 &&
              state?.productArray.find((e) => e.type === "plan")?.qty) ||
            "",
          return_date:
            state.containerTypeSelect === "container"
              ? state?.collectionRetrun?.container_return_date
              : state.collectionRetrun?.return_date &&
                state.collectionRetrun?.return_start_time_slot
                ? state.collectionRetrun?.return_date
                : "",
          return_postcode:
            state.collectionRetrun?.return_postcode &&
              state.collectionRetrun?.return_start_time_slot
              ? state.collectionRetrun?.return_postcode
              : "",
          is_student: state.collectionRetrun?.is_student || 0,
          is_student_return: state.collectionRetrun?.is_student_return || 0,
          competitor_price: state.competitorPrice?.check
            ? state.competitorPrice?.price
            : 0,
          isCompetitor: state.competitorPrice?.check,
          container_size: state.containerListSelect,
          site_id: state?.siteSelect?.id,
          is_plan_replaced: state?.is_plan_replaced,
          // container_plan:state.containerPlanListSelect
        };
        const totalArray = [...arr, ...q];
        dispatch(
          fetchOrderSummary(
            state?.deletedProduct,
            "70",
            paramData.unit,
            paramData.period,
            totalArray,
            franchise,
            cardDetail && cardDetail.length && cardDetail[0].postcode
              ? cardDetail[0].postcode
              : "",
            "",
            "",
            "",
            "",
            data
          )
        );
      }
    }
  };

  useEffect(() => {
    if (orderSummaryData.loading) {
      setProductValue("");
    }
  }, [orderSummaryData.loading]);

  const handleOfferCode = (applyCode, franchise, quote) => {
    setSnack({ call: false, type: "", message: "" });

    findOfferCode(applyCode, franchise)
      .then((res) => {
        const arr = [...state?.coupon_id];
        if (res.data.data[0].coupon.discount_type === "percentage") {
          const val = res.data.data[0].coupon;
          const check =
            val?.item_constraints?.some(
              (e) => e.item_type === "plan" && e.constraint === "all"
            ) || false;
          if (val?.apply_on === "each_specified_item" && check) {
            arr.push({
              type: "percent",
              id: res.data.data[0].coupon.id,
              value: res.data.data[0].coupon.discount_percentage,
              name: applyCode,
              check: "billing",
              isDeletable: true,
              is_quote: quote === "quote" ? 1 : 0,
            });
          } else {
            arr.push({
              type: "percent",
              id: res.data.data[0].coupon.id,
              value: res.data.data[0].coupon.discount_percentage,
              name: applyCode,
              isDeletable: true,
              is_quote: quote === "quote" ? 1 : 0,
            });
          }
        } else {
          const val = res.data.data[0].coupon;
          const check =
            val?.item_constraints?.some(
              (e) => e.item_type === "plan" && e.constraint === "all"
            ) || false;
          if (val?.apply_on === "each_specified_item" && check) {
            arr.push({
              type: "fixed",
              id: res.data.data[0].coupon.id,
              value: res.data.data[0].coupon.discount_amount,
              name: applyCode,
              check: "billing",
              isDeletable: true,
              is_quote: quote === "quote" ? 1 : 0,
            });
          } else {
            arr.push({
              type: "fixed",
              id: res.data.data[0].coupon.id,
              value: res.data.data[0].coupon.discount_amount,
              name: applyCode,
              isDeletable: true,
              is_quote: quote === "quote" ? 1 : 0,
            });
          }
        }

        dispatchReducer({ type: "coupon", value: arr, check: "3" });
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setApplyCode("");
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
          error: error?.response?.data?.errors,
        });
      });
  };

  const handleUpdateManualAllDiscount = (values) => {
    const arr = [...state.coupon_id];

    // if (values?.applyOn === "invoice_amount") {
    // if (values?.discountType === "percentage") {
    //   const dd = arr.findIndex((e) => e.name === "manual");
    //   if (dd !== -1) {
    //     arr[dd].value = Number(values?.enterDiscount);
    //     arr[dd].type = "percent";
    //   } else {
    //     arr.push({
    //       type: "percent",
    //       value: Number(values?.enterDiscount),
    //       name: "manual",
    //     });
    //   }
    // } else {
    const dd = arr.findIndex((e) => e.name === "manual");
    if (dd !== -1) {
      arr[dd].value = Number(values?.amount);
      arr[dd].type = "fixed";
    } else {
      arr.push({
        type: "fixed",
        value: Number(values?.amount),
        name: "manual",
        check: "billing",
      });
    }
    // }
    // setTotalData({ ...totalData, discount: arr });

    dispatchReducer({ type: "coupon", value: arr, check: "4" });
    // }
  };

  useEffect(() => {
    if (state?.manualDiscount?.amount) {
      handleUpdateManualAllDiscount(state?.manualDiscount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.manualDiscount?.amount]);

  const handleAdHoc = () => {
    setSnack({ call: false, type: "", message: "" });
    findAdHoc(franchise)
      .then((res) => dispatchReducer({ type: "adHoc", adhoc: res.data.data }))
      .catch((err) =>
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
          // error: error?.response?.data?.errors,
        })
      );
  };
  useEffect(() => {
    if (
      !state.adHoc?.id &&
      cardDetail &&
      cardDetail.length &&
      state?.productArray?.length > 0
    ) {
      handleAdHoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail, state.adHoc?.id, state?.productArray?.length]);

  useEffect(() => {
    if (!state.adHoc?.id && cardDetail && cardDetail.length) {
      // handleAdHoc();
    } else {
      const addHocinPro = state.productArray?.some(
        (e) => e.id === state.adHoc?.id
      );
      const check = state.productArray?.some(
        (e) => e.productPrice === state.adhocGbp
      );
      if (addHocinPro && !check && !location?.search.includes("cm-qty")) {
        const arr = [...state.productArray];
        const preArr = [...state.productArray]
        const week = WeekCounter(state?.collectionRetrun?.collection_date, state?.collectionRetrun?.return_date)
        const pod = state?.productArray.find((e) => e.type === "plan");
        const filteredArrProduct = preArr.filter((e) => e.id !== 'Adhoc-Charges');
        const index = state.productArray.findIndex(
          (e) => e.id === state.adHoc.id
        );
        arr[index].productPrice = state.adhocGbp;
        arr[index].name = state.adHoc.external_name;
        arr[index].cost = state.adhocGbp;
        arr[index].currency = state.currencyCode;
        arr[index].total =
          arr[index] && arr[index].qty
            ? arr[index].qty * state.adhocGbp
            : state.adhocGbp;

        const isFirstClassPod = pod?.id === "First-Class";
        const isWeekGreaterThan12 = week ? week > 12 : false;
        if (isWeekGreaterThan12 && isFirstClassPod) {
          // The condition is true, no action needed.
        } else {
          dispatchReducer({
            type: "productArray",
            productArray: arr,
            check: "po",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.adHoc?.id, state.adhocGbp, state.productArray]);

  useEffect(() => {
    if (state?.productArray) {
      const check = state.productArray.some((e) => e.productPrice === "N/A");
      setDisableAll(check);
    }
  }, [state.productArray]);

  const handleStorage = () => {
    if (cardDetail && cardDetail.length) {
      setDialog(true);
      const data = {
        reducer: state,
        customerData: cardDetail,
        paramData: paramData,
      };
      dispatchReducer({ type: "params", value: paramData });
      dispatch(actions.storageData(data));
    }
  };

  useEffect(() => {
    if (
      (state.collectionRetrun?.collection_date &&
        state.collectionRetrun?.return_date &&
        state.collectionRetrun?.return_start_time_slot &&
        paramData.period !== "week") ||
      (state.collectionRetrun?.collection_date &&
        state.collectionRetrun?.return_date &&
        state.containerTypeSelect === "container")
    ) {
      if (state.productArray.some((e) => e.id === "Howdens")) return;
      handleBilling("1w");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    state?.collectionRetrun,
  ]);

  useEffect(() => {
    if (
      ((cardDetail && cardDetail.length > 0 && !state.initialBilingCycle) ||
        (billingCall && !orderDataLoading)) &&
      (location?.search.includes("quote") || state.containerTypeSelect)
    ) {
      handleBilling(
        state?.params?.period === "week"
          ? "1w"
          : state?.params?.unit
            ? state?.params?.unit
            : 1
      );
      setBillingCall(false);
      dispatchReducer({ type: "initialBilingCycle", value: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.initialBilingCycle,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cardDetail?.length,
    billingCall,
    orderDataLoading,
    state.containerTypeSelect,
  ]);
  useEffect(() => {
    if (state.productArray.some((e) => e.id === "Howdens") && !howdenCheck) {
      dispatchReducer({ type: "coupon", value: [], check: "5" });
      if (
        state.productArray.some((e) => e.id === "Howdens") &&
        (state?.params?.period === "week" || state?.params?.unit > 2)
      ) {
        handleBilling(1);
      }
      // dispatchReducer({ type: "initialBilingCycle", value: false });
      setHowdenCheck(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.productArray]);
  useEffect(() => {
    if ((!state?.franchise_id && franchise) || (state?.franchise_id && (state?.franchise_id != franchise))) {

      dispatchReducer({
        type: "franchise_id",
        value: franchise,

      });
    }
  }, [franchise]);
  const handleChangePlan = useCallback((e) => {
    const arr = [...paramData.products_id];
    const arrQuantity = [...state.quantity];
    const removeIds = ["POD", "Economy-POD", "First-Class"];

    const changeQuantity = arrQuantity?.map((item) => {
      if (removeIds.includes(item?.id)) {
        return { ...item, qty: 1 };
      }
      return item;
    });
    let newArr = arr.filter((id) => !removeIds.includes(id));
    newArr.push(e.target.value);
    dispatchReducer({ type: "quantity", value: changeQuantity })
    const data = {
      collection_date:
        state.containerTypeSelect === "container"
          ? state?.collectionRetrun?.container_collection_date
          : state?.collectionRetrun?.collection_date || "",
      pod:
        1,
      return_date:
        state.containerTypeSelect === "container"
          ? state?.collectionRetrun?.container_return_date
          : state.collectionRetrun?.return_date &&
            state.collectionRetrun?.return_start_time_slot
            ? state.collectionRetrun?.return_date
            : "",
      return_postcode:
        state.collectionRetrun?.return_postcode &&
          state.collectionRetrun?.return_start_time_slot
          ? state.collectionRetrun?.return_postcode
          : "",
      is_student: state.collectionRetrun?.is_student || 0,
      is_student_return: state.collectionRetrun?.is_student_return || 0,
      competitor_price: state.competitorPrice?.check
        ? state.competitorPrice?.price
        : 0,
      isCompetitor: state.competitorPrice?.check,
      container_size: state.containerListSelect,
      site_id: state?.siteSelect?.id,
      is_plan_replaced: state?.is_plan_replaced
      // container_plan: state.containerPlanListSelect
    };
    dispatch(
      fetchOrderSummary(
        state?.deletedProduct,
        "7",
        paramData.unit,
        paramData.period,
        newArr,
        franchise,
        cardDetail &&
          cardDetail.length &&
          cardDetail[0].postcode
          ? cardDetail[0].postcode
          : state?.postcode?.postcode || "",
        "",
        "",
        "",
        "",
        data
      )
    );
    setParamData({ ...paramData, products_id: newArr });
    setPlanSelected(e.target.value);
  }, [state, paramData, setParamData, setPlanSelected, dispatch, franchise, cardDetail]);

  return (
    <>
      <div
        className="card card-custom gutter-b order-summary"
        style={{ minHeight: "640px" }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                    <div className="mr-3 mb-5">
                      <h4 className="card-title align-items-start flex-column mb-0">
                        <span className="font-weight-bolder text-dark">
                          {intl.formatMessage({ id: "Order.summary" })}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mr-1 w-30" style={{ width: "50%" }}>
              {orderSummaryData.data && orderSummaryData.data.length ? (
                <div className="d-flex">
                  <FormControl fullWidth className="mr-2">
                    <Select
                      name="address"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={`form-control  ${getInputClasses(
                        formik,
                        "address"
                      )}`}
                      IconComponent={(props) => (
                        <i {...props}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      value={
                        state?.params?.period === "week"
                          ? "1w"
                          : state?.params?.period === "year"
                            ? "12"
                            : state?.params.unit
                      }
                      onChange={(e) => handleBilling(e.target.value)}
                    >
                      <MenuItem value=" ">
                        {intl.formatMessage({ id: "Billing.Cycle" })}
                      </MenuItem>
                      {chargebee &&
                        chargebee.length &&
                        chargebee.map((data, index) => (
                          <MenuItem
                            value={data.unit}
                            key={index}
                            disabled={
                              state.productArray.some((e) => e.id === "Howdens")
                                ? data.unit > 2 || data.unit === "1w"
                                : data.unit === "1w" &&
                                  state.collectionRetrun?.return_date &&
                                  state.collectionRetrun?.return_start_time_slot
                                  ? true
                                  : false
                            }
                          >
                            {data.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="mb-5">
            {/* {!cardDetail.length ? ( */}
            <PostcodeSearch
              dispatchReducer={dispatchReducer}
              state={state}
              quantity={quantity}
              setProductArray={setProductArray}
              setParamData={setParamData}
              paramData={paramData}
              cardDetail={cardDetail}
              handleChangeOrder={handleChangeOrder}
            />
            {/* ) : (
              ""
            )} */}
            <div className="d-flex prod-search  ">
              <div
                className="order-search product-search"
                style={{ width: "50% !important" }}
              >
                <InputGroup>
                  <Autocomplete
                    autoComplete={false}
                    fullWidth
                    loading={searchProduct.loading}
                    filterSelectedOptions
                    onChange={(e, q) => handleChangeOrder(q)}
                    onInputChange={(event, newInputValue) => {
                      setProductValue(newInputValue);
                    }}
                    inputValue={productValue}
                    id="controllable-states-demo"
                    options={productValue !== "" ? searchProduct.data : []}
                    getOptionLabel={(option) =>
                      `${option?.item?.id} ${" | "}${option?.item?.name
                      } ${" | "} ${(option?.price &&
                        option?.price?.length &&
                        option?.price[0]?.item_price?.price) ||
                      "N/A"}`
                    }
                    renderOption={(props, option) => {
                      const index = props?.price.findIndex(
                        (e) =>
                          e?.item_price?.currency_code === state?.currencyCode
                      );
                      return (
                        <span>
                          {/* {props?.item?.id}&nbsp;|&nbsp;&nbsp;{" "} */}
                          {props?.item?.name}
                          {/* &nbsp;|&nbsp;&nbsp; */}
                          {/* {(props &&
                            props.price &&
                            props.price.length &&
                            props.price[index] &&
                            props.price[index]?.item_price?.currency_code &&
                            convertGBPtoPoundSymbol(
                              props.price[index]?.item_price?.currency_code ||
                                " "
                            ) + props.price[index]?.item_price?.price) ||
                            "N/A"} */}
                        </span>
                      );
                    }}
                    style={{ width: 270 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={intl.formatMessage({ id: "Search.Product" })}
                        variant="outlined"
                        className="form-control"
                        onChange={(e) => setSelectV(true)}
                      />
                    )}
                  />
                </InputGroup>
              </div>
              <div className="ml-3">
                <button
                  className="btn btn-secondary font-weight-bolder font-size-sm mr-1 "
                  onClick={() => handleStorage()}
                >
                  {intl.formatMessage({ id: "Add/Edit.Storage.Items" })}
                </button>
              </div>
            </div>
            <br />
            {state?.containerTypeSelect === "pod" && !orderSummaryData.loading &&
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Plan</FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="top" value={planSelected} onChange={handleChangePlan}>

                  <FormControlLabel value="Economy-POD" control={<Radio color="primary" />} label="Economy" />
                  <FormControlLabel value="POD" control={<Radio color="primary" />} label="Premium" />
                  <FormControlLabel value="First-Class" control={<Radio color="primary" />} label="First Class" />

                </RadioGroup>
              </FormControl>
            }
          </div>
          <div>
            <OrderSummaryTable
              quantityUpdate={handleOrderTableQuantity}
              rowDelete={handleOrderTableDelete}
              orderData={orderSummaryData.data}
              orderDataTwo={orderSummaryData?.data_two}
              handleDelete={handleOrderTableDelete}
              loading={orderSummaryData.loading}
              setProductArray={setProductArray}
              productArray={productArray}
              setTotalData={setTotalData}
              totalData={totalData}
              dispatchReducer={dispatchReducer}
              state={state}
              setQuantity={setQuantity}
              quantity={quantity}
              setParamData={setParamData}
              paramData={paramData}
              location={location}
              secCheck={secCheck}
              handleBilling={handleBilling}
            />
          </div>
          {(state?.productArray && state?.productArray.length) ||
            orderSummaryData.loading ? (
            <>
              <OrderSummaryButton
                state={state}
                formik={formik}
                setOpenCanvaQuote={setOpenCanvaQuote}
                dispatchReducer={dispatchReducer}
                disableAll={disableAll}
                intl={intl}
                setOpenCanva={setOpenCanva}
                getInputClasses={getInputClasses}
                applyCode={applyCode}
                setApplyCode={setApplyCode}
                handleOfferCode={handleOfferCode}
                franchise={franchise}
                paramData={paramData}
                setParamData={setParamData}
                fetchOrderSummary={fetchOrderSummary}
                cardDetail={cardDetail}
                dispatch={dispatch}
                setSnack={setSnack}
                location={location}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <OrderNotes
        state={state}
        dispatchReducer={dispatchReducer}
        cardDetail={cardDetail}
      /> */}
      {/* ) : (
        ""
      )} */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      {openCanva ? (
        <>
          <ManualDiscount
            setOpenCanva={setOpenCanva}
            state={state}
            dispatchReducer={dispatchReducer}
            handleUpdateManualAllDiscount={handleUpdateManualAllDiscount}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanva(false)}
          ></div>
        </>
      ) : (
        ""
      )}
      {(openCanvaQuote.type === "quote" || openCanvaQuote.type === "draft") &&
        openCanvaQuote.open ? (
        <>
          <SendQuote
            state={state}
            cardDetail={cardDetail}
            setOpenCanvaQuote={setOpenCanvaQuote}
            openCanvaQuote={openCanvaQuote}
            location={location}
          >
            <OrderSummaryTable
              quantityUpdate={handleOrderTableQuantity}
              rowDelete={handleOrderTableDelete}
              orderData={orderSummaryData.data}
              orderDataTwo={orderSummaryData?.data_two}
              handleDelete={handleOrderTableDelete}
              loading={orderSummaryData.loading}
              setProductArray={setProductArray}
              productArray={productArray}
              setTotalData={setTotalData}
              totalData={totalData}
              dispatchReducer={dispatchReducer}
              state={state}
              setQuantity={setQuantity}
              quantity={quantity}
              typeCheck={"sendQuote"}
              paramData={paramData}
              setParamData={setParamData}
              location={location}
              secCheck={secCheck}
              handleBilling={handleBilling}
            />
          </SendQuote>
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
          ></div>
        </>
      ) : (
        ""
      )}
      {openCanvaQuote.type === "payment" && openCanvaQuote.open ? (
        <>
          <OrderPayment
            state={state}
            dispatchReducer={dispatchReducer}
            setOpenCanvaQuote={setOpenCanvaQuote}
            snack={snack}
            setSnack={setSnack}
          />
          <div
            className="offcanvas-overlay"
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
          ></div>
        </>
      ) : (
        ""
      )}

      <CalcumateModal dialog={dialog} setDialog={setDialog} />
    </>
  );
}
