/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import {
  CardCVV,
  CardComponent,
  CardExpiry,
  CardNumber,
} from "@chargebee/chargebee-js-react-wrapper";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  getPaymentIntent,
  getAddress,
  getPlaceOrder,
  addCards,
  addCharge,
  addPerson,
  addCardOnPlace,
  addPodCharge,
} from "../_redux/order/orderCrud";
import { shallowEqual, useSelector } from "react-redux";

import { OrderPaymentSchema } from "../order-form/validationSchema";
import SVG from "react-inlinesvg";
// import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import {
  apiThirdPartyEmail,
  handleFinalPrice,
  newOrderAddressAuth3ds,
  newOrderIntent,
  newOrderPayment,
  newOrderPaymentIntent,
} from "../orderUtils";
import { getInputClasses } from "../../../utils/commonFunction";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useLocation } from "react-router-dom";

export function OrderPayment({
  state,
  dispatchReducer,
  setOpenCanvaQuote,
  snack,
  setSnack,
}) {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { cardDetail } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail,
    }),
    shallowEqual
  );
  const [dropDown, setDropDown] = useState({ address: [] });
  const [checked, setChecked] = useState(false);
  const [confettiLoading, setConfettiLoading] = useState(false);

  const handleManualUpdate = () => {
    formik.setFieldValue("street1", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("is_address_manual", 1);
  };

  const handleGetAddress = async (postcode, check) => {
    if (!postcode) return;
    formik.setFieldValue("is_address_manual", "0");
    getAddress(postcode)
      .then((res) => {
        setDropDown({ address: res.data.addresses });
        formik.setFieldValue("city", res.data.addresses[0].town_or_city);
        if (check !== "onMount") {
          setSnack({
            call: true,
            type: "success",
            message: "Postcode found successfully",
          });
        }
      })
      .catch((error) => {
        formik.setFieldValue("street1", "");
        formik.setFieldValue("city", "");
        setSnack({
          call: true,
          type: "error",
          message: "Cannot find Post Code",
        });
      });
  };
  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length &&
      cardDetail[0]?.contact?.franchise?.chargebee_key
    ) {
      const el = document.createElement("script");

      el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
      document.body.appendChild(el);
      el.onload = () => {
        window.Chargebee.init({
          site:
            (cardDetail &&
              cardDetail.length &&
              cardDetail[0]?.contact?.franchise?.chargebee_key) ||
            "",
          publishableKey:
            (cardDetail &&
              cardDetail.length &&
              cardDetail[0]?.contact?.franchise?.stripe_pk) ||
            "",
        });
        // window.Chargebee.registerAgain();
        setChecked(true);
        // this.setState({ chargebeeReady: true });
      };
      return () => {
        // window.Chargebee.registerAgain();
        document.body.removeChild(el);
        window.Chargebee.getInstance().closeAll();
      };
    }
  }, [cardDetail]);

  const cardRef = React.createRef();
  const initialValues = {
    billing: "same",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    postCode: "",
    firstName:
      cardDetail && cardDetail.length && cardDetail[0].first_name
        ? cardDetail[0].first_name + " " + cardDetail[0]?.last_name
        : "",
    is_address_manual: "0",
    paymentType: "card",
  };
  const [state3ds, setState] = useState({
    intent_id: "",
    error: "",
    loading: false,
    intentLoading: false,
    firstName: "",
    options: {
      // Custom classes - applied on container elements based on field's state
      classes: {
        focus: "focus",
        invalid: "invalid",
        empty: "empty",
        complete: "complete",
      },

      style: {
        // Styles for default field state
        base: {
          color: "#333",
          fontWeight: "500",
          fontFamily: "Roboto, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",

          ":focus": {
            color: "#424770",
          },

          "::placeholder": {
            color: "transparent",
          },

          ":focus::placeholder": {
            color: "#7b808c",
          },
        },

        // Styles for invalid field state
        invalid: {
          color: "#e41029",

          ":focus": {
            color: "#e44d5f",
          },
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },

      // locale
      locale: "en",

      // Custom placeholders
      placeholder: {
        number: "4111 1111 1111 1111",
        expiry: "MM / YY",
        cvv: "CVV",
      },

      // Custom fonts
      fonts: ["https://fonts.googleapis.com/css?family=Roboto:300,500,600"],
    },
  });
  // const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  // const [validate, setValidate] = useState("same");

  const authorizeWith3ds = async () => {
    setState({ loading: true });
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const addre =
      cardDetail && cardDetail.length > 0 && cardDetail[0].address
        ? cardDetail[0].address.split(",")
        : "";

    const additionalData = newOrderAddressAuth3ds(
      cardDetail,
      addre,
      state,
      formik
    );

    const intent = newOrderIntent(state);
    cardRef.current
      .authorizeWith3ds(intent, additionalData)
      .then((data) => {
        setState({ loading: true, intent_id: data.id, error: "" });
        handlePayment(data?.id);

        // handlePayment(data.active_payment_attempt.id_at_gateway);
      })
      .catch((error) => {
        console.log();
        // const time = error?.code === "replyTimeout" ? 2000 : 15000;
        setTimeout(() => {
          setOpenCanvaQuote({ type: "", open: false });
          if (error?.code === "replyTimeout") {
            window.location.reload();
          }
        }, 2000);

        setSnack({
          call: true,
          type: "error",
          message: (
            <span>
              error
              {error?.displayMessage}
              {error?.displayMessage ? <br /> : ""}
              {error.message}
            </span>
          ),
        });
        setState({
          loading: false,
          intent_id: "",
          error: error.message,
        });
      });
  };
  const handleChange = (event) => {
    const target = event.target;
    let val = target.value.replace(/[^a-z ]/gi, "");
    const name = target.name;
    formik.setFieldValue("firstName", val);
    setState({
      [name]: val,
    });
  };

  const handleReset = () => { };

  const handlePaymentIntent = (value, stValue) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    setState({ ...state3ds, intentLoading: true });
    let finalPrice = 0;
    if (state?.collectionRetrun?.return_start_time_slot) {
      finalPrice = handleFinalPrice(state)?.total;
    }

    const data = newOrderPaymentIntent(value, state, stValue, finalPrice);
    getPaymentIntent(data)
      .then((res) => {
        dispatchReducer({ type: "paymentIntent", value: res.data });
        setState({ ...state3ds, intentLoading: false });
      })
      .catch((err) => {
        setSnack({
          call: true,
          type: "error",
          message: err?.response?.data?.message,
        });
        setState({ ...state3ds, intentLoading: false });
      });
  };

  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length > 0 &&
      cardDetail[0] &&
      state?.currencyCode
    )
      handlePaymentIntent(cardDetail[0], state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail, state?.currencyCode]);
  const handlePayment = (token) => {
    setState({ ...state3ds, loading: true });
    const data = newOrderPayment(cardDetail, state, formik, token);
    for (let items of state?.productArray) {
      if (
        items?.is_packaging_material === "1" ||
        items?.is_packaging_material === 1
      ) {
        data.packaging_materials.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      if (
        items.id === "Standard-Access" ||
        items.id === "24hr-Access" ||
        items.id === "Extended-Access"
      ) {
        data.access_type = items.id;
      }
      if (items.type === "addon") {
        const productItem = {
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
          cover: items?.cover,
        };

        if (
          items.id !== "Container-Protect" &&
          items.id !== "Store-Protect" &&
          items.id !== "Insurance-Cover"
        ) {
          // Remove the 'cover' property from productItem
          delete productItem.cover;
        }
        data.product.push(productItem);

        if (
          items.id === "Container-Protect" ||
          items.id === "Store-Protect" ||
          items.id === "Insurance-Cover"
        ) {
          data.insurance_cover = items?.cover;
        }
      } else {
        data.product.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      data.item_price_id.push(items.price_id);
      data.quantity.push(items.qty ? items.qty : 1);
    }
    if (state?.coupon_id) {
      for (let items of state?.coupon_id) {
        if (items?.id) data.coupon_id.push(items.id);
      }
    }
    if (state?.adhocChargeName) {
      data.adhoc_charge_name = state?.adhocChargeName;
    }
    const adhoc = state?.productArray.findIndex(
      (e) => e.id === state?.adHoc?.id
    );
    if (adhoc !== -1) {
      data.adhoc_charge_price = state?.productArray[adhoc].productPrice;
      data.adhoc_charge_quantity = state?.productArray[adhoc].qty;
    }
    if (state?.manualDiscount?.amount) {
      const datas = {
        duration_type: state?.manualDiscount?.durationType || "",
        apply_on: state?.manualDiscount?.applyOn || "",
        discount_amount:
          (+state?.manualDiscount?.amount &&
            state?.manualDiscount?.manual_discount &&
            (
              +state?.manualDiscount?.amount +
              Number(state?.manualDiscount?.manual_discount)
            ).toFixed(2)) ||
          "",
        discount_type: state?.manualDiscount?.discount_type || "",
        manual_discount_period: state?.manualDiscount?.period || "",
        manual_discount_period_unit: state?.manualDiscount?.periodUnit || "",
        manual_discount_item_price_id:
          state?.manualDiscount?.manual_discount_item_price_id || "",
        // manual_discount: state?.manualDiscount?.manual_discount || "",
      };
      data.apply_manual_discounts.push(datas);
    }
    if (formik?.values?.paymentType) {
      data.payment_type = formik?.values?.paymentType;
    }
    if (state.competitorPrice?.check) {
      const pod = state.productArray.find((e) => e.type === "plan");
      data.competitor_price = state?.competitorPrice?.price || 0;
      data.competitor_pod_price = pod?.cost || 0;
      data.competitor_name = state?.competitorPrice?.name || "";
    }
    const containerExists =
      data.product.some((e) => e.id === "Container") ||
      state.containerTypeSelect === "container";
    const howdenExists = data.product.some((e) => e.id === "Howdens");

    if (containerExists || howdenExists) {
      data.is_container = containerExists ? 1 : 2;

      data.date_type = state.collectionRetrun?.date_type || "";
      data.site_class_id = state.collectionRetrun?.site_class_id || "";
      data.container_return_date =
        state.collectionRetrun?.container_return_date || "";
      data.container_collection_date =
        state.collectionRetrun?.container_collection_date || "";
      data.return_date = state.collectionRetrun?.return_date || "";

      if (state.availData.length > 0) {
        const qty =
          state?.productArray.find((e) => e.type === "plan")?.qty || 0;

        // Filter availData based on the quantity
        const filteredAvailData = state.availData.slice(0, +qty);

        // Extract the 'id' values from the filtered array
        const franchise_container_id = filteredAvailData.map((e) => e.id);

        // Assign the franchise_container_id to data.franchise_container_id
        data.franchise_container_id = franchise_container_id;
        data.end_time_slot = "";
        data.return_end_time_slot = "";
      }
    }

    if (location?.search.includes("quote")) {
      if (
        cardDetail &&
        cardDetail.length > 0 &&
        cardDetail[0] &&
        cardDetail[0].contact?.latest_deals?.id
      ) {
        data.deal_id = cardDetail[0] && cardDetail[0].contact?.latest_deals?.id;
      }
    }
    if (
      (state?.containerTypeSelect != "pod") ||
      (state?.containerTypeSelect == "pod" && state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e) => e.type === "plan" && e?.id !== "Economy-POD"))
    ) {
      data.is_reservation =
        new Date(state.collectionRetrun?.collection_date) <
          new Date(Date.now() + 48 * 60 * 60 * 1000)
          ? 1
          : 2;
    }

    getPlaceOrder(data)
      .then((res) => {
        setConfettiLoading(true);
        // setSnack({ call: true, type: "success", message: res.data.message });

        if (
          (state?.containerTypeSelect == "pod" && state?.franchise_id != process.env?.REACT_APP_FRANCHISE_ID) ||
          (state?.containerTypeSelect == "pod" &&
            state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e) => e.type === "plan" && e?.id === "Economy-POD")) || (
            state.collectionRetrun?.collection_date &&
            new Date(state.collectionRetrun?.collection_date) <
            new Date(Date.now() + 48 * 60 * 60 * 1000)) ||
          (state?.containerTypeSelect === "container" &&
            state.collectionRetrun?.collection_date &&
            // Convert string date to Date object
            new Date(state.collectionRetrun?.collection_date) <
            new Date(Date.now() + 48 * 60 * 60 * 1000)) ||
          // state?.containerTypeSelect === "container" &&
          formik.values?.paymentType !== "card"
        ) {
          setOpenCanvaQuote({ type: "", open: false });

          addPerson(data.email)
            .then(() => {
              window.location.reload();
              setTimeout(() => { }, 2000);
            })
            .catch(() => {
              window.location.reload();
              setTimeout(() => { }, 2000);
            });
          history.push(`/successful-order/${res.data.data?.id}`);
        } else {
          addPerson(data.email);
          const dataCard = {
            customer_chargebee_id: res.data.data?.customer_chargebee_id,
            franchise_id: res.data.data?.franchise_id,
            gw_token: token,
            order_id: res.data.data?.id,
          };
          addCardOnPlace(dataCard)
            .then((resC) => {
              let finalPrice = 0;
              if (state?.collectionRetrun?.return_start_time_slot) {
                finalPrice = handleFinalPrice(state)?.total;
              }
              const twentyPercentOfTotal =
                Number(state?.collectionRetrun?.return_start_time_slot ? finalPrice.toFixed(2) : state?.total.toFixed(2)) * 0.1;
              const ePercentOfTotal = Number(state?.collectionRetrun?.return_start_time_slot ? finalPrice : state?.total) * 0.9;
              const dataC = {
                order_id: dataCard?.order_id,
                invoice_id: res.data.data?.invoice_id,
                invoice_due_amount:
                  state?.total == 0 ? 0 : +state?.total.toFixed(2),
                twenty_percent_amount: Number(twentyPercentOfTotal).toFixed(2),
                eighty_percent_amount: Number(ePercentOfTotal).toFixed(2),
                twenty_percent_status: 1,
                ...data,
              };
              if (state.containerTypeSelect !== "container") {
                addPodCharge(dataC)
                  .then((resCh) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "success",
                      message: resCh.data.message,
                    });
                    setOpenCanvaQuote({ type: "", open: false });

                    history.push(`/successful-order/${res.data.data?.id}`);
                    window.location.reload();
                    setTimeout(() => { }, 2000);
                  })
                  .catch((error) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "error",
                      message:
                        "Order Placed successfully " +
                        error?.response?.data?.message,
                    });
                  });
              } else {
                addCharge(dataC)
                  .then((resCh) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "success",
                      message: resCh.data.message,
                    });
                    setOpenCanvaQuote({ type: "", open: false });

                    history.push(`/successful-order/${res.data.data?.id}`);
                    window.location.reload();
                    setTimeout(() => { }, 2000);
                  })
                  .catch((error) => {
                    setState({ ...state3ds, loading: false });
                    setSnack({
                      call: true,
                      type: "error",
                      message:
                        "Order Placed successfully " +
                        error?.response?.data?.message,
                    });
                  });
              }
            })
            .catch((err) => {
              setState({ ...state3ds, loading: false });
              setSnack({
                call: true,
                type: "error",
                message:
                  "Order Placed successfully " + err?.response?.data?.message,
              });
            });
        }
        // setTimeout(() => {}, 3000);
      })
      .catch((error) => {
        setState({ ...state3ds, loading: false });
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };
  useEffect(() => {
    if (confettiLoading) {
      const timer = setTimeout(() => {
        // Call your function here
        setConfettiLoading(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [confettiLoading]);

  const handleSubmit = () => {
    // authorizeWith3ds();
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: OrderPaymentSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });
  useEffect(() => {
    if (formik?.values?.postcode && formik.values?.is_address_manual !== 1) {
      handleGetAddress(formik?.values?.postcode, "onMount");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.postcode]);
  const handlePayByLink = () => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    let checkLatestDeal =
      (cardDetail &&
        cardDetail.length &&
        cardDetail[0].contact &&
        cardDetail[0].contact.latest_deals?.id) ||
      "";
    const data = newOrderPayment(cardDetail, state, formik);
    for (let items of state?.productArray) {
      if (items.type === "addon") {
        const productItem = {
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
          cover: items?.cover,
        };

        if (
          items.id !== "Container-Protect" &&
          items.id !== "Store-Protect" &&
          items.id !== "Insurance-Cover"
        ) {
          // Remove the 'cover' property from productItem
          delete productItem.cover;
        }
        data.product.push(productItem);

        data.insurance_cover = items?.cover;
      } else {
        data.product.push({
          id: items.id,
          quantity: items.qty ? items.qty : 1,
          name: items.name,
          price: items.cost,
          item_price_id: items.price_id,
        });
      }
      data.item_price_id.push(items.price_id);
      data.quantity.push(items.qty ? items.qty : 1);
    }
    if (state?.coupon_id) {
      for (let items of state?.coupon_id) {
        if (items?.id) data.coupon_id.push(items.id);
      }
    }
    if (state?.adhocChargeName) {
      data.adhoc_charge_name = state?.adhocChargeName;
    }
    const adhoc = state?.productArray.findIndex(
      (e) => e.id === state?.adHoc?.id
    );
    if (adhoc !== -1) {
      data.adhoc_charge_price = state?.productArray[adhoc].productPrice;
      data.adhoc_charge_quantity = state?.productArray[adhoc].qty;
    }
    if (state?.manualDiscount?.applyOn) {
      const datas = {
        duration_type: state?.manualDiscount?.duration || "",
        apply_on: state?.manualDiscount?.applyOn || "",
        discount_amount: state?.manualDiscount?.enterDiscount || "",
        discount_type: state?.manualDiscount?.discountType || "",
        manual_discount_period: state?.manualDiscount?.limitedPeriod || "",
        manual_discount_period_unit: state?.manualDiscount?.time || "",
      };
      data.apply_manual_discounts.push(datas);
    }
    data.payment_link = 1;
    data.to = (cardDetail && cardDetail.length && cardDetail[0].email) || "";
    data.subject = "hello, your easyStorage enquiry.";
    data.deal_id = checkLatestDeal;
    if (state?.collectionRetrun?.return_start_time_slot) {
      data.amount = handleFinalPrice(state)?.total;
    }

    const containerExists =
      data.product.some((e) => e.id === "Container") ||
      state.containerTypeSelect === "container";
    const howdenExists = data.product.some((e) => e.id === "Howdens");

    if (containerExists || howdenExists) {
      data.is_container = containerExists ? 1 : 2;
      data.date_type = state.collectionRetrun?.date_type || "";
      data.site_class_id = state.collectionRetrun?.site_class_id || "";
      data.container_return_date =
        state.collectionRetrun?.container_return_date || "";
      data.container_collection_date =
        state.collectionRetrun?.container_collection_date || "";
      data.return_date = state.collectionRetrun?.return_date || "";

      if (state.availData.length > 0) {
        const qty =
          state?.productArray.find((e) => e.type === "plan")?.qty || 0;

        // Filter availData based on the quantity
        const filteredAvailData = state.availData.slice(0, +qty);

        // Extract the 'id' values from the filtered array
        const franchise_container_id = filteredAvailData.map((e) => e.id);

        // Assign the franchise_container_id to data.franchise_container_id
        data.franchise_container_id = franchise_container_id;
        data.end_time_slot = "";
        data.return_end_time_slot = "";
      }
    }
    if (
      state?.containerTypeSelect != "pod" ||
      (state?.containerTypeSelect == "pod" && state?.franchise_id == process.env?.REACT_APP_FRANCHISE_ID && state?.productArray?.some((e) => e.type === "plan" && e?.id !== "Economy-POD"))
    ) {
      data.is_reservation =
        new Date(state.collectionRetrun?.collection_date) <
          new Date(Date.now() + 48 * 60 * 60 * 1000)
          ? 1
          : 2;
    }

    getPlaceOrder(data)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  // const hello = () => {
  //   const container = document.querySelector(".fireworks");
  //   const fireworks = new Fireworks.default(container);
  //   fireworks.start();
  // };

  return (
    <div
      id="kt_orderpayment_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_orderpayment_panel_close"
            onClick={() => setOpenCanvaQuote({ type: "", open: false })}
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          {confettiLoading && (
            <div className="offcanvas-overlay ">
              <div className="customloader">
                {/* <Fireworks
                  style={{
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    position: "fixed",
                    background: "transparent",
                  }}
                  enabled={enabled}
                  options={options}
                ></Fireworks> */}
                {/* <Confetti width={width} height={height} /> */}
              </div>
            </div>
          )}
          <div>
            <h5 onClick={() => hello()}>
              {intl.formatMessage({ id: "Order.Payment" })}
            </h5>
          </div>
          <div className="row mt-4">
            <div className="mb-4 col-4">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "card" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "card")}
                />
                <span className="mr-2"></span>
                Card
              </label>
            </div>
            <div className="mb-4 col-4 ">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "cash" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "cash")}
                />
                <span className="mr-2"></span>
                Cash
              </label>
            </div>{" "}
            <div className="mb-4 col-4">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="enabled_for_checkout"
                  checked={formik.values?.paymentType === "bac" ? true : false}
                  onChange={(e) => formik.setFieldValue("paymentType", "bac")}
                />
                <span className="mr-2"></span>
                Bank Transfer
              </label>
            </div>{" "}
          </div>

          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10 custom-scroll"
            id="kt_quick_panel_logs_kt_orderpayment_panel"
          >
            {formik?.values?.paymentType === "card" ? (
              <>
                {checked ? (
                  <CardComponent
                    ref={cardRef}
                    className="fieldset field"
                    styles={state3ds?.options?.style}
                    classes={state3ds?.options?.classes}
                    locale={state3ds?.options?.locale}
                    placeholder={state3ds?.options?.placeholder}
                    fonts={state3ds?.options?.fonts}
                  >
                    <div className="mb-5">
                      <h6>{intl.formatMessage({ id: "Payment.Type" })}</h6>
                    </div>
                    <div>
                      <img
                        src="../media/payment/visa.png"
                        alt=""
                        className="mr-1"
                      />
                      <img
                        src="../media/payment/master-card.png"
                        alt=""
                        className="mr-1"
                      />
                      <img
                        src="../media/payment/american-express.png"
                        alt=""
                        className="mr-1"
                      />
                      <img
                        src="../media/payment/stripe.png"
                        alt=""
                        className="mr-1"
                      />
                    </div>
                    <div className="mt-10">
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>
                            {intl.formatMessage({ id: "Card.Number" })}
                          </Form.Label>

                          <CardNumber
                            className="ex4-input form-control"
                            placeholder={intl.formatMessage({
                              id: "Card.Number",
                            })}
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-6 mb-5">
                              <div>
                                <Form.Label>
                                  {intl.formatMessage({ id: "Expires" })}
                                </Form.Label>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <CardExpiry className="ex4-input form-control" />

                                  {formik.touched.expires &&
                                    formik.errors.expires ? (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.expires}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>
                                  {intl.formatMessage({ id: "CVV" })}
                                </Form.Label>
                                <CardCVV
                                  className="ex4-input form-control"
                                  placeholder="CVV"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>
                            {intl.formatMessage({ id: "Name.On.Card" })}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            onKeydown="return /[a-z]/i.test(event.key)"
                            placeholder="Name on Card"
                            className={
                              state3ds.firstName
                                ? "ex4-input val form-control"
                                : "ex4-input form-control"
                            }
                            value={formik.values.firstName}
                            onChange={handleChange}
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.firstName}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <div className="mb-7 payment-radio">
                        <Form.Check
                          className="mb-4"
                          type="radio"
                          label={intl.formatMessage({ id: "Billing.address" })}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios1"
                          checked={formik.values.billing === "same"}
                          onClick={() =>
                            formik.setFieldValue("billing", "same")
                          }
                        />
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({
                            id: "Billing.customer.address",
                          })}
                          name="formHorizontalRadios"
                          id="formHorizontalRadios2"
                          checked={formik.values.billing === "manual"}
                          onClick={() =>
                            formik.setFieldValue("billing", "manual")
                          }
                        />
                      </div>
                      {formik.values.billing === "manual" ? (
                        // <div className="row">
                        //   <div className="col-md-12 mb-5">
                        //     <Form.Control
                        //       type="text"
                        //       placeholder={intl.formatMessage({ id: "street1" })}
                        //       name="street1"
                        //       {...formik.getFieldProps("street1")}
                        //     />
                        //     {formik.touched.street1 && formik.errors.street1 ? (
                        //       <div className="fv-plugins-message-container">
                        //         <div className="fv-help-block">
                        //           {formik.errors.street1}
                        //         </div>
                        //       </div>
                        //     ) : null}
                        //   </div>
                        //   <div className="col-md-12 mb-5">
                        //     <Form.Control
                        //       type="text"
                        //       placeholder={intl.formatMessage({ id: "Street2" })}
                        //       name="street2"
                        //       {...formik.getFieldProps("street2")}
                        //     />
                        //   </div>
                        //   <div className="col-md-12 mb-5">
                        //     <Form.Control
                        //       type="text"
                        //       placeholder={intl.formatMessage({ id: "Street3" })}
                        //       name="street3"
                        //       {...formik.getFieldProps("street3")}
                        //     />
                        //   </div>
                        //   <div className="col-md-7 mb-5">
                        //     <Form.Control
                        //       type="text"
                        //       placeholder="City"
                        //       className={`form-control  ${getInputClasses(
                        //         formik,
                        //         "city"
                        //       )}`}
                        //       name="city"
                        //       {...formik.getFieldProps("city")}
                        //     />
                        //     {formik.touched.city && formik.errors.city ? (
                        //       <div className="fv-plugins-message-container">
                        //         <div className="fv-help-block">
                        //           {formik.errors.city}
                        //         </div>
                        //       </div>
                        //     ) : null}
                        //   </div>
                        //   <div className="col-md-5 mb-5 ">
                        //     <InputGroup>
                        //       <Form.Control
                        //         type="text"
                        //         placeholder="Postal Code"
                        //         aria-describedby="inputGroupPrepend"
                        //         // required
                        //         className={`form-control  ${getInputClasses(
                        //           formik,
                        //           "postCode"
                        //         )}`}
                        //         name="postCode"
                        //         {...formik.getFieldProps("postCode")}
                        //       />
                        //       {/* <InputGroup.Append>
                        //         <InputGroup.Text id="inputGroupPrepend">
                        //           <span className="symbol-label ml-2 pointer">
                        //             <span className="svg-icon svg-icon-sm svg-icon-primary">
                        //               <SVG
                        //                 title=" "
                        //                 className="h-25 align-self-end"
                        //                 src={toAbsoluteUrl(
                        //                   "/media/svg/icons/General/Search.svg"
                        //                 )}
                        //               ></SVG>
                        //             </span>
                        //           </span>
                        //         </InputGroup.Text>
                        //       </InputGroup.Append> */}
                        //     </InputGroup>
                        //     {formik.touched.postCode && formik.errors.postCode ? (
                        //       <div className="fv-plugins-message-container">
                        //         <div className="fv-help-block">
                        //           {formik.errors.postCode}
                        //         </div>
                        //       </div>
                        //     ) : null}
                        //   </div>
                        // </div>
                        <div className="row">
                          <div className="col-md-12 mb-5">
                            <label>
                              {intl.formatMessage({ id: "Post.Code" })}
                            </label>
                            <div className="row align-items-center">
                              <div className="col-6 d-flex justify-content-between align-items-center ">
                                <div className="form-groupB w-100">
                                  <input
                                    type="text"
                                    className={`form-control  ${getInputClasses(
                                      formik,
                                      "postCode"
                                    )}`}
                                    placeholder={intl.formatMessage({
                                      id: "Post.Code",
                                    })}
                                    name="postCode"
                                    value={formik.values?.postCode}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;

                                      // Remove any non-alphanumeric characters from the input value
                                      const alphanumericValue = inputValue.replace(
                                        /\W/g,
                                        ""
                                      );

                                      // Use regex to format the input value as a UK postcode
                                      const formattedValue = alphanumericValue.replace(
                                        /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
                                        "$1 $2"
                                      );
                                      formik.setFieldValue(
                                        "postCode",
                                        formattedValue
                                      );
                                    }}
                                  // {...formik.getFieldProps("postcode")}
                                  />
                                </div>
                                <div className="mb-2">
                                  <span className="symbol-label ml-2">
                                    <span
                                      className="svg-icon svg-icon-lg svg-icon-primary"
                                      onClick={() =>
                                        handleGetAddress(formik.values.postCode)
                                      }
                                    >
                                      <SVG
                                        className="h-25 align-self-end"
                                        style={{ cursor: "pointer" }}
                                        title=" "
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Search.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-2 123">
                                <span
                                  style={{
                                    color: "#ff9f60",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleManualUpdate}
                                >
                                  {intl.formatMessage({ id: "Enter.Manually" })}
                                </span>
                              </div>
                              {formik.touched.postCode &&
                                formik.errors.postCode ? (
                                <div className=" ml-4 mb-4 fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik?.errors?.postCode}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12">
                            <label>Address</label>
                            <div className="form-groupB">
                              {formik.values.is_address_manual === 1 ? (
                                <input
                                  type="text"
                                  className={`form-control  ${getInputClasses(
                                    formik,
                                    "street1"
                                  )}`}
                                  placeholder="address"
                                  name="street1"
                                  {...formik.getFieldProps("street1")}
                                />
                              ) : (
                                <FormControl fullWidth>
                                  <Select
                                    name="street1"
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                    className={`form-control  ${getInputClasses(
                                      formik,
                                      "street1"
                                    )}`}
                                    IconComponent={(props) => (
                                      <i {...props}>
                                        <KeyboardArrowDownIcon />
                                      </i>
                                    )}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    placeholder="Address"
                                    displayEmpty
                                    {...formik.getFieldProps("street1")}
                                  >
                                    <MenuItem value="">
                                      {intl.formatMessage({ id: "street1" })}
                                    </MenuItem>
                                    {dropDown?.address &&
                                      dropDown?.address.length > 0
                                      ? dropDown?.address.map((data, index) => {
                                        let addressData = data?.formatted_address.toString();
                                        addressData = addressData?.replaceAll(
                                          ", ",
                                          ","
                                        );
                                        addressData = addressData?.replaceAll(
                                          ",, ",
                                          ","
                                        );
                                        addressData = addressData?.replaceAll(
                                          ",,,",
                                          ","
                                        );

                                        return (
                                          <MenuItem
                                            value={addressData}
                                            key={index}
                                          >
                                            {addressData}
                                          </MenuItem>
                                        );
                                      })
                                      : null}
                                  </Select>
                                </FormControl>
                              )}

                              {formik.touched.street1 &&
                                formik.errors.street1 ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.street1}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12">
                            <label>{intl.formatMessage({ id: "CITY" })}</label>
                            <div className="form-groupB">
                              <input
                                type="text"
                                className={`form-control  ${getInputClasses(
                                  formik,
                                  "city"
                                )}`}
                                placeholder={intl.formatMessage({ id: "CITY" })}
                                name="city"
                                {...formik.getFieldProps("city")}
                              />
                              {formik.touched.city && formik.errors.city ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.city}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardComponent>
                ) : (
                  <div className="text-center mt-8">
                    <CircularProgress />
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-center ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => setOpenCanvaQuote({ type: "", open: false })}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            {formik?.values?.paymentType === "card" ? (
              <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-sm mr-3"
                onClick={() => handlePayByLink()}
              // disabled={state3ds.loading}
              >
                Send SMS
              </button>
            ) : (
              ""
            )}
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              onClick={
                Object.keys(formik.errors).length === 0 &&
                  formik.values?.firstName &&
                  formik.values?.paymentType === "card"
                  ? authorizeWith3ds
                  : state.containerTypeSelect === "container" &&
                    state.collectionRetrun?.collection_date &&
                    // Convert string date to Date object
                    new Date(state.collectionRetrun?.collection_date) <
                    new Date(Date.now() + 48 * 60 * 60 * 1000) &&
                    formik.values?.paymentType === "card"
                    ? authorizeWith3ds
                    : handlePayment
              }
              disabled={state3ds.loading || state3ds?.intentLoading}
            >
              {state.containerTypeSelect === "container"
                ? "Payment"
                : intl.formatMessage({ id: "Place.Order" })}
              {state3ds?.intentLoading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {/* {snack.call ? <SnackBarTool {...snack} /> : ""} */}
      {state3ds.loading && (
        <div className="offcanvas-overlay ">
          <div className="customloader">
            <div class="spinner-border customSpinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
