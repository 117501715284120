/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useState } from "react";
import {
  fetchBillingCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../_redux/order/orderAction";

import { Form } from "react-bootstrap";
import { HoldSchema } from "../order-form/validationSchema";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../utils/canvaCloseFunction";
import { sendPutHold } from "../_redux/order/orderCrud";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useIntl } from "react-intl";

export function PutOnHold({ orderDetail }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [loading, setLoading] = useState(false);

  const initialValues = {
    reason: "",
    order_id: orderDetail?.id || "",
    franchise_id: orderDetail?.franchise_id || "",
    subscription_id: orderDetail?.subscription_id || "",
  };

  const handleSubmit = (values, actions) => {
    setLoading(true);
    sendPutHold(values)
      .then((res) => {
        setSnack({ call: true, type: "success", message: res.data.message });
        actions.resetForm({ values: "" });
        canvaCancel("kt_PutOnHold_panel_close");
        setLoading(false);
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
        setLoading(false);
      });
  };

  const handleReset = () => {};

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: HoldSchema,
    onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleDiscard = () => {
    canvaCancel("kt_PutOnHold_panel_close");
    formik.resetForm();
  };

  return (
    <div
      id="kt_PutOnHold_panel"
      className="offcanvas offcanvas-right pt-5 pb-10"
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={() => handleDiscard()}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
        <div id="kt_PutOnHold_panel_close"></div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 ">
          <div>
            <h5>{intl.formatMessage({ id: "Put.Hold" })}</h5>
          </div>
          <div
            className="fade pt-3 pr-5 mr-n5 scroll active show ps--active-y mt-10"
            id="kt_quick_panel_logs_kt_PutOnHold_panel"
          >
            <div>
              <Form.Group className="mb-5">
                <Form.Label>
                  {intl.formatMessage({ id: "Reason.Hold" })}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className={`form-control  ${getInputClasses("reason")}`}
                  name="reason"
                  {...formik.getFieldProps("reason")}
                />
                {formik.touched.reason && formik.errors.reason ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.reason}</div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              disabled={loading}
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && <span className="spinner spinner-white mr-4"></span>}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
}
