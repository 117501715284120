// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html

import * as uiHelpers from "../InvoiceUIHelpers";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  getHandlerTableChange,
  getSelectRow,
  getSelectRowChargeIn,
  getSelectRowChargePromo,
  sortCaret,
} from "../../../../_metronic/_helpers";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import NoRecord from "../../../../_metronic/_partials/widgets/NoRecord";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { Pagination } from "../../../../_metronic/_partials/controls";
// import { fetchAgent } from "../../ContactDeals/_redux/contact/contactAction";
import { fetchInvoice } from "../_redux/InvoiceAction";
import moment from "moment";
import { useIntl } from "react-intl";
import { useInvoiceUIContext } from "../InvoiceUIContext";
import ContactDealTaskModal from "../../../../_metronic/_partials/widgets/alert/ContactDealTaskModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router-dom";
import { viewPdfInvoice } from "../_redux/InvoiceCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";




const TasksTable = ({ show, setShow, permission }) => {
  const intl = useIntl();
  const InvoiceUIContext = useInvoiceUIContext();
  const [dialog, setDialog] = useState({ type: "", open: false, id: "" });
  let history = useHistory();
  const TasksUIProps = useMemo(() => {
    return {
      ids: InvoiceUIContext?.ids,
      setIds: InvoiceUIContext?.setIds,
      queryParams: InvoiceUIContext?.queryParams,
      setQueryParams: InvoiceUIContext?.setQueryParams,
      openEditContactPage: InvoiceUIContext?.openEditContactPage,
      openDeleteContactDialog: InvoiceUIContext?.openDeleteContactDialog,
    };
  }, [InvoiceUIContext]);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.invoice.list }),
    shallowEqual
  );

  const { entities, nextoffset, totalCount, loading } = currentState;

  useEffect(() => {
    // clear selections list
    // server call by queryParams
    // TasksUIProps.setIds([]);
    dispatch(fetchInvoice(TasksUIProps.queryParams, TasksUIProps?.ids));
    // dispatch(fetchAgent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TasksUIProps.queryParams, dispatch]);

  // useEffect(() => {
  //   TasksUIProps.setQueryParams(uiHelpers.initialFilter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const columns = [
    {
      dataField: "invoice",
      text: "Invoice",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.invoice && data.invoice.id ? data.invoice.id : "-"}

          </span>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>
            <span
              className={`label label-lg label-light-${data.invoice?.status === "paid"
                ? "success"
                : data.invoice?.status === "void"
                  ? "warning"
                  : data.invoice?.status === "payment_due"
                    ? "danger"
                    : ""
                } label-inline`}
            >
              {data.invoice?.status === "paid" ? "Paid " : data.invoice?.status === "payment_due" ? "Due" : data.invoice?.status === "voided" ? "Void" : data.invoice?.status == "not_paid" ? "Not Paid" : "-"}
            </span>
          </>
        );
      },

    },
    {
      dataField: "amount",
      text: "Amount",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <>

            &nbsp;&pound;{data.invoice?.status === "paid" ? Number((+data.invoice?.amount_paid / 100))?.toLocaleString() : data.invoice?.status === "payment_due" ? Number(+data.invoice?.amount_due / 100)?.toLocaleString() : Number(+data.invoice?.total / 100)?.toLocaleString() || "-"}
          </>
        );
      },

    },

    {
      dataField: "info",
      text: "Customer Name",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.invoice && data.invoice.billing_address ? data.invoice.billing_address?.first_name + " " + (data.invoice.billing_address?.last_name || "") : "-"}
            {data?.order_id && <span style={{ color: "#ff6601", textDecoration: "underline" }} onClick={() => {
              if (data.order_id) {

                history.push(`/order-details/${data.order_id}`);
              }
              // window.open(`contact-details/${data.id}`, "_blank");
            }}>
              <br />
              {data?.booking_reference}
            </span>}
            {data?.email && <>
              <br />
              {data?.email}
            </>}
            {data?.phone && <>
              <br />
              {data?.phone}
            </>}
          </span>
        );
      },
    },
    {
      dataField: "info",
      text: "Service",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>


            {data?.is_container == 1 ? "Container" : data?.is_container == "3" ? "Box" : "Pod"}


          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Issued On",
      sort: true,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.invoice && data.invoice.date ? moment(data.invoice.date * 1000).format("DD-MM-YYYY") : "-"}
          </span>
        );
      },
    },
    {
      dataField: "paid_on",
      text: "Paid On",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.invoice && data.invoice.paid_at ? moment(data.invoice.paid_at * 1000).format("DD-MM-YYYY") : "-"}
          </span>
        );
      },

    },
    {
      dataField: "recurring",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span>
            {data && data.invoice && data.invoice.recurring ? "Recurring" : "-"}
          </span>
        );
      },
    },
    {
      dataField: "payment_retry",
      text: "Payment Retry",
      sort: false,
      width: "20%",
      sortCaret: sortCaret,
      formatter: (e, data) => {
        // Truncate the string to 20 characters and add ellipsis
        return (
          <span >
            {data?.invoice?.dunning_attempts && data?.invoice?.dunning_attempts.length > 0 && data?.invoice?.dunning_attempts?.length + " retry attempt"}
            {data?.invoice?.dunning_attempts && data?.invoice?.dunning_attempts.length > 0 ? <br /> : ""}
            {data && data.invoice && data.invoice.next_retry_at ? moment(data.invoice.next_retry_at * 1000).format("DD-MM-YYYY") : "-"}
          </span>
        );

      },
    },
    // {
    //   dataField: "shipping_info",
    //   text: "Shipping Info",
    //   sort: false,
    //   width: "20%",
    //   sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     const shippingAddress = data?.invoice?.shipping_address?.line1 || "-";

    //     // Check if the length exceeds 20 characters
    //     if (shippingAddress.length > 20) {
    //       // Truncate the string to 20 characters and add ellipsis
    //       return (
    //         <span title={shippingAddress}>
    //           {shippingAddress.substring(0, 20)}...
    //         </span>
    //       );
    //     } else {
    //       return <span>{shippingAddress}</span>;
    //     }
    //   },
    // },

    // {
    //   // dataField: "created_at",
    //   text: "View",
    //   // sort: true,
    //   // sortCaret: sortCaret,
    //   formatter: (e, data) => {
    //     return (
    //       <span
    //         onClick={() => {
    //           if (data.order_id) {

    //             history.push(`/order-details/${data.order_id}`);
    //           }
    //           // window.open(`contact-details/${data.id}`, "_blank");
    //         }}
    //       >
    //         {data.order_id ? <VisibilityIcon /> : "-"}
    //       </span>
    //     );
    //   },
    // },
    {
      // dataField: "created_at",
      text: "Action",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: (e, data) => {
        return (
          <span
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => {
              const datas = {
                franchise_id: InvoiceUIContext?.queryParams?.filter?.search_franchise,
                invoice_id: data.invoice.id

              }

              viewPdfInvoice(datas).then((res) => {
                window.open(res.data?.data.download_url, '_blank');
              }).catch((error) => {

              })
            }}>
            <span className="svg-icon svg-icon-sm svg-icon-primary">
              <SVG
                title=" "
                className="h-25 align-self-end"
                src={toAbsoluteUrl(
                  "/media/svg/icons/Files/Download.svg"
                )}
              ></SVG>
            </span>
          </span>
        );
      },
    },

  ];
  // const [total, setTotal] = useState(5);

  // useEffect(() => {
  //   if (TasksUIProps.queryParams?.pageNumber) {
  //     setTotal(total + 5);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [TasksUIProps.queryParams.pageNumber]);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: TasksUIProps.queryParams?.pageSize,
    page: TasksUIProps.queryParams?.pageNumber,
    offset: nextoffset,
    ids: TasksUIProps?.ids,
    setIds: TasksUIProps.setIds,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {

      history.push(`/order-details/${row.order_id}`);

    },
  };
  console.log("TasksUIProps", TasksUIProps);
  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    hideSelectColumn: true,
  };
  console.log("entities", entities);
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={loading}
              paginationProps={paginationProps}
              entities={entities}
              customePagination={true}
              loading={loading}
            >
              {show && (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  // rowEvents={rowEvents}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  remote
                  hover
                  keyField="invoice"

                  // defaultSorted={uiHelpers.defaultSorted}
                  data={entities === null ? [] : entities}
                  onTableChange={getHandlerTableChange(
                    TasksUIProps.setQueryParams
                  )}
                  columns={columns}
                  {...paginationTableProps}
                ></BootstrapTable>
              )}
              {permission?.task?.view ? (
                <>
                  {(entities && entities?.length) || loading ? (
                    ""
                  ) : (
                    <NoRecord />
                  )}
                </>
              ) : (
                ""
                // <NotAuthorized />
              )}
            </Pagination>
          );
        }}
      </PaginationProvider>
      <ContactDealTaskModal dialog={dialog} setDialog={setDialog} />
    </>
  );
};

export default TasksTable;
