/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

export function DealsTop({ setActiveTab, dealDetail, ids }) {
  const intl = useIntl();

  return (
    <>
      <>
        <ul className="deal-top">
          <li className="mr-3">
            <Dropdown className="dropdown-inline" drop="down" alignRight>
              <Dropdown.Toggle
                id="dropdown-toggle-top2"
                variant="transparent"
                className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                title={
                  <span>
                    <i className="fad fa-newspaper"></i> Dropdown
                  </span>
                }
              >
                {intl.formatMessage({ id: "Actions" })}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <Dropdown.Item onClick={() => setActiveTab("notes")}>
                  <span className="navi-item">
                    <span className="navi-link">
                      <span className="navi-icon">
                        <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Design/Edit.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text">
                        &nbsp;&nbsp;{intl.formatMessage({ id: "Create" })}{" "}
                        {intl.formatMessage({ id: "Note" })}
                      </span>
                    </span>
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setActiveTab("tasks")}>
                  <span className="navi-item">
                    <span className="navi-link">
                      <span className="navi-icon">
                        <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Clipboard-list.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text">
                        &nbsp;&nbsp;{intl.formatMessage({ id: "Create" })}{" "}
                        {intl.formatMessage({ id: "Task" })}
                      </span>
                    </span>
                  </span>
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => setActiveTab("calls")}>
                  <span className="navi-item">
                    <span className="navi-link">
                      <span className="navi-icon">
                        <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Active-call.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text">
                        &nbsp;&nbsp;{intl.formatMessage({ id: "Call" })}{" "}
                        {intl.formatMessage({ id: "Contact" })}
                      </span>
                    </span>
                  </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setActiveTab("emails")}>
                  <span className="navi-item">
                    <span className="navi-link">
                      <span className="navi-icon">
                        <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text">
                        &nbsp;&nbsp;{intl.formatMessage({ id: "Email" })}{" "}
                        {intl.formatMessage({ id: "Contact" })}
                      </span>
                    </span>
                  </span>
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => setActiveTab("sms")}>
                  <span className="navi-item">
                    <span className="navi-link">
                      <span className="navi-icon">
                        <span className="svg-icon svg-icon-sm svg-icon-light-dark">
                          <SVG
                            title=" "
                            className="h-25 align-self-end"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Chat5.svg"
                            )}
                          ></SVG>
                        </span>
                      </span>
                      <span className="navi-text">
                        &nbsp;&nbsp;{intl.formatMessage({ id: "SMS" })}{" "}
                        {intl.formatMessage({ id: "Contact" })}
                      </span>
                    </span>
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li>
            {ids ? (
              ""
            ) : (
              <Link
                to={{
                  pathname: "/new-order",

                  state: {
                    id: dealDetail?.user_data?.id,
                    type: "deal",
                    dealId: dealDetail?.id,
                  },
                }}
              >
                <button className="btn btn-primary font-weight-bolder font-size-sm ">
                  Create Order
                </button>
              </Link>
            )}
          </li>
        </ul>
      </>
    </>
  );
}
