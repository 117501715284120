/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import {
  createPromotion,
  editPromotion,
} from "../../../_redux/promotions/promotionsCrud";
import { editPromoValues, newPromoInitialValues } from "../PromotionsUIHelpers";
import {
  fetchPromoDetail,
  fetchPromotions,
} from "../../../_redux/promotions/promotionsAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ActiveDialog from "../promotions-dialog/ActiveDialog";
import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import PromotionForm from "../promotions-form/PromotionForm";
import { PromotionsSchema } from "../promotions-form/validationSchema";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { canvaCancel } from "../../../../../utils/canvaCloseFunction";
import currencyApi from "../../../../../utils/currencyApi.json";
import { fetchActiveCountry } from "../../../_redux/country/countryAction";
import { fetchFranchisesCountry } from "../../../_redux/franchises/franchisesActions";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { usePromotionsUIContext } from "../PromotionsUIContext";

export function NewPromotionsCanva({ type, editPromoData }) {
  // Access the dispatch function to dispatch actions
  const dispatch = useDispatch();
  // Access the `intl` object for translations
  const intl = useIntl();

  // Access the context for the promotions UI
  const PromotionsUIContext = usePromotionsUIContext();

  // State for loading indicator
  const [loading, setLoading] = useState(false);
  // State for the active state of the form (whether it is open or closed)
  const [active, setActive] = useState(false);
  // State for the dialog box
  const [dialog, setDialog] = useState(false);
  // State for the disabled state of the dialog box
  const [dialogDis, setDialogDis] = useState(false);
  // State for the snackbar
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const { detailData, franchiseList } = useSelector(
    (state) => ({
      detailData: state.country.activeCountry,
      franchiseList: state.franchise.countryFranchise,
    }),
    shallowEqual
  );

  // Function to save a promotion (either by creating a new one or editing an existing one)
  const savePromotions = (values) => {
    const uniqueArr = [...new Set(values.franchise_charbee_key)];
    values.franchise_charbee_key = uniqueArr;

    setLoading(true);
    setSnack({ call: false, type: "" });
    if (type === "edit") {
      // Fetch the updated details for the edited promotion
      editPromotion(values)
        .then((res) => {
          setSnack({ call: true, type: "success", message: res.data.message });
          canvaCancel("kt_new_promotions_panel_close");

          dispatch(fetchPromoDetail(editPromoData?.id));
          setLoading(false);
          // Reset the formik form
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    } else {
      createPromotion(values)
        .then((res) => {
          canvaCancel("kt_new_promotions_panel_close");
          setSnack({ call: true, type: "success", message: res.data.message });
          dispatch(fetchPromotions(PromotionsUIContext.queryParams));
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
            errors: error?.response?.data?.message,
          });
          setLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues:
      type === "edit" ? editPromoValues(editPromoData) : newPromoInitialValues,
    enableReinitialize: true,
    validationSchema: PromotionsSchema(intl, Yup),
    onSubmit: savePromotions,
  });

  useEffect(() => {
    dispatch(fetchActiveCountry());
  }, [dispatch]);

  useEffect(() => {
    if (formik?.values?.offer_code && type !== "edit") {
      const value = formik.values.offer_code;
      const promo = value.replace(/ /g, "_");
      formik.setFieldValue("promotion_id", promo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.offer_code]);

  const handleFranchise = (e, data) => {
    const arr = [...formik.values.franchise_charbee_key];
    if (e.target.checked) {
      arr.push(data.chargebee_key ? data.chargebee_key : data.id);
      formik.setFieldValue("franchise_charbee_key", arr);
    } else {
      const charge = data.chargebee_key ? data.chargebee_key : data.id;
      const currentId = arr.findIndex((element) => element === charge);
      arr.splice(currentId, 1);
      formik.setFieldValue("franchise_charbee_key", arr);
    }
  };

  const handleCurrency = () => {
    const contryCurrency = detailData.filter(
      (element) => element.id === Number(formik.values.country)
    );
    if (contryCurrency?.length) {
      const currencyCode = currencyApi.filter(
        (element) => element.name === contryCurrency[0].currency
      );
      if (currencyCode?.length) {
        formik.setFieldValue("currency_code", currencyCode[0].cc);
      } else {
        formik.setFieldValue("currency_code", "");
      }
    }
  };

  const handleAllFranchise = (e) => {
    const arr = [];
    if (e.target.checked) {
      for (const element of franchiseList) {
        arr.push(element.chargebee_key ? element.chargebee_key : element.id);
        formik.setFieldValue("franchise_charbee_key", arr);
        formik.setFieldValue("all_franchise", "all");
      }
    } else {
      // arr.push([]);
      formik.setFieldValue("all_franchise", "");
      formik.setFieldValue("franchise_charbee_key", []);
    }
  };

  useEffect(() => {
    if (
      formik.values.duration_type === "forever" ||
      formik.values.duration_type === "one_time" ||
      formik.values.duration_type === ""
    ) {
      formik.setFieldValue("duration", " ");
      formik.setFieldError("duration", " ");
    }
    if (
      formik.values.duration_type === "forever" ||
      formik.values.duration_type === "one_time" ||
      formik.values.duration_type === ""
    ) {
      formik.setFieldValue("duration_length", " ");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.duration_type]);

  const handleDiscard = () => {
    if (formik.dirty && Object.keys(formik.touched)?.length !== 0) {
      setDialogDis(true);
    } else {
      canvaCancel("kt_new_promotions_panel_close");
      formik.resetForm();
    }
  };

  const handleCountry = (e) => {
    formik.setFieldValue("country", e.target.value);
    setTimeout(() => {
      formik.setFieldValue("franchise_charbee_key", []);
    }, 500);
  };

  useEffect(() => {
    if (formik?.values?.country) {
      dispatch(fetchFranchisesCountry(formik.values.country, "promotion"));
      setActive(formik.values.status === "active" ? true : false);
      handleCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country, dispatch]);

  return (
    <div
      id="kt_new_promotions_panel"
      className={`offcanvas offcanvas-right pt-5 pb-10 `}
    >
      <form onSubmit={formik.handleSubmit}>
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div
            className="offcanvas-close mt-n1 pr-5"
            style={{ position: "absolute", top: "15px", right: "10px" }}
          >
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleDiscard()}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </span>
          </div>
          <div id="kt_new_promotions_panel_close"></div>
        </div>

        {/*end::Header*/}

        {/*begin::Content*/}
        <div className="offcanvas-content px-10 date-picker">
          <div>
            <h5>
              {type === "edit"
                ? "Edit Promotion"
                : intl.formatMessage({ id: "New.Promotion" })}
            </h5>
          </div>
          <div>
            <div
              id="kt_quick_panel_logs_kt_new_promotions_panel"
              className={`fade pt-3 pr-5 mr-n5 scroll  active show `}
            >
              {/* promotion create edit form */}
              <PromotionForm
                intl={intl}
                formik={formik}
                handleCountry={handleCountry}
                detailData={detailData}
                franchiseList={franchiseList}
                handleAllFranchise={handleAllFranchise}
                handleFranchise={handleFranchise}
              />

              <div className="row">
                <div className="col-6"></div>
                <div className="col-12"></div>
                <></>
              </div>
            </div>
            {/*end::Content*/}
          </div>
        </div>
        <ActiveDialog
          active={active}
          setActive={setActive}
          dialog={dialog}
          setDialog={setDialog}
          formik={formik}
        />
        {/* cancel and save button */}
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={() => handleDiscard()}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={loading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {loading && (
                <span className="ml-2 mr-2 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {/* rendering snack bar message component */}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
      <DiscardDialog
        setDialogDis={setDialogDis}
        dialogDis={dialogDis}
        formik={formik}
        closeId={"kt_new_promotions_panel_close"}
      />
    </div>
  );
}
