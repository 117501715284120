import React from "react";
import { useIntl } from "react-intl";

// Component to display a dropdown with a list of options for a given profile
const Dropdown = ({ handleFeedCheck, profile, feedsList }) => {
  // Use react-intl hook to support internationalization
  const intl = useIntl();

  // List of options for the dropdown
  const options = [
    "order",
    "operation",
    "billing",
    "email",
    "contact",
    "deal",
    "task",
    "note",
    "call",
  ];

  return (
    <>
      {/* Map through the list of options and render a checkbox for each option */}
      {options.map((option, index) => (
        <div className="row" key={index}>
          <label className="col-6 col-sm-6 col-md-3 col-form-label ">
            {intl.formatMessage({ id: option })}
          </label>
          <div className="col-6 col-sm-6 col-md-9 text-right mt-1">
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  checked={
                    feedsList &&
                    feedsList.length &&
                    feedsList.find(
                      (element) =>
                        element.role_id === profile.id &&
                        element.name === option
                    )
                  }
                  onChange={(e) => handleFeedCheck(e, profile.id, option)}
                  name="quick_panel_notifications_2"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

export default Dropdown;
