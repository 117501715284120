import axios from "axios";

export const FRANCHISES_URL = "api/franchises";
export const FRANCHISES_URL_LIVE = "franchise";
export const VAN_LIVE = "van";
export const DRIVER_LOADER = "driver-loader";
export const DEPOT_LIST = "depot-franchise";
export const VAN_STATUS = "van-status-update";
export const DEPOT_SEARCH = "depot-franchise-search";
export const DEPOT_SEARCH_SAVE = "depot-franchise-save";
export const DEPOT_STATUS = "depot-franchise-status-update";
export const DRIVERLOADER_STATUS = "driver-loader-status-update";
export const DRIVER_SEARCH_LIST = "driver-loader-list";
export const FRANCHISES_POPOVER = "franchise-details-save";
export const POSTCODE = "postcode";
export const POSTCODE_LIVE = "search-postcode";
export const SECTOR = "sector";
export const SECTOR_STATUS = "sector-status-update";
export const COUNTRY_FRANCHISE = "promotion/country-franchise/list";
export const WORKING_DAYS = "franchise-working-days-list";
export const DELETE = "delete/franchise";
export const EDIT_WORKING_DAYS = "franchise-working-days-save";
export const Auto_Mate = "automated-price"
export const CREATE_SITE = "franchise-site/site"
export const SITE_LIST = "franchise-site/site"
export const CLASS_LIST = "franchise-site-class/site-class"
export const CLASS_LISTQ = "franchise-site-class/class-by-site"
export const CLASS_ADD = "franchise-site-class/site-class"
export const ADD_CONTAINER = "franchise-container/franchise-container-save"
export const UPDATE_CONTAINER = "franchise-container/franchise-container-update"
export const DELETE_SITE = "franchise-site/delete/site"
export const DELETE_CLASS = "franchise-site-class/delete/site-class"
export const DELETE_CONTAINER = "franchise-container/delete/container"
export const STAIR_STEP = "franchise-plans-tier-pricing"

export function deleteContainer(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE_CONTAINER, data);
}
export function deleteSite(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE_SITE, data);
}
export function deleteClass(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DELETE_CLASS, data);
}
//franchise list api
export function findFranchise(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + FRANCHISES_URL_LIVE}?page=${queryParams.pageNumber
    }&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField
    }&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search
    }`
  );
}

//franchise detail api
export function findFranchiseDetail(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + FRANCHISES_URL_LIVE}/${queryParams}`
  );
}

//franchise based postcode api
export function findFranchisePostCode(queryParams, text) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + POSTCODE_LIVE}?postcode=${text ? text : ""
    }&franchise_id=${queryParams}`
  );
}

//franchise sector postcode api
export function findFranchiseSectorPostCode(queryParams, text) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + SECTOR}?franchise_id=${queryParams}`
  );
}

//franchise postcode api
export function createFranchisePostCode(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + POSTCODE, data);
}

//franchise delete postcode api
export function deleteFranchisePostCode(id) {
  return axios.delete(process.env.REACT_APP_SITE_URL + POSTCODE + `/${id}`);
}
//delete franchise sector api
export function deleteFranchiseSector(id) {
  return axios.delete(process.env.REACT_APP_SITE_URL + SECTOR + `/${id}`);
}
//franchise sector postcode api
export function createFranchiseSectorPostCode(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + SECTOR, data);
}
//franchise sector postcode api
export function editFranchiseSectorPostCode(query, data) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + SECTOR + `/${query}`,
    data
  );
}
//franhcise van list api
export function findFranchiseVan(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + VAN_LIVE}?franchise_id=${queryParams}`
  );
}
//create franchise van api
export function createFranchiseVan(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + VAN_LIVE, data);
}
//edit van on franchise detail page
export function franchiseEditVan(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL + VAN_LIVE + `/${id}?_method=PATCH`,
    data
  );
}
export function createFranchiseDepotOnSearch(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + DEPOT_SEARCH_SAVE, data);
}
export function createFranchiseVanStatus(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + VAN_STATUS, data);
}
export function franchiseSectorStatus(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + SECTOR_STATUS, data);
}

export function createFranchiseDriverLoaderStatus(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + DRIVERLOADER_STATUS, data);
}
export function createFranchiseDepotStatus(data) {
  // mock.restore();

  return axios.post(process.env.REACT_APP_SITE_URL + DEPOT_STATUS, data);
}
export function findFranchiseDepot(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEPOT_LIST}?franchise_id=${queryParams?.franchise_id ? queryParams?.franchise_id : queryParams || ""}&customer_postcode=${queryParams?.postcode}`
  );
}
export function findFranchiseDriverLoader(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL +
    DRIVER_LOADER}?franchise_id=${queryParams}`
  );
}
export function addFranchiseDriverLoader(data) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DRIVER_LOADER}`, data);
}
export function findFranchiseDepotSearch(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DEPOT_SEARCH}?name=${queryParams}`
  );
}
export function findFranchiseDriverSearch(ids) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + DRIVER_SEARCH_LIST}?franchise_id=${ids}`
  );
}

export function createFranchise(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + FRANCHISES_URL_LIVE, data);
}
export function editFranchisePopDetail(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + FRANCHISES_POPOVER, data);
}
export function editFranchise(data, id) {
  return axios.post(
    process.env.REACT_APP_SITE_URL +
    FRANCHISES_URL_LIVE +
    `/${id}?_method=PATCH`,
    data
  );
}

export function findFranchiseCountry(text, promotion) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + COUNTRY_FRANCHISE}?country_id=${text}${promotion === "promotion" ? `&promotion=${1}` : ""
    }`
  );
}

//delete franchise
export function deleteFranchise(ids) {
  return axios.post(`${process.env.REACT_APP_SITE_URL + DELETE}`, ids);
}

//working days
export function findWorkingDays(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + WORKING_DAYS}?franchise_id=${id}`
  );
}
export function editWorkingDays(value) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + EDIT_WORKING_DAYS}`,
    value
  );
}

export function automatePrice(value) {
  return axios.post(
    `${process.env.REACT_APP_SITE_URL + Auto_Mate}`,
    value
  );
}

export function getTimeline(id) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + "franchises-timeline"}?franchise_id=${id}`
  );
}

export function createSite(data, edit) {
  return axios.post(process.env.REACT_APP_SITE_URL + CREATE_SITE + edit, data);
}

export function getSiteList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + SITE_LIST}?franchise_id=${queryParams}`
  );
}

export function getClassList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CLASS_LIST}?franchise_id=${queryParams}`
  );
}
export function getClassSiteList(queryParams) {
  return axios.get(
    `${process.env.REACT_APP_SITE_URL + CLASS_LISTQ}?site_id=${queryParams}`
  );
}

export function createClass(data, edit, id) {
  return axios.post(process.env.REACT_APP_SITE_URL + CLASS_ADD + (edit === "edit" ? `/${id}?_method=PATCH` : ""), data);
}

export function createContainerFranchise(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + ADD_CONTAINER, data);
}
export function updateContainerFranchise(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + UPDATE_CONTAINER, data);
}

export function setStairStepPrice(data) {
  return axios.post(process.env.REACT_APP_SITE_URL + STAIR_STEP, data);
}